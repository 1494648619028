import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-delivery-tooltip',
  templateUrl: './delivery-tooltip.component.html',
  styleUrls: ['./delivery-tooltip.component.scss']
})
export class DeliveryTooltipComponent implements OnInit {

  constructor() { }

  @Input() mode: string;
  @Input() venueId: string;

  ngOnInit() {
  }

  public openDelivery() {
    window.open(environment.config.reservationWidgetUrl
                        .concat(this.venueId.toString())
                        .concat(environment.config.reservationWidgetQueryString), '_blank');
  }
}
