import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClientService } from './httpclient.service';
import * as _ from 'lodash';

@Injectable()
export class MenusService {
  constructor(private _httpClient: HttpClientService) { }

  private menus = new BehaviorSubject<{}>('');
  public menus$ = this.menus.asObservable();

  public getMenus(): {} {
    return this.menus.getValue();
  }

  setMenus(_menus): void {
    this.menus.next(_menus);
  }

  public getHeaderAndFooter(catalogId: String): Observable<any> {
    return this._httpClient.get('ltm/client/menus/' + catalogId)
  }

}
