import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fraud-finished',
  templateUrl: './fraud-finished.component.html',
  styleUrls: ['./fraud-finished.component.scss']
})
export class FraudFinishedComponent implements OnInit {

  constructor() { }

  @Input() code;
  @Input() message;
  @Input() subMessage;

  ngOnInit() {
  }

}
