import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Mosaic } from "./mosaic.interface";

@Injectable({
	providedIn: "root",
})
export class MosaicService {
	public mosaic = new BehaviorSubject<Mosaic>({
		loading: false,
		venues: [],
		page: 1,
		limit: 10,
		hasNextPagination: true,
	});

	constructor() {}

	public sendMosaic(mosaic: Mosaic) {
		this.mosaic.next(mosaic);
	}

	public receiveMosaic() {
		return this.mosaic.asObservable();
	}
}