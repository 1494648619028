import { ShoppingCart } from './../../interfaces/ShoppingCart';
import { AnteFraudFormValidator } from './../../validators/AnteFraudForm.validator';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './../../../dialog/dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthGuardService } from 'app/_services/auth-guard.service';
import { LoaderService } from './../../../../_services/loader.service';
import { CheckoutService } from './../../../../_services/checkout.service';
import { Component, Inject } from '@angular/core';
import { Modal } from '../../interfaces/Modal';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Router, ActivatedRoute } from '@angular/router';
import { OnboardingModalComponent } from '../onboarding-modal/onboarding-modal.component';
import { AuthenticationService } from 'app/_services/authentication.service';

@Component({
  selector: 'app-verification-code-modal',
  templateUrl: './verification-code-modal.component.html',
  styleUrls: [
    '../modal.component.scss',
    './verification-code-modal.component.scss',
  ],
})
//smoke
export class VerificationCodeModalComponent implements Modal {
  constructor(
    private checkoutService: CheckoutService,
    private authGuardService: AuthGuardService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private onboardingModalComponent: OnboardingModalComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>) {
    dialogRef.disableClose = true;
    if (data && data.customer && data.customer.cellphone) {
      const number = data.customer.cellphone.toString();

      data.customer.maskedNumber = '(' + number.substring(0, 2) + ') ' +
        number.substring(2, 4) + '***-**' +
        number.substring(number.length - 2, number.length)

      this.shoppingCart = data;
    }
  }

  public tokenExpire = null;
  public shoppingCart: ShoppingCart = null;
  public anteFraudForm: FormGroup;
  public digitOne = new FormControl;
  public digitTwo = new FormControl;
  public digitThree = new FormControl;
  public digitFour = new FormControl;
  public digitFive = new FormControl;
  public digitSix = new FormControl;

  ngOnInit() {
    this.expireCodeDuration();
    this.anteFraudForm = this.formBuilder.group(AnteFraudFormValidator.validator());
  }

  public onKey = e => {
    const target = e.srcElement;
    const maxLength = parseInt(target.attributes["maxlength"].value, 10);
    const myLength = target.value.length;
    if (myLength >= maxLength) {
      var next = target;
      while (next = next.nextElementSibling) {
        if (next == null)
          break;
        if (next.tagName.toLowerCase() == "input") {
          next.focus();
          break;
        }
      }
    }
  }

  private expireCodeDuration() {
    const eventTime = 1630958520; // Timestamp 
    const currentTime = 1630958400; // Timestamp 
    const diffTime = eventTime - currentTime;
    let duration: any = moment.duration(diffTime * 1000, 'milliseconds');
    const interval = 1000;

    const refreshSetInterval = setInterval(() => {
      duration = moment.duration(duration - interval, 'milliseconds');
      this.tokenExpire = ("0" + duration.minutes() + ":" + duration.seconds());
      if (duration.minutes() <= 0 && duration.seconds() <= 0) {
        clearInterval(refreshSetInterval);
        this.dialog.closeAll()
      }
    }, interval);
  }

  private updateClientBalance(orderId, balanceUpdated): void {
    this.authGuardService.user$.subscribe(user => {
      if (user && orderId) {
        const client = this.authGuardService.getItemLS('client')
        client['points'] = balanceUpdated
        this.authGuardService.setItemLS('client', client);
        user['points'] = balanceUpdated;
        this.authGuardService.setUser(user);
      }
    });
  }

  public smokeButton() {
    this.router.navigate(['gastronomia/finished', true, false, '4324324324', 'g7278938329784g83979g742987'], { relativeTo: this.route });
  }

  public sendFraudCode() {
    this.loaderService.start();
    this.shoppingCart['tokenAnteFraud'] = this.anteFraudForm.value;
    this.checkoutService.sendTokenCheckFraud(this.shoppingCart).subscribe(
      purchase => {
        if (purchase.errors && purchase.errors.length > 0) {
          let errors = null;
          purchase.errors.forEach(err => {
            console.log(
              'finishCheckout - err: code ' + err.code + ' | ' + err.message
            );
            errors += err.message + '\n';
          });
          this.authGuardService.alert('Erro no servidor ao tentar finalizar.', 'ok');
        } else if (_.isEmpty(purchase)) {
          this.authGuardService.alert('Erro no servidor ao tentar finalizar. ', 'ok');
        } else {
          const hasReservation = !_.isNull(this.shoppingCart.reservation.date);
          this.updateClientBalance(purchase.id, purchase.clientBalanceUpdated);
          this.dialog.closeAll();
          this.router.navigate(['gastronomia/finished', true, hasReservation, purchase.id, this.shoppingCart['venue']['id']], { relativeTo: this.route });
        }

        this.loaderService.stop();
      },
      err => {
        console.error(err);
        if (err && err.body && err.body.statusMessage === 'Unauthorized') {
          this.authService.alertLogin();
          this.authService.logout();
        }
        this.authGuardService.alert('Erro no servidor ao tentar finalizar.', 'ok');
        this.loaderService.stop();
      }
    );
  }

  public changePhone() {
    this.onboardingModalComponent.goOnbordingLtm();
  }

}
