import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import { Filter } from "./filter.interface";

@Injectable({
	providedIn: "root",
})
export class FilterService {
	public filter = new BehaviorSubject<Filter>({
		slug: "",
		loading: true,
		search: null,
		searchDatalist: [],
		location: { latitude: null, longitude: null },
		address: [],
		style: [],
		cousine: [],
		prize: [],
		reservation: [],
	});

	constructor(private http: HttpClient) {}

	public sendFilter(filter: Filter) {
		this.filter.next(filter);
	}

	public receiveFilter() {
		return this.filter.asObservable();
	}

	public getAddressByGeolocation(latitude: string | number, longitude: string | number): Observable<any> {
		return this.http
			.get<any>(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
			.pipe(timeout(5000));
	}
}
