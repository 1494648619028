import { CheckoutService } from "./../../../_services/checkout.service";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ShoppingCart } from "../interfaces/ShoppingCart";
import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";
@Component({
  selector: "app-order-summary",
  templateUrl: "./order-summary.component.html",
  styleUrls: ["./order-summary.component.scss"],
})
export class OrderSummaryComponent {
  @Input() shoppingCart: ShoppingCart;
  @Input() totalAmountInPoints: number = 0;
  @Input() totalAmountInCash: number = 0;
  @Input() reserve: boolean;
  @Input() venue;
  @Input() ambiences: Array<{ id: string; name: string }>;
  @Input() isFinishButtonEnabled: boolean = false;
  @Output() finishCheckoutEmmiter = new EventEmitter<void>();
  constructor(private checkoutService: CheckoutService) {}
  /**
   * Returns the total of items in the shopping cart
   *
   * @return number
   */
  public get amountOfItems(): number {
    return this.shoppingCart.items.reduce((accumulator, item) => accumulator + item.amount, 0);
  }
  /**
   * Returns the reservation date formatted
   *
   * @return string
   */
  public get formattedReservationDate(): string {
    return format(parseISO(this.shoppingCart.reservation.date.toString()), "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
  }
  /**
   * Returns the name of ambience
   *
   * @return string
   */
  public get ambienceName(): string {
    const ambience: { id: string; name: string } = this.ambiences.find(
      (ambience) => ambience.id === this.shoppingCart.reservation.ambience
    );
    return ambience ? ambience.name : 'Salão Principal';
  }
  /**
   * Checks if the button is enabled
   *
   * @return boolean
   */
  public get isButtonEnabled(): boolean {
    return (
      this.isFinishButtonEnabled &&
      this.shoppingCart.reservation.date !== null &&
      this.shoppingCart.reservation.time !== null &&
      this.shoppingCart.reservation.time !== null &&
      this.shoppingCart.reservation.ambience !== null
    );
  }
  /**
   * Emmiter an event for parent component after click in the finish button if it's enabled.
   *
   * @return void
   */
  public finishCheckout(): void {
    if (this.isButtonEnabled) {
      this.finishCheckoutEmmiter.emit();
    }
  }
}