import  { Component } from '@angular/core';

@Component({
    selector: 'tg-footer',
    styleUrls:['./footer.component.scss'],
    template: `
      <div class="container-fluid foot-tagme">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 hidden-xs">
                    <h4>PRODUTOS TAGME</h4>
                    <p><a href="#">Menu Digital + Carta de vinhos</a></p>
                    <p><a href="#">CRM & Integração 360º</a></p>
                    <p><a href="#">Lista de espera & Reservas online</a></p>
                    <p><a href="#">Wifi Tagme hotspots</a></p>
                    <p><a href="#">Web Menu</a></p>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 hidden-xs">
                    <h4>TAGME FOOD SOLUTIONS</h4>
                    <p>R. Dalcídio Jurandir, 255.</p>
                    <p>Sala 103 / Barra da Tijuca</p>
                    <p>Rio de Janeiro – RJ</p>
                    <p><a href="https://www.google.com.br/maps/place/R.+Dalc%C3%ADdio+Jurandir,+255+-+103+-+Barra+da+Tijuca,+Rio+de+Janeiro+-+RJ,+22631-250/@-23.0027396,-43.3402089,17z/data=!3m1!4b1!4m5!3m4!1s0x9bda0d7363c971:0xc09f4f6578047aeb!8m2!3d-23.0027396!4d-43.3380202" target="_blank" style="text-decoration: underline;">Ver mapa</a></p>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 hidden-xs">
                    <h4>CONTATO</h4>
                    <p>comercial@tagme.com.br</p>
                    <p>+ 55 21 3649-9151</p>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 share">
                    <h4><img src="assets/images/logo/tagmeHorizontalFull.png" alt="Tagme"></h4>
                    <p>
                        <a href="#"><i class="itau itau-facebook itau-4x espaco"></i></a>
                        <a href="#"><i class="itau itau-instagram itau-4x espaco"></i></a>
                    </p>
                    <p>© 2009-2017 Tagme food solutions.</p>
                    <p>Todos os direitos reservados.</p>
                    <p class="visible-xs">comercial@tagme.com.br</p>
                    <p class="visible-xs">+ 55 21 3649-9151</p>
                </div>
            </div>
          </div>
      </div>
    `
})

export class FooterComponent {}
