import { AuthenticationService } from "./../../_services/authentication.service";
// import { environment } from './../../../environments/environment.stage';
import { LoaderService } from "./../../_services/loader.service";
import StringMask from "string-mask";
import {
  Component,
  OnInit,
  ViewChild,
  Injectable,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbAccordion, NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";

import { AuthGuardService } from "./../../_services/auth-guard.service";
import { CheckoutService } from "./../../_services/checkout.service";
import { AccordionConfig } from "./interfaces/AccordionConfig";
import { AccordionData } from "./interfaces/AccordionData";
import { ShoppingCart } from "./interfaces/ShoppingCart";
import { ICustomerFormData } from "./interfaces/CustomerFormData";
import { ShoppingCartMock } from "./mocks/ShoppingCartMock.mock";
import { AccordionDataMock } from "./mocks/AccordionDataMock.mock";
import { ReservationFormData } from "./interfaces/ReservationFormData";
import { VenueAdapter } from "./adapters/VenueAdapter.adapter";
import { Venue } from "./interfaces/Venue";
import { ShoppingCartAdapter } from "./adapters/ShoppingCartAdapter.adapter";
import { CustomerFormValidator } from "./validators/CustomerForm.validator";
import { ReservationService } from "app/_services/reservation.service";
import { Availability } from "./interfaces/Availability.interface";
import { ReservationFormDataAdapter } from "./adapters/ReservationFormDataAdapter.adapter";
import * as _ from "lodash";
import { CustomerFormData } from "./mocks/CustomerFormDataMock.mock";
import { environment } from "./../../../environments/environment";
import { Subscription } from "rxjs";
import { SharedDataService } from "app/_services/sharedData.service";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
@Injectable()
export class CheckoutComponent implements OnInit, OnDestroy {
  @ViewChild("acc") accordionComponent: NgbAccordion;

  public accordionConfig: AccordionConfig = null;
  public accordionData: Array<AccordionData> = [];
  public shoppingCart: ShoppingCart;
  public customerFormData: Array<ICustomerFormData>;
  public reservationFormData: Array<ReservationFormData>;
  public customerForm: FormGroup;
  public venue: Venue;
  private venueId: string;
  public available: boolean = false;
  public availability: Array<Availability>;
  public onlyReservation: boolean = false;
  public uniqueAmbiences: Array<{ id: string; name: string }> = [];
  public isFinishButtonEnabled: boolean = true;
  public showGhosts: boolean = true;
  public isBenefitRedeemEnabled: boolean = false;
  public isBenefitRedeemRequired: boolean = false;
  public env = environment;
  public queryParams: Subscription;
  public getVenueService: Subscription;
  public getExchangesByVenueService: Subscription;
  public getClientService: Subscription;
  public registerFilterLog: Subscription;
  public getBalanceClientService: Subscription;
  public getAppReservationsAvailabilitiesService: Subscription;
  public prox: boolean = false;
  public isFraud: boolean = false;
  public clickJumpReserve: boolean = false;
  public fraudCode: string = "";
  public fraudMessage: string = "";
  public fraudSubMessage: string = "";
  public searchFormAction: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private checkoutService: CheckoutService,
    private reservationService: ReservationService,
    private router: Router,
    private authGuardService: AuthGuardService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private _elementRef: ElementRef,
    public sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.searchFormAction = this.env.production
      ? `https://www.iupp.com.br`
      : `https://psp-uat.webpremios.digital`;
    window.scrollTo(0, 0);
    this.loaderService.start();
    // Fictional data for development purposes
    //console.log(history.state)
    this.authService.isLogged();

    this.customerFormData = CustomerFormData.default();
    // this.venueId = '5c9d128f22db8f849ea3b3cf';
    this.shoppingCart = ShoppingCartMock.default();

    let historyData = JSON.parse(localStorage.getItem("state.data"));
    this.onlyReservation = !historyData ? true : false;
    this.isBenefitRedeemEnabled = historyData ? historyData : false;
    this.isBenefitRedeemRequired = historyData ? historyData : false;

    this.accordionConfig = {
      closeOthers: true,
      activeIds: this.onlyReservation ? "reservation" : "benefits",
    };

    this.customerForm = this.formBuilder.group(
      CustomerFormValidator.validator()
    );

    this.queryParams = this.route.params.subscribe(async (params: Params) => {
      this.venueId = params["venueIdParam"];

      if (this.venueId) {
        this.getAppReservationsAvailabilitiesService =
          await this.reservationService
            .getAppReservationsAvailabilities(this.venueId)
            .subscribe(
              (response) => {
                this.availability = response.availabilities;
                this.reservationFormData =
                  ReservationFormDataAdapter.fromAPIResponse(
                    response.availabilities
                  );
                this.uniqueAmbiences = this.getUniqueAmbiences();
              },
              (err) => {
                console.log(err);
                this.authGuardService.alert(
                  "Ops. Erro ao carregar dados de disponibilizada da reserva. Tente novamente em instantes, por favor.",
                  "ok"
                );
                this.authService.doLogin();
              }
            );

        this.getVenue();
        const client = this.authGuardService.getItemLS("client");
        await this.makeClientBalance(client);
      }
    });

    setTimeout(() => {
      ////  esperar criar o elemento para
      this.removeRoleAttr(); //// retirar o 'role tab' para acessibilidade
    }, 300);
  }

  ngOnDestroy(): void {
    if (this.queryParams) this.queryParams.unsubscribe();
    if (this.getClientService) this.getClientService.unsubscribe();
    if (this.getVenueService) this.getVenueService.unsubscribe();
    if (this.getExchangesByVenueService)
      this.getExchangesByVenueService.unsubscribe();
    if (this.getAppReservationsAvailabilitiesService)
      this.getAppReservationsAvailabilitiesService.unsubscribe();
    if (this.getBalanceClientService)
      this.getBalanceClientService.unsubscribe();
  }

  removeRoleAttr() {
    this._elementRef.nativeElement
      .querySelectorAll("#jsAccordion .card-header")
      .forEach((el) => {
        el.removeAttribute("role");
      });
  }

  private goWithClientCached() {
    try {
      let clientCached = JSON.parse(this.authGuardService.getCookie("client"));

      clientCached =
        ShoppingCartAdapter.getCustomerFromAPIResponse(clientCached);

      this.shoppingCart.customer =
        ShoppingCartAdapter.getCustomerFromAPIResponse(clientCached);

      this.shoppingCart["venue"] = this.venue;

      const birthDate = new Date(this.shoppingCart.customer.birthDate);
      this.checksIfInvalidAndSetsWithRightFormat(
        "cpf",
        this.shoppingCart.customer.cpf,
        "000.000.000-00"
      );
      this.checksIfInvalidAndSetsWithRightFormat(
        "cellphone",
        this.shoppingCart.customer.cellphone,
        "(00) 00000-0000"
      );
      this.checksIfInvalidAndSetsWithRightFormat(
        "birthDate",
        `${("00" + (birthDate.getDay() + 1)).slice(-2)}${(
          "00" +
          (birthDate.getMonth() + 1)
        ).slice(-2)}${birthDate.getFullYear()}`,
        "00-00-0000"
      );

      this.authService.checkLTMLogin(clientCached);
      this.customerForm.setValue(clientCached);
      this.getBalance(clientCached.accessToken);

      this.showGhosts = false;

      this.loaderService.stop();
    } catch (err) {
      this.authGuardService.alert(
        "Ops. Erro ao carregar dados do cliente. Tente novamente em instantes, por favor.",
        "ok"
      );
      this.authService.doLogin();
    }
  }

  private getVenue() {
    try {
      const venue = this.authGuardService.getItemLS("venue");
      this.venue = VenueAdapter.fromAPIResponse(venue);
      this.shoppingCart = ShoppingCartAdapter.fromAPIResponse(venue);
      this.checkIfExistsAndSetPoints(venue);
      this.accordionData = this.onlyReservation
        ? AccordionDataMock.reservationFirst(this.venue)
        : AccordionDataMock.custom(this.venue);
      this.accordionConfig = {
        closeOthers: true,
        activeIds: this.accordionData[0].htmlId,
      };

      if (!venue.reservation.available) this.removeTab("reservation");
      if (this.onlyReservation) {
        this.removeTab("payment");
      }

      const j = this.accordionData.findIndex((d) => d.htmlId === "personal");
      this.accordionData.splice(j, 1);
    } catch (err) {
      console.log(err);
      this.authGuardService.alert(
        "Ops. Erro ao carregar dados do restaurante. Tente novamente em instantes, por favor.",
        "ok"
      );
    }

    return this.getVenueService;
  }

  private removeTab(htmlId) {
    const i = this.accordionData.findIndex((d) => d.htmlId === htmlId);
    this.accordionData.splice(i, 1);
  }

  private makeClientBalance(client: any) {
    try {
      if (!client.accessToken) this.authService.doLogin();

      // this.getBalanceClientService = this.authService.getBalanceClient(client.accessToken).subscribe(
      //   balance => {
      this.shoppingCart.customer =
        ShoppingCartAdapter.getCustomerFromAPIResponse(client);
      // this.shoppingCart.customer = client;
      if (client.points) {
        this.authGuardService.setCookie("points", client.points, 1);
        this.shoppingCart.customer.points = client.points;
        this.shoppingCart["venue"] = this.venue;
        this.loaderService.stop();
        /*  //TODO: Verify correct message
      } else {
        this.authGuardService.alert(
          "Ops. Erro ao buscar saldo de pontos. Tente novamente em instantes, por favor.",
          "ok"
        );
*/
      }

      if (client) {
        this.shoppingCart.customer.accessToken = client.accessToken;
        this.shoppingCart.customer.idToken = client.idToken;
        this.loaderService.stop();
      } else {
        this.authGuardService.alert(
          "Ops. Erro ao buscar saldo de pontos. Tente novamente em instantes, por favor.",
          "ok"
        );
        this.loaderService.stop();
      }

      // this.authGuardService.updateClientCookie(this.shoppingCart.customer);

      this.customerForm.setValue(this.shoppingCart.customer);
      const birthDate = new Date(this.shoppingCart.customer.birthDate);
      this.checksIfInvalidAndSetsWithRightFormat(
        "cpf",
        this.shoppingCart.customer.cpf,
        "000.000.000-00"
      );
      this.checksIfInvalidAndSetsWithRightFormat(
        "cellphone",
        this.shoppingCart.customer.cellphone,
        "(00) 00000-0000"
      );
      this.checksIfInvalidAndSetsWithRightFormat(
        "birthDate",
        `${("00" + (birthDate.getDay() + 1)).slice(-2)}${(
          "00" +
          (birthDate.getMonth() + 1)
        ).slice(-2)}${birthDate.getFullYear()}`,
        "00-00-0000"
      );

      if (!this.shoppingCart.customer) {
        this.authGuardService.alert(
          "Instabilidade na rede. Tente novamente em instantes, por favor.",
          "ok"
        );
        this.authService.doLogin();
      }

      if (this.venue && !_.isEmpty(this.venue)) this.showGhosts = false;
    } catch (err) {
      this.authGuardService.alert(
        "Ops. Erro ao buscar saldo de pontos. Tente novamente em instantes, por favor.",
        "ok"
      );
      this.loaderService.stop();
    }
    // );
  }

  private getBalance(accessToken) {
    this.getBalanceClientService = this.authService
      .getBalanceClient(accessToken)
      .subscribe(
        (balance) => {
          return balance.pointsValue;
        },
        (err) => {
          console.log(err);
          this.authGuardService.alert(
            "Ops. Erro ao buscar saldo de pontos. Tente novamente em instantes, por favor.",
            "ok"
          );
        }
      );
  }

  private checksIfInvalidAndSetsWithRightFormat(
    name: string,
    value: any,
    mask: string
  ): void {
    if (value && !this.customerForm.controls[name].valid) {
      const formatter = new StringMask(mask);

      this.customerForm.controls[name].setValue(formatter.apply(String(value)));
    }
  }

  private checkIfExistsAndSetPoints(venue: any) {
    if (venue.prizes && venue.prizes.length > 0) {
      venue.prizes.forEach((prize) => {
        this.shoppingCart.items.forEach((cartItem) => {
          cartItem.benefit["allowed"] = prize.allowed;
          if (
            _.get(cartItem, "benefit.price.cash.current") ===
            parseInt(prize.price)
          ) {
            const points = Math.trunc(parseInt(prize.price) / this.sharedDataService.CPP);
            cartItem.benefit.price.points.previous = points;
            cartItem.benefit.price.points.current = points;
          }
        });
      });
    }
  }

  get totalValueOfShoppingCartInCash(): number {
    return this.getTotalValueOfShoppingCart("cash");
  }

  /**
   * Gets the total value of shopping cart in points
   */
  get totalValueOfShoppingCartInPoints(): number {
    return this.getTotalValueOfShoppingCart("points");
  }

  /**
   * Gets the total value of shopping cart in cash
   */
  private getTotalValueOfShoppingCart(typeOfPrice: string): number {
    return typeOfPrice === "points" || typeOfPrice === "cash"
      ? this.shoppingCart.items.reduce(
          (accumulator, item) =>
            accumulator + item.amount * item.benefit.price[typeOfPrice].current,
          0
        )
      : 0;
  }

  public getToken() {
    return this.authGuardService.getCookie("token");
  }

  /**
   * Blocks the openning of panel if it's disabled
   *
   * @param $event event of change in accordion's panel
   *
   * @return void
   */
  public beforeChange($event: NgbPanelChangeEvent): void {
    const accordionData = this.findAccordionDataByHtmlId($event.panelId);

    if (accordionData !== undefined && accordionData.disabled) {
      $event.preventDefault();
    }
  }

  /**
   * Returns a boolean to represent the disabled status of button to next panel. If the button is disabled, the next panel is disabled too.
   *
   * @return boolean
   */
  get benefitsNextButtonDisabled(): boolean {
    for (let shoppingCartItem of this.shoppingCart.items) {
      if (shoppingCartItem.amount > 0) {
        return false;
      }
    }

    this.toggleAccordionPanel("personal", false);

    return true;
  }

  /**
   * Returns true if the customer object in shopping cart object has a null, empty or undefined property or false if it is completely filled
   *
   * @return boolean
   */
  get reservationNextButtonDisabled(): boolean {
    let itHasNullProperty = false;

    for (let property in this.shoppingCart.reservation) {
      const value = this.shoppingCart.reservation[property];

      if (value === null) {
        itHasNullProperty = true;

        break;
      }
    }

    return itHasNullProperty;
  }

  /**
   * Enables a accordion's panel and opens it
   *
   * @param nextHtmlIdPanel accordion's panel #id
   *
   * @return void
   */
  openNextPanel(nextHtmlIdPanel: string, currentHtmlId: string): void {
    this.clickJumpReserve = false;
    const currentIndex = this.accordionData.findIndex(
      (item) => item.htmlId === currentHtmlId
    );
    this.accordionData[currentIndex].isConcluded = true;
    this.accordionData[currentIndex].icon = "fa fa-check-circle";

    if (!this.shoppingCart.customer) {
      alert("Instabilidade na rede. Tente novamente em instantes, por favor.");
      this.authService.doLogin();
    }
    this.toggleAccordionPanel(nextHtmlIdPanel, true);
    this.accordionComponent.toggle(nextHtmlIdPanel);
  }

  deleteReserve(){
    this.shoppingCart.reservation['date'] = null;
    this.shoppingCart.reservation['ambience'] = null;
    this.shoppingCart.reservation['people'] = null;
    this.shoppingCart.reservation['time'] = null;
  }

  skipReserve(){
    this.deleteReserve()
    this.openNextPanel('payment', 'reservation')
    this.clickJumpReserve = true;
  }
  /**
   * Enables ou disables an accordion's panel
   *
   * @param htmlIdPanel #id of accordion's panel
   * @param status True for enable panel or false to disable it
   *
   * @return void
   *
   */
  private toggleAccordionPanel(htmlIdPanel: string, status: boolean): void {
    const panel = this.findAccordionDataByHtmlId(htmlIdPanel);

    if (panel) {
      panel.disabled = !status;
    }
  }

  /**
   * Returns object AccordionData by #id of panel in accordion or undefined if can't find it
   *
   * @param htmlIdPanel #id of panel in accordion
   *
   * @return AccordionData | undefined
   */
  private findAccordionDataByHtmlId(
    htmlIdPanel: string
  ): AccordionData | undefined {
    return this.accordionData.find(
      (accordionData) => accordionData.htmlId === htmlIdPanel
    );
  }

  /**
   * Updates a property in customer object of shopping cart object after user's input
   *
   * @param event Event triggered by input of user
   * @param property Property of customer object in shopping cart object
   *
   * @return void
   */
  public updatePropertyOfCustomer(event: any, property: string): void {
    const value =
      property === "birthDate"
        ? this.dateInBrazillianFormatToDate(event.target.value)
        : event.target.value;

    this.shoppingCart.customer[property] = value;
  }

  /**
   * Receives a string with a format 'dd-mm-yyyy' and returns 'yyyy-mm-dd'
   *
   * @param brazillianFormat string with the format 'dd-mm-yyyy'
   *
   * @return Date
   */
  private dateInBrazillianFormatToDate(brazillianFormat: string): Date {
    const [day, month, year] = brazillianFormat.split("-");

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  /**
   * Updates a property in reservation object of shopping cart object after user's input
   *
   * @param event Event triggered by input of user
   * @param property Property of reservation object in shopping cart object
   *
   * @return void
   */
  public updatePropertyOfReservation(event: any, property: string): void {
    let value =
      property === "people" ? parseInt(event.target.value) : event.target.value;

    if (
      event.target.value === null ||
      event.target.value === undefined ||
      event.target.value === ""
    ) {
      value = null;
    }

    if (property === "date") {
      this.emptyReservationFormData(1, "Selecione um ambiente");
      this.emptyReservationFormData(2, "Selecione o nº de pessoas");
      this.emptyReservationFormData(3, "Selecione o horário");
      this.updateAmbienceOptionsOfReservationForm(value);
    }

    if (property === "ambience") {
      const date: string = this.shoppingCart.reservation["date"]
        ? this.shoppingCart.reservation["date"].toString()
        : "";

      this.emptyReservationFormData(2, "Selecione o nº de pessoas");
      this.emptyReservationFormData(3, "Selecione o horário");
      this.updatePeopleOptionsOfReservationForm(date, value);
    }

    if (property === "people") {
      const date: string = this.shoppingCart.reservation["date"]
        ? this.shoppingCart.reservation["date"].toString()
        : "";
      const time: string = value ? value.toString() : "";

      this.emptyReservationFormData(3, "Selecione o horário");
      this.updateTimeOptionsOfReservationForm(
        date,
        this.shoppingCart.reservation["ambience"],
        time
      );
    }

    this.shoppingCart.reservation[property] = value;
    if(this.shoppingCart.reservation[property] == null){
      this.deleteReserve();
    }
  }

  /**
   * TODO:
   * [] unit testing
   *
   * Updates the ambience options using the day received
   *
   * @param value Date selected for reservation
   */
  private updateAmbienceOptionsOfReservationForm(value: string): void {
    const availability = this.availability.find(
      (availability) => availability.reservationDay === value
    );

    if (availability) {
      availability.sections.forEach((section) => {
        if (section.available) {
          this.reservationFormData[1].options.push({
            value: section.id,
            text: section.label,
          });
        }
      });
    }
  }

  /**
   * TODO:
   * [] unit testing
   *
   * Updates the party size options using the ambience and date received
   *
   * @param date Date selected for the reservation
   * @param ambience Ambience selected for the reservation
   */
  private updatePeopleOptionsOfReservationForm(
    date: string,
    ambience: string
  ): void {
    const availability = this.availability.find(
      (availability) => availability.reservationDay === date
    );

    if (availability) {
      const section = availability.sections.find(
        (section) => section.id === ambience
      );

      if (section) {
        section.schedules.forEach((schedule) => {
          if (schedule.available) {
            this.reservationFormData[2].options.push({
              value: schedule.partySize.toString(),
              text: `${schedule.partySize} pessoa${
                schedule.partySize > 1 ? "s" : ""
              }`,
            });
          }
        });
      }
    }
  }

  /**
   * TODO:
   * [] unit testing
   *
   * Updates the time options using the ambience, date and party size received
   *
   * @param date Date selected for the reservation
   * @param ambience Ambience selected for the reservation
   * @param partySize Party size selected for the reservation
   */
  private updateTimeOptionsOfReservationForm(
    date: string,
    ambience: string,
    partySize: string
  ): void {
    const availability = this.availability.find(
      (availability) => availability.reservationDay === date
    );

    if (availability) {
      const section = availability.sections.find(
        (section) => section.id === ambience
      );

      if (section) {
        const schedule = section.schedules.find(
          (schedule) => schedule.partySize === parseInt(partySize)
        );

        if (schedule) {
          schedule.reservationTimes.forEach((reservationTime) => {
            if (reservationTime.available) {
              this.reservationFormData[3].options.push({
                value: reservationTime.reservationTime,
                text: reservationTime.reservationTime,
              });
            }
          });
        }
      }
    }
  }

  /**
   * TODO:
   * [] unit testing
   *
   * Empty the options of a reservation form data
   *
   * @param reservationFormDataIndex index of reservation form data to be empty
   * @param message Message to be displayed in the select tag
   */
  private emptyReservationFormData(
    reservationFormDataIndex: number,
    message: string
  ): void {
    this.reservationFormData[reservationFormDataIndex].options = [
      {
        value: "",
        text: message,
      },
    ];
  }

  /**
   * Returns the htmlId of next element in the accordion data array
   *
   * @param htmlId String with the htmlId of current element of accordion data array
   *
   * @return string | null
   */
  public getNextHtmlId(htmlId: string): string | null {
    const currentAccordionDataIndex: number = this.accordionData.findIndex(
      (accorionData) => accorionData.htmlId === htmlId
    );

    if (
      currentAccordionDataIndex >= 0 &&
      currentAccordionDataIndex < this.accordionData.length - 1
    ) {
      return this.accordionData[currentAccordionDataIndex + 1].htmlId;
    }

    return null;
  }

  public getPreviousHtmlId(htmlId: string): string | null {
    const currentAccordionDataIndex: number = this.accordionData.findIndex(
      (accorionData) => accorionData.htmlId === htmlId
    );

    if (
      currentAccordionDataIndex >= 0 &&
      currentAccordionDataIndex < this.accordionData.length - 1
    ) {
      return this.accordionData[currentAccordionDataIndex + 1].htmlId;
    }

    return null;
  }

  /**
   * Return all of unique ambiences from availability
   *
   * @return Array<{ id: string; name: string }>
   */
  private getUniqueAmbiences(): Array<{ id: string; name: string }> {
    const uniqueAmbiences: Array<{ id: string; name: string }> = [];

    if (this.availability && this.availability.length > 0) {
      this.availability[0].sections.forEach((section) => {
        uniqueAmbiences.push({ id: section.id, name: section.label });
      });
    }

    return uniqueAmbiences;
  }

  /**
   * Receives a event determing if the finish button is enabled
   *
   * @param $event Boolean to determine if the finish button is enabled
   *
   * @return void
   */
  public isFinishButtonEnabledReceiver($event: boolean): void {
    this.isFinishButtonEnabled = $event;
  }

  private updateClientBalance(orderId, balanceUpdated): void {
    if (orderId) {
      const client = this.authGuardService.getItemLS("client");
      client["points"] = balanceUpdated;
      this.authGuardService.setItemLS("client", client);
      this.authGuardService.setCookie("points", balanceUpdated, 1);
      this.authGuardService.setUser(client);
    }
  }

  /**
   * Receives a event to finish the checkout and redirects to finished checkout page
   *
   * @return void
   */
  public finishCheckoutReceiver(): void {
    this.loaderService.start();

    this.checkoutService.finishPayment(this.shoppingCart).subscribe(
      (purchase) => {
        if (purchase.errors && purchase.errors.length > 0) {
          let errors = null;
          purchase.errors.forEach((err) => {
            console.log(
              "finishCheckout - err: code " + err.code + " | " + err.message
            );
            errors += err.message + "\n";
          });
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        } else if (_.isEmpty(purchase)) {
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        } else {
          const hasReservation = !_.isNull(this.shoppingCart.reservation.date);
          this.updateClientBalance(purchase.id, purchase.clientBalanceUpdated);
          this.router.navigate([
            "gastronomia/finished",
            true,
            hasReservation,
            purchase.id,
            this.venueId,
          ]);
        }

        this.loaderService.stop();
      },
      (err) => {
        console.error(err);
        if (err.body && err.body.statusMessage === "Unauthorized") {
          this.authService.alertLogin();
        } else if (err && typeof err === "string" && err.length > 15) {
          this.authGuardService.alert(err, "ok");
        } else if (err._body && !isJson(err._body) && err._body === "401") {
          this.authService.alertLogin();
        } else if (
          err._body &&
          !isJson(err._body) &&
          typeof err._body === "string" &&
          err._body.length > 15
        ) {
          this.authGuardService.alert(err._body, "ok");
        } else if (err.error) {
          const errJson = err.error;
          if (errJson.status === "AUTHENTICATIONREQUIRED") {
            this.shoppingCart["orderId"] = errJson.authorizationId.toString();
            this.checkoutService.fraudModal(this.shoppingCart);
          } else if (
            [325, 326, 329].includes(err.status) &&
            Array.isArray(errJson.errors)
          ) {
            this.isFraud = true;
            this.fraudMessage = errJson.errors[0].message;
            this.fraudCode = errJson.errors[0].code;
            this.fraudSubMessage = errJson.errors[0].subMessage;
          } else if (errJson.status === "MessageOnBoardingRequired") {
            this.checkoutService.onBoardingModal();
          } else if (errJson.status === "MessageOnBoardingRequired") {
            this.checkoutService.onBoardingModal();
          } else {
            this.authGuardService.alert(
              "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
              "ok"
            );
          }
        } else if (err._body && isJson(err._body)) {
          const errJson = JSON.parse(err._body);
          if (errJson.statusMessage === "Unauthorized") {
            this.authService.alertLogin();
          } else if (
            errJson.errors &&
            errJson.errors.length > 0 &&
            errJson.errors[0].message
          ) {
            this.authGuardService.alert(errJson.errors[0].message, "ok");
          } else if (errJson.status === "AUTHENTICATIONREQUIRED") {
            this.shoppingCart["orderId"] = errJson.authorizationId.toString();
            this.checkoutService.fraudModal(this.shoppingCart);
          } else if (errJson.status === "MessageOnBoardingRequired") {
            this.checkoutService.onBoardingModal();
          } else {
            this.authGuardService.alert(
              "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
              "ok"
            );
          }
        } else {
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        }

        this.loaderService.stop();
      }
    );
  }

  public finishReservation(): void {
    this.loaderService.start();

    delete this.shoppingCart.prize;

    this.checkoutService.finishReservation(this.shoppingCart).subscribe(
      (purchase) => {
        if (purchase.errors && purchase.errors.length > 0) {
          let errors = null;
          purchase.errors.forEach((err) => {
            console.log(
              "finishCheckout - err: code " + err.code + " | " + err.message
            );
            errors += err.message + "\n";
          });
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        } else if (_.isEmpty(purchase)) {
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        } else {
          const hasReservation = !_.isNull(this.shoppingCart.reservation.date);
          this.router.navigate(["../../finished", false, hasReservation], {
            relativeTo: this.route,
          });
        }

        this.loaderService.stop();
      },
      (err) => {
        console.log(err);
        if (err && err.body && err.body.statusMessage === "Unauthorized") {
          this.authService.alertLogin();
          this.authService.logout();
        }
        this.authGuardService.alert(
          "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
          "ok"
        );
        this.loaderService.stop();
      }
    );
  }

  public changeIsBenefitRedeemEnabled(flag: boolean): void {
    this.isBenefitRedeemEnabled = flag;
  }

  public goBack(): void {
    this.router.navigate(["gastronomia/venue-details/" + this.venueId]);
    localStorage.removeItem("state.data");
  }
}

function isJson(str) {
  try {
    const obj = JSON.parse(str);

    const isObj = typeof obj === "object";
    return isObj;
  } catch (e) {
    return false;
  }
  // return true;
}
