import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { HttpClientService } from './httpclient.service';
import * as _ from 'lodash';

@Injectable()
export class ReservationService {

  constructor(private _httpClient: HttpClientService) {}

  public getReservation (_reservationId: any) : Observable<any> {

    return this._httpClient.get('Reservation/'+_reservationId, { reservationId: _reservationId })
  }
  
  public getAppReservationsAvailabilities (_venueId: any) : Observable<any> {

    return this._httpClient.get('Reservation/Availabilities/'+_venueId)
  }

  public confirmReservation (_reservation : any) : Observable<any> {


    return this._httpClient.put('Reservation/'+_reservation.venue+'/'+_reservation._id+'/Confirm', { 
        logs: { customer: _reservation.customer._id, actionType: 'Update' },
        customer: _reservation.customer._id }
    )
  }

  public cancelReservation (_reservation: any) : Observable<any> {

    let body;

    if(_reservation.customer){
        body = {
                logs: { customer: _reservation.customer._id, actionType: 'Remove' }, 
                customer: _reservation.customer._id, 
                cancelReason: 'Customer', 
                origin: 'IUPP',
                originalReservationData : _reservation 
            } 
    }

    return this._httpClient.put('Reservation/'+_reservation.venue +'/'+_reservation._id+'/Cancel', body)
  }

  public setReservation (_reservation: any) : Observable<any> {

    return this._httpClient.post('Reservation/'+_reservation.venue, _reservation)
  }

  public getPrizes (_venueId: any) : Observable<any> {

    return this._httpClient.get('Reservation/Prizes/'+_venueId)
  }

  public getApp (_venueId: any, _appLabel: any) : Observable<any> {

    return this._httpClient.get('Reservation/Apps/'+_venueId, { appLabel: _appLabel })

  }
  
}