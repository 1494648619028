import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() title: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() readonly: boolean;
  @Input('control') formControl: FormControl;
  @Input('mask') receivedMask: string;
  @Input() errorMessage: string;
}
