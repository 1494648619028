import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-venue-not-found',
  templateUrl: './venue-not-found.component.html',
  styleUrls: ['./venue-not-found.component.scss'],
})
export class VenueNotFoundComponent {
  @Input() style: string;
  @Input() cuisine: string;
}
