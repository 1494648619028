import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { VenueService } from "../../../_services/venue.service";
import { AuthGuardService } from "app/_services/auth-guard.service";
import { Mosaic } from "./mosaic.interface";
import { Filter } from "../filter/filter.interface";
import { Observable, Subscription } from "rxjs";
import { MosaicService } from "./mosaic.service";
import { FilterService } from "../filter/filter.service";

@Component({
	selector: "mosaic",
	templateUrl: "./mosaic.component.html",
	styleUrls: ["./mosaic.component.scss"],
})
export class MosaicComponent implements OnInit, OnDestroy {
	@Input() slug: string = "mosaic";
	@Input() title: string = "";
	public loadingVenues: Array<null> = [];

	public $mosaicSubs: Subscription;
	public mosaic: Mosaic | null = this.mosaicService.mosaic.getValue();

	public $filterSubs: Subscription;
	public filter: Filter | null = this.filterService.filter.getValue();

	constructor(
		private mosaicService: MosaicService,
		private filterService: FilterService,
		private venueService: VenueService,
		private authGuardService: AuthGuardService
	) {}

	async ngOnInit() {
		this.$mosaicSubs = await this.mosaicService.receiveMosaic().subscribe((res) => (this.mosaic = res));
		this.$filterSubs = this.filterService.receiveFilter().subscribe((res) => {
			// console.log(res);
			// if (res.loading && res.slug == "" && res.slug !== this.slug) return;
			if (!res.cousine.length || !res.address.length) return;

			this.filter = res;
			this.mosaic.venues = [];
			this.mosaic.page = 1;
			this.mosaic.hasNextPagination = true;
			this.getVenuesFromAPI();
		});
		this.loadingVenues = Array(this.mosaic.limit).fill(null);
	}

	ngOnDestroy() {
		this.$filterSubs.unsubscribe();
		this.$mosaicSubs.unsubscribe();
	}

	public async dispatchMosaic(mosaic: Mosaic) {
		this.mosaicService.sendMosaic(mosaic);
	}

	public fetchSeeMore() {
		this.getVenuesFromAPI();
	}

	public getSelectedAddress() {
		if (!this.filter || !this.filter.address.length) return { addressType: null, address: null };

		for (let city of this.filter.address) {
			for (let neighborhood of city.neighborhoods)
				if (neighborhood.selected) return { addressType: "neighborhood", address: neighborhood };
			if (city.selected) return { addressType: "city", address: city };
		}
	}

	public getVenuesFromAPI() {
		this.dispatchMosaic({ ...this.mosaic, loading: true });

		// const client = this.authGuardService.getItemLS("client");

		// // if (!client) throw new Error(`Could not find client on LocalStorage.`);
		// if (!client) return;

		const segment = '40539';
		const style = this.filter.style.find((item) => item.selected);
		const cousine = this.filter.cousine.find((item) => item.selected);
		const prize = this.filter.prize.find((item) => item.selected);
		const reservation = this.filter.reservation.find((item) => item.selected);
		const { addressType, address } = this.getSelectedAddress();
		const search = this.filter.search.length ? this.filter.search : null;
		const venuesAlreadyInView = this.mosaic.venues.map((item) => item._id);

		this.venueService
			.getVenues(
				this.slug || "home",
				addressType === "city" ? address.name : null,
				addressType === "neighborhood" ? address.name : null,
				this.filter.location,
				segment,
				search,
				cousine.name !== "Todos" ? cousine.name : null,
				prize.name !== "Todos" ? prize.name : null,
				style.allowed || null,
				reservation.label !== "Todos" ? reservation.name : null,
				this.mosaic.page,
				this.mosaic.limit,
				venuesAlreadyInView
			)
			.subscribe(async (_venues) => {
				_venues.venues = _venues.venues.map((venue) => ({ ...venue, display: true }));

				this.mosaic.venues = [...this.mosaic.venues, ..._venues.venues];

				if (_venues.next) this.dispatchMosaic({ ...this.mosaic, loading: false, page: ++this.mosaic.page });
				else this.dispatchMosaic({ ...this.mosaic, loading: false, hasNextPagination: false });

				(err) => {
					this.authGuardService.alert(
						"Erro ao tentar carregar restaurantes. Tente novamente em instantes, por favor.",
						"ok"
					);
					// this.loaderService.stop();
					throw err;
				};
			});
	}
}
