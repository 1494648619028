import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PrizeService } from "app/_services/prize.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-menu-details",
  templateUrl: "./menu-details.component.html",
  styleUrls: ["./menu-details.component.scss"],
})
export class MenuDetailsComponent implements OnInit, OnDestroy {
  public prize = null;
  public idx = 0;
  public subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prizeService: PrizeService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.subs.add(
      this.prizeService.prizeWithIdx$.subscribe((prizeIdx) => {
        this.prize = prizeIdx.prize;
        this.idx = prizeIdx.idx;
        if (!this.prize) {
          this.router.navigate(["../"], { relativeTo: this.route });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public goCheckout(): void {
    try {
      this.router.navigate(["gastronomia/checkout", this.prize.venue._id], {
        state: { data: true },
      });

      localStorage.setItem("state.data", true.toString());
    } catch (error) {
      this.router.navigate(["../"], { relativeTo: this.route });
    }
  }

  public menuItemImageURL(avatarURL: string): string {
    return `${environment.config.imageUrl}${avatarURL}`;
  }

  public backToVenueDetails(): void {
    try {
      this.router.navigate(["gastronomia/venue-details", this.prize.venue._id]);
    } catch (error) {
      this.router.navigate(["../"], { relativeTo: this.route });
    }
  }
}
