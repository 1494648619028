import { Injectable } from '@angular/core';
import { AppSettingsService } from './appsettings.service';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from './auth-guard.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpClientService {
  constructor(private _appSettingsService: AppSettingsService,
    private authGuardService: AuthGuardService,
    public _http: HttpClient) {

      this.client = this.authGuardService.getItemLS('client')

      this.header = {
        headers: new HttpHeaders({
          apitoken: this._appSettingsService.getSettings().apiEndpoint.token,
        }),
        'Content-Type': 'application/json',
      }
  }

  private client: any;
  private header: any;

  public get(_url);
  public get(_url, _params);
  public get(_url, _params?) {

    let params = _params ? '?' + new URLSearchParams(_params).toString() : '';

    return this._http.get(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url + params, this.header);
  }

  public getWithAuth(_url, _params?) {

    this.header = {
      headers: new HttpHeaders({
        apitoken: this._appSettingsService.getSettings().apiEndpoint.token,
        Authorization: 'Bearer ' + this.client.idToken
      }),
      'Content-Type': 'application/json',
    }

    let params = _params ? '?' + new URLSearchParams(_params).toString() : '';

    return this._http.get(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url + params, this.header);
  }

  public getWithStaticAuth(_url, _params?) {
    this.header = {
      headers: new HttpHeaders({
        apitoken: this._appSettingsService.getSettings().apiEndpoint.token,
        Authorization: 'Bearer ' + environment.iupp.itauStaticToken
      }),
      'Content-Type': 'application/json',
    }
    // this.header = this.header.headers.append('Authorization', 'Bearer ' + environment.iupp.itauStaticToken)

    let params = _params ? '?' + new URLSearchParams(_params).toString() : '';

    return this._http.get(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url + params, this.header);
  }

  public post(_url, _data) {
    try {
      this.header = {
        headers: new HttpHeaders({
          apitoken: this._appSettingsService.getSettings().apiEndpoint.token,
          Authorization: 'Bearer ' + _data.customer.accessToken
        }),
        'Content-Type': 'application/json',
      }

      return this._http.post(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url, _data, this.header)
    } catch (err) {
      throw err;
    }
  }

  public postWithStaticAuth(_url, _params?) {
    this.header = {
      headers: new HttpHeaders({
        apitoken: this._appSettingsService.getSettings().apiEndpoint.token,
        Authorization: 'Bearer ' + environment.iupp.itauStaticToken
      }),
      'Content-Type': 'application/json',
    }
    // this.header = this.header.headers.append('Authorization', 'Bearer ' + environment.iupp.itauStaticToken)

    let params = _params ? '?' + new URLSearchParams(_params).toString() : '';

    return this._http.post(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url, _params, this.header);
  }


  public put(_url, _data) {
    return this._http.put(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url, _data, this.header)
  }

  public delete(_url) {
    return this._http.delete(this._appSettingsService.getSettings().apiEndpoint.url + '/' + _url, this.header)
  }
}
