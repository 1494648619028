import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { AuthGuardService } from "app/_services/auth-guard.service";
import { AuthenticationService } from "app/_services/authentication.service";
import { LoaderService } from "app/_services/loader.service";

@Component({
  selector: "app-favorite",
  templateUrl: "./favorite.component.html",
  styleUrls: ["./favorite.component.scss"],
})
export class FavoriteComponent implements OnInit {
  public isLogged: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private authGuardService: AuthGuardService,
    private authService: AuthenticationService
  ) {}

  async ngOnInit() {
    this.isLogged = false;
    this.loaderService.start();

    await this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      const generatedCodeParam = params["code"] === "null" ? null : params["code"];

      if (
        !generatedCodeParam &&
        (!this.authGuardService.getCookie("token") || this.authGuardService.getCookie("token") == undefined)
      ) {
        this.isLogged = true;
        await this.authService.doLogin();
      } else {
        const treatedCodeParam = generatedCodeParam || this.authGuardService.getCookie("token");
        this.authGuardService.setCookie("token", treatedCodeParam, 1);
        if (!this.authGuardService.getItemLS("client")) {
          await this.authService.client(treatedCodeParam);
        }
        this.isLogged = true;
      }
    });

    this.loaderService.stop();
  }
}
