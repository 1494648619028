import { Component, OnInit } from "@angular/core";

@Component({
  selector: "filter-loading",
  templateUrl: "./filter-loading.component.html",
  styleUrls: ["./filter-loading.component.scss"],
})
export class FilterLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
