import { CheckoutFinishedComponent } from "./checkout-finished/checkout-finished.component";
import { OnboardingModalComponent } from "./checkout/modal/onboarding-modal/onboarding-modal.component";
import { VerificationCodeModalComponent } from "./checkout/modal/verification-code-modal/verification-code-modal.component";
import { LoaderService } from "./../_services/loader.service";
import { AuthenticationService } from "./../_services/authentication.service";
import { ExchangeService } from "./../_services/exchange.service";
import { AuthGuardService } from "./../_services/auth-guard.service";
import { CheckoutService } from "./../_services/checkout.service";
import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CurrencyPipe, registerLocaleData } from "@angular/common";
import localePT from "@angular/common/locales/pt";

//ROUTING
import { IuppRoutingModule } from "./iupp-routing.module";
//COMPONENTS
import { IuppComponent } from "./iupp.component";
import { IuppHomeComponent } from "./iupp-home.component";
import { PersonnaliteVenuesMosaicComponent } from "./iupp-venues-mosaic.component";
import { VenueDetailsComponent } from "./venue-details/venue-details.component";
//SHARED MODULES
import { SharedModule } from "../_shared/shared.module";
//EXTERNAL MODULES
import { BsDropdownModule, CarouselModule, ModalModule, TypeaheadModule } from "ngx-bootstrap";
import { PersonnaliteReservationConfirmModalComponent } from "./reservation-confirm-modal.component";

//SEVICES
import { VenueService } from "./../_services/venue.service";
import { GeolocationService } from "./../_services/geolocation.service";
import { CheckoutModule } from "./checkout/checkout.module";

//MATERIAL-ANGULAR
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
	MatIconModule,
	MatCheckboxModule,
	MatRadioModule,
	MatSelectModule,
	MatSliderModule,
	MatSnackBarModule,
	MatDialogModule,
	MatMenuModule,
	MatButtonModule,
	MatProgressSpinnerModule,
} from "@angular/material";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MenuDetailsComponent } from "./menu-details/menu-details.component";
import { WineDetailsComponent } from "./wine-details/wine-details.component";
import { VenueCardGhostComponent } from "./ghost/venue-card-ghost/venue-card-ghost.component";
import { CusineTypeGhostComponent } from "./ghost/cusine-type-ghost/cusine-type-ghost.component";
import { CookieService } from "ngx-cookie-service";
import { SliderGhostComponent } from "./ghost/slider-ghost/slider-ghost.component";
import { MobileBenefitCardGhostComponent } from "./ghost/mobile-benefit-card-ghost/mobile-benefit-card-ghost.component";
import { DesktopMenuContentComponent } from "./ghost/desktop-menu-content/desktop-menu-content.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { VenueNotFoundComponent } from "./venue-not-found/venue-not-found.component";
import { PriceInPointsPipe } from "./pipes/price-in-points.pipe";
import { MenusService } from "app/_services/menus.service";
import { DialogComponent } from "./dialog/dialog.component";
import { DeliveryTooltipComponent } from "./delivery-tooltip/delivery-tooltip.component";
import { ReservationComponent } from "./reservation/reservation.component";
import { VenueComponent } from "./component/venue/venue.component";
import { FilterAddressModalComponent } from "./filter-address-modal/filter-address-modal.component";
import { NewAddressComponent } from "./new-address/new-address.component";
import { HomeComponent } from "./page/home/home.component";
import { FilterComponent } from "./component/filter/filter.component";
import { MosaicComponent } from "./component/mosaic/mosaic.component";
import { LoadingComponent } from "./component/loading/loading.component";
import { FilterLoadingComponent } from "./component/loading/filter-loading/filter-loading.component";
import { VenueLoadingComponent } from "./component/loading/venue-loading/venue-loading.component";

import { BannerComponent } from "./component/banner/banner.component";
import { ModalRatingComponent } from "./modal-rating/modal-rating.component";
import { ModalDownRatingComponent } from "./modal-down-rating/modal-down-rating.component";
import { NavigatorComponent } from "./component/navigator/navigator.component";
import { ModalUserRatingComponent } from "./modal-user-rating/modal-user-rating.component";
import { ModalUserRatingMobileComponent } from "./modal-user-rating-mobile/modal-user-rating-mobile.component";
import { FavoriteComponent } from "./page/favorite/favorite.component";
import { HomeLoadingComponent } from "./component/loading/home-loading/home-loading.component";
import { FavoriteLoadingComponent } from "./component/loading/favorite-loading/favorite-loading.component";

registerLocaleData(localePT);

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SharedModule,
		BsDropdownModule.forRoot(),
		CarouselModule.forRoot(),
		ModalModule.forRoot(),
		TypeaheadModule.forRoot(),
		IuppRoutingModule,
		FormsModule,
		CheckoutModule,
		HttpClientModule,
		BrowserModule,
		MatIconModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSnackBarModule,
		MatSliderModule,
		MatDialogModule,
		MatMenuModule,
		MatButtonModule,
		BrowserAnimationsModule,
		MatInputModule,
		MatFormFieldModule,
	],
	entryComponents: [
		DialogComponent,
		VerificationCodeModalComponent,
		OnboardingModalComponent,
		ModalRatingComponent,
		ModalDownRatingComponent,
		ModalUserRatingComponent,
		ModalUserRatingMobileComponent,
	],
	declarations: [
		IuppComponent,
		IuppHomeComponent,
		PersonnaliteVenuesMosaicComponent,
		PersonnaliteReservationConfirmModalComponent,
		VenueDetailsComponent,
		MenuDetailsComponent,
		WineDetailsComponent,
		VenueCardGhostComponent,
		CusineTypeGhostComponent,
		SliderGhostComponent,
		MobileBenefitCardGhostComponent,
		DesktopMenuContentComponent,
		FooterComponent,
		HeaderComponent,
		VenueNotFoundComponent,
		PriceInPointsPipe,
		DialogComponent,
		DeliveryTooltipComponent,
		CheckoutFinishedComponent,
		ReservationComponent,
		VenueComponent,
		FilterAddressModalComponent,
		NewAddressComponent,
		HomeComponent,
		FilterComponent,
		MosaicComponent,
		LoadingComponent,
		FilterLoadingComponent,
		VenueLoadingComponent,
		BannerComponent,
		ModalRatingComponent,
		ModalDownRatingComponent,
		NavigatorComponent,
		ModalUserRatingComponent,
		ModalUserRatingMobileComponent,
		FavoriteComponent,
		HomeLoadingComponent,
		FavoriteLoadingComponent,
	],
	providers: [
		VenueService,
		GeolocationService,
		CurrencyPipe,
		{ provide: LOCALE_ID, useValue: "pt" },
		VenueService,
		CheckoutService,
		GeolocationService,
		ExchangeService,
		MenusService,
		AuthGuardService,
		LoaderService,
		AuthenticationService,
		CookieService,
		OnboardingModalComponent,
		PriceInPointsPipe,
	],
	exports: [
		IuppComponent,
		VenueDetailsComponent,
		IuppHomeComponent,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatFormFieldModule,
		MatSelectModule,
		MatSnackBarModule,
		MatSliderModule,
		MatDialogModule,
		PersonnaliteVenuesMosaicComponent,
		PersonnaliteReservationConfirmModalComponent,
		SliderGhostComponent,
	],
})
export class IuppModule {}