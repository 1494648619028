import { Component, OnInit, ContentChild } from '@angular/core';
import { IuppHomeComponent } from './iupp-home.component';

@Component({
  styleUrls: ['./iupp.component.scss'],
  templateUrl: "./iupp.component.html",
  providers: [IuppHomeComponent]
})
export class IuppComponent implements OnInit {
  constructor() { }
  @ContentChild(IuppHomeComponent)
  public homeComponent: IuppHomeComponent;
  public checkout: boolean = false

  ngOnInit() {

    // if(window.location.href.includes('checkout')){
    //   this.checkout = true
    // }

    var oldHref = document.location.href;

    window.onload = () => {

      var bodyList = document.querySelector("body"), observer = new MutationObserver((mutations) => {

        mutations.forEach((mutation) => {

          if (oldHref != document.location.href) {
            oldHref = document.location.href;

            if(oldHref.includes('checkout')){
              this.checkout = true
            }else{
              this.checkout = false
            }    
          }

        });  

      });

      var config = {
        childList: true,
        subtree: true
      };

      observer.observe(bodyList, config);

    };

  }

}
