import { Component, OnInit } from "@angular/core";
import { CarouselConfig } from "ngx-bootstrap/carousel";
import * as _ from "lodash";
import { Banner } from "./banner.interface";
import { Router } from "@angular/router";

@Component({
  selector: "banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
  providers: [{ provide: CarouselConfig, useValue: { interval: 1500, noPause: false, showIndicators: true } }],
})
export class BannerComponent implements OnInit {
  public baseUrl: string = "./../../../../assets/images/banner";
  public banners: Array<Banner> = [
    {
      src: `${this.baseUrl}/bannerHome-01.png`,
      alt: "Um menu exclusivo nos melhores restaurantes",
      link: null,
      order: 1,
    },
    {
      src: `${this.baseUrl}/bannerHome-02.png`,
      alt: "Troque pontos pelo Menu Personnalité",
      link: null,
      order: 2,
    },
    {
      src: `${this.baseUrl}/bannerHome-03.png`,
      alt: "Aproveite esta e outras experiências gastronômicas",
      link: null,
      order: 3,
    },
  ];
  public interval: number = 5000;

  constructor(private router: Router) {}

  ngOnInit() {
    this.bannerSort();
  }

  bannerSort() {
    this.banners = _.sortBy(this.banners, ["order"]);
  }

  navigate(banner: Banner) {
    if (!banner.link) return;
    this.router.navigate([banner.link]);
  }
}
