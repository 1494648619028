import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { DialogComponent } from "./../client/dialog/dialog.component";
import { HttpClientService } from "./httpclient.service";
import { BehaviorSubject, Observable } from "rxjs"; //import all operators
import * as _ from "lodash";
import { AuthGuardService } from "./auth-guard.service";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class AuthenticationService {
  private authToken = new BehaviorSubject<string>(null);
  private isLoggedIn = new BehaviorSubject<boolean>(true);

  public authToken$ = this.authToken.asObservable();
  public isLoggedIn$ = this.isLoggedIn.asObservable();

  private redirect_logged = environment.config.redirect_logged;
  private redirect_logout = environment.config.redirect_logout;

  private logoutLtmUrl =
    environment.iupp.url.auth +
    "/logout?client_id=" +
    environment.iupp.clientID +
    "&logout_uri=" +
    this.redirect_logout;

  constructor(
    private _httpClient: HttpClientService,
    private dialog: MatDialog,
    private authGuardService: AuthGuardService
  ) {}

  public getAuthToken(): string {
    return this.authToken.getValue();
  }

  setAuthToken(_token): void {
    this.authToken.next(_token);
  }

  public getLoggedInStatus(): boolean {
    // let user = this._currentUserService.getCurrentUser();
    // console.log(this.client);
    if (this.client) {
      this.setAuthToken(this.client["token"]);
      this.isLoggedIn.next(true);
    }
    return this.isLoggedIn.getValue();
  }

  public signIn(stateParam) {
    const signInUrl =
      environment.iupp.url.auth +
      "/oauth2/authorize?client_id=" +
      environment.iupp.clientID +
      "&redirect_uri=" +
      this.redirect_logged +
      "&response_type=code&scope=profile webpremios.campaigns/40455 email openid&state=";

    window.open(signInUrl.concat(stateParam), "_self");
  }

  public logout() {
    this.authGuardService.deleteCookie("token");
    this.authGuardService.deleteCookie("catalogId");
    this.authGuardService.deleteCookie("userName");
    this.authGuardService.deleteCookie("points");
    localStorage.clear();
    // this.logoutLtm();
  }

  public logoutLtm() {
    this._httpClient.get("ltm/client/logout");
    window.open(this.logoutLtmUrl, "_self");
  }

  public doLogin() {
    this.logout();
    // this.signIn(null);
  }

  public isLogged(): any {
    const tokenCookie = this.authGuardService.getCookie("token");

    if (!tokenCookie) this.doLogin();
  }

  public getLtmClient(_token: String): Observable<any> {
    // console.log("getLtmClient - URL: " + "ltm/client/get/" + _token);
    return this._httpClient.get("ltm/client/get/" + _token).toPromise();
  }

  public checkLTMLogin(objLTMError) {
    if (
      _.get(objLTMError, "_body") === "401" ||
      _.get(objLTMError.error, "message.body.error") === "invalid_grant" ||
      _.get(objLTMError.error, "message.body.error") ===
        "unauthorized_client" ||
      _.get(objLTMError.error, "message.body.error") === "invalid_client"
    ) {
      this.authGuardService.alert(
        "Houve problemas na autenticação de usuário. Favor se logar novamente.",
        "ok"
      );

      // console.log("checkLTMLogin - err: " + JSON.stringify(objLTMError.error));
      this.logout();
      return false;
    } else if (
      _.get(objLTMError.error, "message") === {} ||
      _.get(objLTMError.error, "code") === {} ||
      _.get(objLTMError.error, "code") === 500 ||
      _.get(objLTMError.error, "code.message") === "Invalid response"
    ) {
      this.authGuardService.alert(
        "Erro no servidor. Favor aguardar alguns minutos e tentar novamente.",
        "ok"
      );

      // console.log("checkLTMLogin - err: " + JSON.stringify(objLTMError.error));
      this.logout();
      return false;
    }
  }

  public async client(generatedCodeParam: String) {
    try {
      // console.log("checkLTMLogin - token: " + generatedCodeParam);
      // const client = await this.getLtmClient(generatedCodeParam);
      // console.log("checkLTMLogin - err: " + JSON.stringify(client));
      // console.log(client);

      // this.checkLTMLogin(client);

      const client = {
        "id": 28731510,
        "profileId": 51135,
        "name": "Usuario Tsste",
        "companyName": null,
        "username": "93699008029",
        "documentNumber": "93699008029",
        "partnerCpf": "93699008029",
        "rg": null,
        "ie": null,
        "birthDate": "1991-07-23T03:00:00+00:00",
        "persontypeId": 1,
        "persontype": "INDIVIDUAL",
        "genderTypeId": 1,
        "genderType": "MALE",
        "maritalStatusId": 6,
        "maritalStatus": "UNINFORMED",
        "statusId": 1,
        "status": "ACTIVE",
        "emails": [
            {
                "email": "93699008029@mailinator.com",
                "type": "PERSONAL"
            }
        ],
        "phones": [
            {
                "areaCode": "11",
                "number": "985206860",
                "type": "MOBILE"
            }
        ],
        "address": {
            "street": "Nao Informado",
            "number": "99",
            "complement": null,
            "district": "Nao Informado",
            "city": "Nao Informado",
            "state": "SP",
            "zipCode": "00000000",
            "reference": "n/i"
        },
        "accessToken": "eyJraWQiOiJUZEJmVGVEbGUwaXVYOEZLUHNOdmpGNzdsQk8xN0dnelowMXlHUlZSWW9VPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI1NWIxNGQwOC0wODdhLTQzY2YtOGQxMC03MTY0OGJmMmY2OTYiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84R3FrbDFJcmIiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiI3bW45bDVqYWRiazl0MGp1aDdxOWxmNTdpdSIsImV2ZW50X2lkIjoiZmVhMzI4NTAtNTg3Yi00Yjg0LWIzYjYtN2FmY2QzZDg4NTdmIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSB3ZWJwcmVtaW9zLmNhbXBhaWduc1wvNDA0NTUgZW1haWwiLCJhdXRoX3RpbWUiOjE2NjY2Mzc1MDAsImV4cCI6MTY2NjY0MTEwMCwiaWF0IjoxNjY2NjM3NTAwLCJqdGkiOiJhYTY5NjhmYS1kN2I1LTRkZDgtOGExNS0zYWJhN2UwOTVmNjgiLCJ1c2VybmFtZSI6IjkzNjk5MDA4MDI5In0.NwMnIUYVdJc2hN4fGrup5EyZQGzfOB7HN-mxiIsUolTjxS6U_VG04TcLtjcteJKgG6WSem8b-Aw8nJJIC6cTGDq6kk8voPyjui5jGMosVnDv8AKx29zbJoQ9q0Evagf0KRP0uYozM5Kr8lFANQB2e9y6RYCRdo8yygpcIl3NL1Ulv7qVDYvdwN3TWcdBhyPyc4jsfzSzMlPAQ6dK7Pn1ZIUwmEmQEFxWhJVK_CfJS3ddlxixFYZlO4ICj1RfB0xGVUYcXlMfRJFB8xY5ZYl6SVA74MdrNzc-uHo0piJOOCkwEnpIGs-vtzHCjXrCsXi4qXuYuPIXRmpkPsSvmH-fXw",
        "refreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.R2DlOLWuRxiKELBKyd0BomKLhNHr5jGB_Wffj_koX3FJlQ-toyJ60Sx-uPWRfb3F-KlRhkP7E1IqJcNmrH4MRflWyPH5ni8Rx3LOIKY0Qx1B1NIGLiRVwZfy6VvHfdx16JG_l8Q6m-at-wrLK8s991x6dXGKsj2d8oxKCuXqw1-ReQQLfvtZNajseagL6GsMHJsYCfVmK6PK48pKGOecsvNbKpAHAAoQIvnm6qAXfdmM__zmsgZ9rYRCbqTqTdnoY4_WFSWAbG4Wp5Brb2ZLQmNk9X4-XMwxC8ihu1JwtN4MMoGYRRU23_n6RwhLY4luKv2aBcTEICbzVlL79Y_Cbg.oHkBOpT0OGV2lVxv.5ZHMUEA6zXw49GZvOMELgdB0wvxrR-psbT3f7xUfNeKFoAi5TRzIakU6wVw-Ha3uzBS_QeVuSHHNZvj4i2eSyfoJLlGuoq4zF26gsIblg63MNTWHOQC-nFVvYKWs5YrXrzS632_x3eg1UKqqQXDTKTkXXeYJGLcVqTA06Cd0x9-J6cKJu_qtUUxVAG9HtmEJGpxvfJeu-14qnRuxvj4e4vsSvoFeossb-OwNmcRxhwHLOnFakRalK006PxnYXJzUHObQ8HdWafLVqW6bUmVLusGtT1LE3LEqBo8xtKFQjPGhJyrTSodlFM6xudVu5o89Fldb3VELhPgb6Q3sGEoPtzjm2Y0y-gbSVrBvhv8x6B8jYeFxwPUlGYhi2EieZT6LDJ0FLPZ-F6o5JQCB3-TyeqZ02Oxwc7oXdNsXAZIo_kBxUvkFw1TsJHNLF8-YMhN-5K9fn5KL4ttjfY27Llk2lHJrazYudpFj3x2Ti2wMfo66Sm6yJ5Kkv8uhH0QB9ghIyzgNwPTnsUu_pttKIzxI2OpQFJ91NgBEw4k-ni5Hsbm3e13WY0Vw0Jk_wHDcgPMwaRi8peInUUDWzsbtqBr7UZIhuxJ6AsjXtrgqO5meh4yM5_klJYVqDjJio733doAlAmVyUFn4fsG2n3FjwoIi22tRGGpX7a2oWXW0pHLHYhOsy2FvtY3QTfZmqBV7ph-qqrcM-v6L7T4k8OeIYIGCrXmS7w3nvYZRJQWlHGflfec-y0YN1qDUeQ5etbGXzUUeRiHhZScRbZPoHBP2TLPIndeLncr91au7CbADUVIM4dxCSsgcgiFAJwjCEQC7SA8kiU_8BreID5NXKylyJrxalbZIQYQpihnfF5HBuZSlh7kQZNx4JaPLC6Bvw4GJXRqHSv_8ZHK9pXc7eu77vQXaCRMS3HffP_s0N3KV8vi9mbfhYSbOHCR8XfqsTyQuRJZwSkzhDL-czC3LVjHkD5tb92lUOLb4JXDwuh-ZjmkEmFvCXeWCWPLPIySVgDqfdoWd5po8PQHz4lTdcDKaSHj00iH3sDPHwLhAwN0NwDpyNXhGka1LlY-pgcOWpmapQYeZ50ZIzvkYGJnkmJNP0HNyfEW5o_742xjmm_ZlmwxODWL0OT1-pQxmQYvCDMrVEHMDhgq-FSUKGN3XvctnY_rurLtCFxMvjfacimHOhdAoN8Mo-qbJItT06gjSmQUD587KetIKMubbqRpy5MzcZbRYGsX-3VQ2aP-sUShe05fRwSYDWiqDO2UlF-r2ulGJ2jplTWz2VJw2Ze6pPDXMU0STsx3mBYCjWVmqRKbOjV5kjw8PJR52ft0HPPAoLxSX562-Hvpv1w.EI82nr2aYDvd-sekBSEWUg",
        "idToken": "eyJraWQiOiJcL2U0NXpUSThzcWlHU01vMmczMHdRWCtkOVFrOFhaMDQ0SnFWRWlaeW1Sdz0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiVWZSemRYbDRWLWVPSE1qZ2hxMzVDQSIsInN1YiI6IjU1YjE0ZDA4LTA4N2EtNDNjZi04ZDEwLTcxNjQ4YmYyZjY5NiIsImN1c3RvbTpjYW1wYWlnbl9pZCI6IjQwNDU1IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfOEdxa2wxSXJiIiwiY3VzdG9tOmNhdGFsb2dfaWQiOiI0MDUzOSIsImNvZ25pdG86dXNlcm5hbWUiOiI5MzY5OTAwODAyOSIsImN1c3RvbTpwYXJ0aWNpcGFudF9pZCI6IjI4NzMxNTEwIiwiYXVkIjoiN21uOWw1amFkYms5dDBqdWg3cTlsZjU3aXUiLCJldmVudF9pZCI6ImZlYTMyODUwLTU4N2ItNGI4NC1iM2I2LTdhZmNkM2Q4ODU3ZiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjY2NjM3NTAwLCJjdXN0b206Y2xpZW50X2lkIjoiMzA1NDciLCJleHAiOjE2NjY2NDExMDAsImlhdCI6MTY2NjYzNzUwMH0.k-hUXEi5zShd2ml6vpAzpEjkRnLm_PKOQhNRsVs3bUza11sEjJAItieqYYVkTl5OHv6VVv3M6-NA8YFZGo3_CPHssf0I3vEj0108jxXXg0WApYdGCN6IOkptIDdm58UOYCtqneR6k3M8u_0N2vwTEAQUjti4XVwmgV3NjvQA1730bVccEuHqQFIgb6lNEpiNuLU0fIvxbnMcHhhxf78ZqcDYY9dibcIaWVjbQ-wTt6hiaC1kwdaTF7jPDNBTz-PQztpFNCsTFp6G_JyNlxQXeGkCpi2mLdTg_XNKy4yJvUFdQ9W6u2jCxN2S-rAWgDPDqigkXImGgH9JWENnhnjnvw",
        "partnerID": "619bf41b465719a6b85afb65",
        "customerID": "615766f068d8f5fe80db47a3",
        "catalogId": "40539",
        "points": 10
    }

      if (client && client["name"]) {
        this.authGuardService.setCookie("catalogId", client["catalogId"], 1);
        this.authGuardService.setCookie("userName", client["name"], 1);
        this.authGuardService.setCookie("points", client["points"], 1);
        this.authGuardService.setItemLS("client", client);
        this.authGuardService.setUser(client);
      }

        // this.authGuardService.setCookie("catalogId", '40543', 1);
        // this.authGuardService.setCookie("userName", 'MMWM', 1);
        // this.authGuardService.setCookie("points", '001', 1);
        // this.authGuardService.setItemLS("client", client);
        // this.authGuardService.setUser(client);


      return client;
    } catch (err) {
      this.authGuardService.alert(
        "Ops. Erro ao carregar dados do participante. Entre em contato com o suporte, por favor.",
        "ok",
        this
      );
    }
  }

  public getBalanceClient(_token: String): Observable<any> {
    return this._httpClient.get("ltm/client/balance/" + _token);
  }

  public alertLogin() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        message:
          "Tempo de sessão expirado. Por favor, faça o login novamente. Obrigado.",
        buttonText: {
          ok: "Login",
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.logout();
    });
  }
}
