import { NgModule, LOCALE_ID }                      from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe }  from '@angular/common';
import { FormsModule, ReactiveFormsModule }         from '@angular/forms';

//IMPORT PARA CROPER DO IMAGE UPLOAD
import { TypeaheadModule, BsDropdownModule }        from 'ngx-bootstrap';

//INTERFACE
import { FooterComponent }                          from './footer.component';
import { LoaderComponent }                          from './loader.component';

@NgModule({
    imports: [ 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        TypeaheadModule.forRoot()
    ],
    declarations: [
        FooterComponent,
        LoaderComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "pt-BR" },
        CurrencyPipe,
        DecimalPipe
    ],
    exports: [
        FooterComponent,
        LoaderComponent
    ]
})
export class SharedModule {}
