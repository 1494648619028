import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClientService } from './httpclient.service';
import * as _ from 'lodash';
import { OnboardingModalComponent } from './../client/checkout/modal/onboarding-modal/onboarding-modal.component';
import { VerificationCodeModalComponent } from './../client/checkout/modal/verification-code-modal/verification-code-modal.component';

@Injectable()
export class CheckoutService {

  constructor(private _httpClient: HttpClientService, private dialog: MatDialog) { }

  public finishPayment(_body: Object): Observable<any> {
    // console.log(_token);
    return this._httpClient.post('ltm/checkout/v2', _body)
  }

  public finishReservation(_body: Object): Observable<any> {
    // console.log(_token);
    return this._httpClient.post('ltm/reservation', _body)
  }

  public sendTokenCheckFraud(_body: Object): Observable<any> {
    return this._httpClient.post('ltm/fraud/checkout', _body)
  }

  public fraudModal(_shoppingCart) {
    this.dialog.open(VerificationCodeModalComponent, {
      disableClose: true,
      data: _shoppingCart
    });
  }

  public onBoardingModal() {
    this.dialog.open(OnboardingModalComponent, {
      disableClose: true
    });
  }
}
