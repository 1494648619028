import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClientService } from "./httpclient.service";
import * as _ from "lodash";

@Injectable()
export class LogService {
  constructor(private _httpClient: HttpClientService) {}

  public registerFilterLog(_selectedFilter: any): Observable<any> {
    var urlSuffix = "Log/RegisterWithoutRecommendation";
    var filterLog = {
      type: "filter",
      details: {
        filterType: _selectedFilter.type,
        selectedValue: _selectedFilter.value,
        event: "Click",
      },
      origin: "IUPP",
      customer: JSON.parse(localStorage.getItem("client")),
    };

    return this._httpClient.post(urlSuffix, filterLog);
  }

  public registerCancelReservationLog(_venue: any, _reservationData: any): Observable<any> {
    var urlSuffix = "Log/RegisterWithoutRecommendation";
    var logData = {
      type: "cancelReservation",
      details: {},
      origin: "IUPP",
      venue: _venue,
    };
    logData.details["reservationId"] = _reservationData._id;
    //console.log('canceled reservationData:', JSON.stringify(_reservationData, null, 4));
    return this._httpClient.post(urlSuffix, logData);
  }

  public registerOpenReservationWidgetLog(_venue: String): Observable<any> {
    var urlSuffix = "Log/RegisterWithRecommendation";
    var data = {
      type: "startReservation",
      venue: _venue,
      details: {},
      origin: "IUPP",
    };
    data.details["event"] = "AddShopCart"; // For azure recommendation usage

    return this._httpClient.post(urlSuffix, data);
  }

  public registerCloseReservationWidgetLog(_venue: String): Observable<any> {
    var urlSuffix = "Log/RegisterWithRecommendation";
    var data = {
      type: "discardReservation",
      venue: _venue,
      details: {},
      origin: "IUPP",
    };
    data.details["event"] = "RemoveShopCart"; // For azure recommendation usage

    return this._httpClient.post(urlSuffix, data);
  }

  public registerReservationConfirmation(_venue: String, _reservationData: any): Observable<any> {
    var urlSuffix = "Log/RegisterWithRecommendation";
    var data = {
      type: "createReservation",
      venue: _venue,
      details: {},
      origin: "IUPP",
    };
    data.details["event"] = "Purchase"; // For azure recommendation usage
    data.details["reservationData"] = _reservationData;
    return this._httpClient.post(urlSuffix, data);
  }
}
