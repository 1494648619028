import  { Component, OnInit } from '@angular/core';

import { LoaderService }     from '../_services/loader.service';

@Component({
    selector: 'tg-loader',
    template: `
        <div class="loader" *ngIf="active">
            <div class="loader-inner line-spin-fade-loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    `
})

export class LoaderComponent implements OnInit {
    constructor(private _loaderService: LoaderService) {}
    public active: boolean = false;
    ngOnInit() {
        this._loaderService.status$.subscribe((status: boolean) => {
            this.active = status;
        });
    }
}




