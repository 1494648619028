import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SharedDataService } from "app/_services/sharedData.service";
import { Filter, FilterAddress, FilterProp, FilterSearchDetail, FilterStyle } from "./filter.interface";
import { GeolocationService } from "../../../_services/geolocation.service";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { VenueService } from "app/_services/venue.service";
import { LogService } from "app/_services/log.service";
import { Router } from "@angular/router";
import { AuthGuardService } from "app/_services/auth-guard.service";
import { FilterService } from "./filter.service";
import { MosaicService } from "../mosaic/mosaic.service";

@Component({
	selector: "filter",
	templateUrl: "./filter.component.html",
	styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, OnDestroy {
	@Input() slug: string = "filter";
	public searchTimeout: any = null;
	public mobileFilterPropToggler: boolean = true;
	public filterSearchDatalists: any = [];
	public activeGroup = false

	public $filterSubs: Subscription;
	public filter: Filter | null = this.filterService.filter.getValue();

	@ViewChild('inputSearch') input: ElementRef

	constructor(
		private filterService: FilterService,
		private mosaicService: MosaicService,
		private sharedDataService: SharedDataService,
		private geoService: GeolocationService,
		private venueService: VenueService,
		private router: Router,
		private logService: LogService,
		private authGuardService: AuthGuardService
	) {}

	async ngOnInit() {
		this.$filterSubs = this.filterService.receiveFilter().subscribe((res) => (this.filter = res));
		await this.initFilter();
	}

	setActiveGroupTrue() {
		this.activeGroup = true
		this.input.nativeElement.focus()
	}

	ngOnDestroy() {
		this.$filterSubs.unsubscribe();
	}

	public async initFilter() {
		this.dispatchFilter({ ...this.filter, loading: true });
		const stored = this.authGuardService.getItemLS(`${this.slug}`) || {};

		if (Object.keys(stored).length > 0) return this.dispatchFilter({ ...stored, loading: false });

		const filter: Filter = {
			slug: this.slug,
			loading: true,
			search: "",
			searchDatalist: await this.getSearchDatalist(),
			location: { latitude: null, longitude: null },
			address: await this.getAddress(),
			style: await this.getStyle(),
			cousine: await this.getCousine(),
			prize: await this.getPrize(),
			reservation: await this.getReservation(),
		};

		this.filter = filter;
		await this.getAddressByGeoLocation();
	}

	public save() {
		this.authGuardService.setItemLS(`${this.slug}`, this.filter);
	}

	public openCitySubmenu($el: any, city: FilterAddress) {
		$el.stopPropagation();
		this.filter.address = this.filter.address.map((item) =>
			!item.isOpen ? { ...item, isOpen: city.id === item.id } : { ...item, isOpen: false }
		);
	}

	public async clearCache() {
		localStorage.removeItem(`${this.slug}`);
		this.mosaicService.sendMosaic({ ...this.mosaicService.mosaic.getValue(), venues: [], loading: false });
		this.filterService.sendFilter({
			slug: this.slug,
			loading: true,
			search: "",
			searchDatalist: [],
			location: { latitude: null, longitude: null },
			address: [],
			style: [],
			cousine: [],
			prize: [],
			reservation: [],
		});
		await this.initFilter();
	}

	private async getSearchDatalist() {
		return await this.venueService.getFilterSearchDatalist().toPromise();
	}

	private async getAddress() {
		const res = await this.sharedDataService.getFilterCities().toPromise();
		const addresses = res.map((address, index1) => ({
			id: index1 + 1,
			name: address.city,
			selected: false,
			isOpen: false,
			neighborhoods: address.neighborhoods.map((neighborhood, index2) => ({
				id: `${index1 + 1}_${index2}`,
				name: neighborhood.neighborhood,
				selected: false,
				isOpen: false,
			})),
		}));
		return [{ id: 0, name: "Todas as cidades", selected: true, neighborhoods: [] }, ...addresses];
	}

	private async getStyle() {
		const styles: Array<FilterStyle> = [
			{ id: 0, name: "Todos", selected: true, allowed: null },
			{ id: 1, name: "Jantar fino", selected: false, allowed: "jantar-fino" },
			{
				id: 2,
				name: "Sofisticados e Bistrôs",
				selected: false,
				allowed: "sofisticados-e-bistros",
			},
			// {
			// 	id: 3,
			// 	label: "Casual",
			// 	name: "Menu casual",
			// 	selected: false,
			// 	allowed: "menu-casual",
			// },
			// {
			// 	id: 4,
			// 	label: "Fast",
			// 	name: "Menu Fast",
			// 	selected: false,
			// 	allowed: "menu-fast",
			// },
		];
		return styles;
	}

	private async getCousine() {
		let tempCousines = await this.venueService.getCuisineFromAPI().toPromise();
		const adaptedTempCousineToInterface: Array<FilterProp> = tempCousines.map((item, index) => ({
			id: index + 1,
			name: item._id,
			selected: false,
		}));
		const cousines: Array<FilterProp> = [
			{ id: 0, name: "Todos", selected: true },
			..._.sortBy(adaptedTempCousineToInterface, ["name"]),
		];
		return cousines;
	}

	private async getPrize() {
		const prizes: Array<FilterProp> = [
			{ id: 0, name: "Todos", selected: true },
			{ id: 1, name: "Grand Menu", selected: false },
			{ id: 2, name: "Menu Personnalité", selected: false },
			{ id: 3, name: "Vinho Personnalité", selected: false },
			// { id: 4, name: "Menu Casual", selected: false },
			// { id: 5, name: "Menu Fast", selected: false },
		];
		return prizes;
	}

	private async getReservation() {
		const reservations: Array<FilterProp> = [
			{ id: 0, name: "Todos", label: "Todos", selected: true },
			{
				id: 1,
				name: true,
				label: "Restaurantes com reservas",
				selected: false,
			},
			{
				id: 2,
				name: false,
				label: "Restaurantes sem reservas",
				selected: false,
			},
		];
		return reservations;
	}

	public async dispatchFilter(filter: Filter) {
		this.filterService.sendFilter(filter);
	}

	public async setSelected(filterProp: string, prop: FilterProp) {
		// if (!this.filter || !this.authGuardService.getItemLS("client")) return;

		// this.logService.registerFilterLog({ type: filterProp, value: prop }).toPromise();

		if (filterProp === "address") {
			this.setSelectedAddress(prop);
			this.save();
			return this.dispatchFilter({ ...this.filter, loading: false });
		}

		this.filter[filterProp] = this.filter[filterProp].map((item) => ({
			...item,
			selected: item.id === prop.id,
		}));

		// this.save();
		return this.dispatchFilter({ ...this.filter, loading: false });
	}

	public async setSelectedAddress(prop: FilterProp) {
		this.filter["address"] = this.filter["address"].map((city) => {
			// Força todas as cidades para pagina de favoritos
			if (this.slug === "favorite" && city.name === "Todas as cidades") {
				city = { ...city, selected: true };
				return city;
			} else {
				city = { ...city, selected: city.id === prop.id };
			}

			city.neighborhoods = city.neighborhoods.map((neighborhood) => ({
				...neighborhood,
				selected: neighborhood.id === prop.id,
			}));

			return city;
		});
	}

	public handleSearchField(el: any) {
		this.filter.search = el.target.value;

		const foundedVenue = this.filter.searchDatalist.find(
			(venue: FilterSearchDetail) => venue.name === this.filter.search
		);

		if (foundedVenue) return this.router.navigate(["gastronomia/venue-details", foundedVenue._id]);

		// Descomente o bloco de código para buscar na API
		/*
    // _.debounce do lodash não funciona por algum motivo.
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.dispatchFilter({ ...this.filter, loading: false });
    }, 1500);
    */
	}

	public handleSearchSubmit(el: any) {
		el.preventDefault();
		return this.dispatchFilter({ ...this.filter, loading: false });
	}

	public getSelected(filterProp: string) {
		if (!this.filter) return { name: "" };

		if (filterProp === "address") {
			for (let city of this.filter[filterProp]) {
				if (city.selected) return city;
				for (let neighborhood of city.neighborhoods) if (neighborhood.selected) return neighborhood;
			}
		}
		return this.filter[filterProp].find((item) => item.selected);
	}

	public async getAddressByGeoLocation() {
    if(/iphone|ios|ipad/.test(window.navigator.userAgent.toLowerCase())) {
      this.save();
      return this.dispatchFilter({ ...this.filter, loading: false });
    }

		await this.geoService.getGeoLocation().subscribe(
			async (location) => {
				this.filter.location = location;
				const { latitude, longitude } = location;

				this.filterService.getAddressByGeolocation(latitude, longitude).subscribe(
					(res) => {
						if (!res.address) {
							this.save();
							return this.dispatchFilter({ ...this.filter, loading: false });
						}
						for (let city of this.filter.address) {
							for (let neighborhood of city.neighborhoods) {
								if (neighborhood.name.toLowerCase() === res.address.suburb.toLowerCase()) {
									this.setSelectedAddress(neighborhood);
									break;
								}
							}

							if (res.address.city && city.name.toLowerCase() === res.address.city.toLowerCase()) {
								this.setSelectedAddress(city);
								break;
							}

							if (
								res.address.city_district &&
								city.name.toLowerCase() === res.address.city_district.toLowerCase()
							) {
								this.setSelectedAddress(city);
								break;
							}
						}
						this.save();
						this.dispatchFilter({ ...this.filter, loading: false });
					},
					(error) => {
						this.save();
						this.dispatchFilter({ ...this.filter, loading: false });
					}
				);
			},
			(error) => {
				this.save();
				this.dispatchFilter({ ...this.filter, loading: false });
			}
		);
	}

	public toggleMobileFilterPropToggler() {
		this.mobileFilterPropToggler = !this.mobileFilterPropToggler;
	}
}
