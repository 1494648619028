import { AuthGuardService } from "./../_services/auth-guard.service";
import { Component, Input, OnInit } from "@angular/core";
import { AppSettingsService } from "../_services/appsettings.service";
import { SharedDataService } from "../_services/sharedData.service";
import * as _ from "lodash";
import { Router } from "@angular/router";

@Component({
  selector: "tg-venues-mosaic",
  styleUrls: ["./iupp-home.component.scss", "./iupp-venues-mosaic.component.scss"],
  templateUrl: "./iupp-venues-mosaic.component.html",
})
export class PersonnaliteVenuesMosaicComponent implements OnInit {
  @Input() venues;
  @Input() listTypeCss;
  @Input() exchanges;
  @Input() showGhosts;
  @Input() unsetLeftMargin: false;
  @Input() topFilterActive;
  public loadingVenues: Array<null> = Array(12).fill(null);

  constructor(
    private _appSettingsService: AppSettingsService,
    private _authGuardService: AuthGuardService,
    private router: Router,
    private _sharedDataService: SharedDataService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
  }

  public imgPrefixPath: string = this._appSettingsService.getSettings().imageUrl;

  // public signIn(venueId) {
  //   this._authGuardService.signIn(venueId);
  // }

  public goVenuePage(venueId): void {
    this.router.navigate(["gastronomia/venue-details", venueId]);
  }

  public hasWine(prizeNames: Array<string>): boolean {
    return this._sharedDataService.hasWine(prizeNames);
  }

  public getVenueAddress(venue): string {
    if (!venue || !venue.address) return "";

    let address: string = "";

    if (venue.address.neighborhood) address = venue.address.neighborhood.trim();

    if (address === "") address = venue.address.city;
    else address += " - " + venue.address.city;

    return address;
  }

  public getCuisine(index: number, _venue: any): string {
    return this._sharedDataService.getCuisine(index, _venue);
  }

  public getVenueStyle(prizeNames: Array<string>): string {
    return this._sharedDataService.getVenueStyle(prizeNames);
  }

  public getVenuePrize(prizeNames: Array<string>): string {
    return this._sharedDataService.getVenuePrize(prizeNames);
  }

  public getVenuePhoto(venue) {
    let venuePhoto: string = this.imgPrefixPath;

    try {
      venuePhoto += venue.apps.itau.mobileCovers[0].coverUrl;
    } catch (error) {
      venuePhoto += "no-image-ibeneficios.jpg";
    }

    return venuePhoto;
  }

  public definePoints(_venue, _price) {
    let points = 0;

    if (this.exchanges && this.exchanges.length > 0) {
      this.exchanges.forEach((exchange) => {
        if (exchange.prize.price === _price) {
          _venue.prizePrices.forEach((prizePrice) => {
            if (exchange.prize.price === prizePrice) {
              points = Math.trunc(parseInt(prizePrice, 0) / exchange.rate);
            }
          });
        } else {
          points = Math.trunc(parseInt(_price, 0) / this.sharedDataService.CPP);
        }
      });
    } else {
      points = Math.trunc(parseInt(_price, 0) / this.sharedDataService.CPP);
    }

    return points;
  }
}
