import { Component, Input } from '@angular/core';
import { ShoppingCart } from '../interfaces/ShoppingCart';

@Component({
  selector: 'app-subtotal',
  templateUrl: './subtotal.component.html',
  styleUrls: ['./subtotal.component.scss'],
})
export class SubtotalComponent {
  @Input() valueInPoints: number = 0;
  @Input() valueInCash: number = 0;
  @Input() shoppingCart: ShoppingCart;
}
