import { Mock } from "../interfaces/Mock.interface";
import { AccordionData } from "../interfaces/AccordionData";

export class AccordionDataMock implements Mock {
  static default(): Array<AccordionData> {
    return [
      {
        htmlId: "benefits",
        icon: "fa fa-beneficio-icone fa-2x",
        title: "Benefícios em<br>Rubayat Faria Lima",
        disabled: false,
        isConcluded: false,
      },
      {
        htmlId: "personal",
        icon: "itau itau-perfil-icone",
        title: "Dados pessoais<br>para emissão do voucher",
        disabled: true,
        isConcluded: false,
      },
      {
        htmlId: "reservation",
        icon: "itau itau-calendario-icone",
        title: "Reserva em<br>Rubayat Faria Lima",
        disabled: true,
        isConcluded: false,
      },
      {
        htmlId: "payment",
        icon: "itau itau-carteira-icone",
        title: "Pagamento",
        disabled: true,
        isConcluded: false,
      },
    ];
  }

  static custom(venue): Array<AccordionData> {
    return [
      {
        htmlId: "benefits",
        icon: "fa fa-meu-pedido fa-2x", //fa fa-delivery-icon,
        title: `Meu pedido em ${venue.name}`,
        disabled: false,
        isConcluded: false,
      },
      {
        htmlId: "personal",
        icon: "itau itau-perfil-icone",
        title: "Dados pessoais<br>para emissão do voucher",
        disabled: true,
        isConcluded: false,
      },
      {
        htmlId: "reservation",
        icon: "itau itau-calendario-icone",
        title: `Reserva em<br>${venue.name}`,
        disabled: true,
        isConcluded: false,
      },
      {
        htmlId: "payment",
        icon: "itau itau-carteira-icone",
        title: "Pagamento",
        disabled: true,
        isConcluded: false,
      },
    ];
  }

  static reservationFirst(venue): Array<AccordionData> {
    return [
      {
        htmlId: "reservation",
        icon: "itau itau-calendario-icone",
        title: `Reserva em<br>${venue.name}`,
        disabled: false,
        isConcluded: false,
      },
      {
        htmlId: "personal",
        icon: "itau itau-perfil-icone",
        title: "Dados pessoais<br>para emissão do voucher",
        disabled: true,
        isConcluded: false,
      },
      {
        htmlId: "benefits",
        icon: "fa fa-beneficio-icone fa-2x",
        title: `Benefícios em<br>${venue.name}`,
        disabled: true,
        isConcluded: false,
      },
      {
        htmlId: "payment",
        icon: "itau itau-carteira-icone",
        title: "Pagamento",
        disabled: true,
        isConcluded: false,
      },
    ];
  }
}
