import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClientService } from './httpclient.service';
import * as _ from 'lodash';

@Injectable()
export class ExchangeService {
  constructor(private _httpClient: HttpClientService) {}

  private exchanges = new BehaviorSubject<[]>([]);
  exchangesObservable = this.exchanges.asObservable();

  public updateExchanges(_exchanges) {
    this.exchanges.next(_exchanges);
  }

  public getExchangesByVenue(_venueId: String): Observable<any> {
    return this._httpClient.get('exchange/exchangesByVenue/' + _venueId)
  }

  public getAllExchanges(): Observable<any> {
    return this._httpClient.get('exchange/all')
  }
}
