import { AuthenticationService } from "../../../_services/authentication.service";
import { LoaderService } from "./../../../_services/loader.service";
import { LogService } from "./../../../_services/log.service";
import { AuthGuardService } from "./../../../_services/auth-guard.service";
import { ShoppingCart } from "./../interfaces/ShoppingCart";
import { CheckoutService } from "./../../../_services/checkout.service";
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PaymentFormValidator } from "../validators/PaymentForm.validator";
import { Mask } from "../masks/Mask.maks";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit, OnChanges {
  @Input() valueInPoints: number = 0;
  @Input() valueInCash: number = 0;
  @Input() customerPoints: number = 0;
  @Input() shoppingCart: ShoppingCart;
  @Input() btJumpF;
  @Input() btJumpS;

  @Output() finishButtonEmmiter = new EventEmitter<boolean>();
  @Output() finishCheckoutEmmiter = new EventEmitter<void>();

  public paymentWithPoints: boolean = true;
  public paymentWithCash: boolean = false;
  public divisionOfPaymentMethod: number = 0.0;
  public paymentForm: FormGroup;
  public cvvMask: Array<string | RegExp> = Mask.cvvMask;
  public cardNumberMask: Array<string | RegExp> = Mask.cardNumber;
  public cardExpiringDateMask: Array<string | RegExp> = Mask.cardExpiringDateMask;
  public installments: number = 1;
  public btnCheckout: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private checkoutService: CheckoutService,
    private router: Router,
    private route: ActivatedRoute,
    private authGuardService: AuthGuardService,
    ) {}

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group(PaymentFormValidator.validator());
    
    if(this.btJumpF){
      this.btnCheckout = 0 //Reserva
    }else if (this.btJumpS){
      this.btnCheckout = 1 //Resgate
    }else{
      this.btnCheckout = 2 // Reserva + Resgate
    }

  }

  ngOnChanges(): void {
    this.paymentForm = this.formBuilder.group(PaymentFormValidator.validator());
    
    if(this.btJumpF){
      this.btnCheckout = 0 //Reserva
    }else if (this.btJumpS){
      this.btnCheckout = 1 //Resgate
    }else{
      this.btnCheckout = 2 // Reserva + Resgate
    }
  }

  /**
   * Returns the value in points divided by the payment method
   *
   * @return number
   */
  get valueInPointsDivided(): number {
    return Math.floor(this.valueInPoints * this.divisionOfPaymentMethod);
  }

  /**
   * Returns the value in cash divided by the payment method
   *
   * @return number
   */
  get valueInCashDivided(): number {
    return parseFloat((this.valueInCash * (1 - this.divisionOfPaymentMethod)).toFixed(2));
  }

  /**
   * Returns a truthy boolean if the finish button is enabled or a falsy.
   * If the payment method choosen is with points, the boolean is always truthy.
   * But if the mehod choosen is cash, the boolean is truthy if the payment form is valid.
   *
   * @return boolean
   */
  get isFinishButtonEnabled(): boolean {
    this.paymentForm.patchValue({ PointsCash: this.valueInPoints });

    let next: boolean = true;
    if (this.paymentWithCash) {
      this.enableCashFields();
      next = this.valueInCash === this.shoppingCart.items[0].benefit.price.cash.current;
    } else if (this.paymentWithPoints) {
      this.disableCashFields();
      next = this.valueInPoints < this.customerPoints;
    }

    const isFormValid = this.paymentForm.valid;

    // Emmiter an event to update the flag in the parent component
    this.finishButtonEmmiter.emit(isFormValid && next);

    return isFormValid && next;
  }

  private enableCashFields() {
    this.paymentForm.controls.nameOfCardHolder.enable();
    this.paymentForm.controls.cardNumber.enable();
    this.paymentForm.controls.cardExpiringDate.enable();
    this.paymentForm.controls.securityCode.enable();
    this.paymentForm.controls.parcels.enable();
  }

  private disableCashFields() {
    this.paymentForm.controls.nameOfCardHolder.disable();
    this.paymentForm.controls.cardNumber.disable();
    this.paymentForm.controls.cardExpiringDate.disable();
    this.paymentForm.controls.securityCode.disable();
    this.paymentForm.controls.parcels.disable();
  }

  /**
   * Toggles the paymentWithCash value and sets the paymentWithPoints with the inverse value
   *
   * @param flag Boolean to toggle the paymentWithCash flag
   *
   * @return void
   */
  public togglePaymentWithCash(flag: boolean): void {
    this.paymentWithCash = flag;
    this.paymentWithPoints = !flag;
    this.paymentForm.patchValue({ PointsCash: this.valueInCash });
  }

  /**
   * Toggles the paymentWithPoints value and sets the paymentWithCash with the inverse value
   *
   * @param flag Boolean to toggle the paymentWithPoints flag
   *
   * @return void
   */
  public togglePaymentWithPoints(flag: boolean): void {
    this.paymentWithPoints = flag;
    this.paymentWithCash = !flag;
    this.paymentForm.patchValue({ PointsCash: this.valueInPoints });
  }

  /**
   * Sets a value to the division of the payment method. The value must be equal or greater than 0.01 and minor or equal to 0.99
   *
   * @param value Number to update the divisionOfPaymentMethod property
   *
   * @return void
   */
  public setDivisionOfPaymentMethod(value: number): void {
    this.divisionOfPaymentMethod = value;
  }

  /**
   * Sets the number of installments changed by <select>
   *
   * @param $event Event of <select>
   *
   * @return void
   */
  public updateInstallments(event): void {
    const installments: number = parseInt(event.target.value);

    if (installments) {
      this.installments = installments;
      this.paymentForm.patchValue({ parcels: installments });
    }
  }

  /**
   * Emmiter an event for parent component after click in the finish button if it's enabled.
   *
   * @return void
   */
  public finishCheckout(): void {
    if (this.isFinishButtonEnabled) {
      if (this.paymentWithPoints) this.paymentForm.patchValue({ PointsCash: this.valueInPoints });

      this.shoppingCart.PaymentMoneyRequest = this.paymentForm.getRawValue();

      this.finishCheckoutEmmiter.emit();
    }

    localStorage.removeItem("state.data");
  }

  public finishReservation(): void {
    this.loaderService.start();

    delete this.shoppingCart.prize;

    this.checkoutService.finishReservation(this.shoppingCart).subscribe(
      (purchase) => {
        if (purchase.errors && purchase.errors.length > 0) {
          let errors = null;
          purchase.errors.forEach((err) => {
            errors += err.message + "\n";
          });
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        } else if (_.isEmpty(purchase)) {
          this.authGuardService.alert(
            "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
            "ok"
          );
        } else {
          const hasReservation = !_.isNull(this.shoppingCart.reservation.date);
          this.router.navigate(["../../finished", false, hasReservation], { relativeTo: this.route });
        }

        this.loaderService.stop();
      },
      (err) => {
        if (err && err.body && err.body.statusMessage === "Unauthorized") {
          this.authService.alertLogin();
          this.authService.logout();
        }
        this.authGuardService.alert(
          "Erro no servidor ao tentar finalizar. Tente novamente mais tarde, por favor. Obrigado.",
          "ok"
        );
        this.loaderService.stop();
      }
    );
  }
}
