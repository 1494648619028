import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-checkout-finished',
  templateUrl: './checkout-finished.component.html',
  styleUrls: ['./checkout-finished.component.scss'],
})
export class CheckoutFinishedComponent implements OnInit {
  public hasReservation: boolean = false;
  public hasRedemption: boolean = false;
  public messageClosed: boolean = false;
  public showDeliveryInfo = false;
  public orderId: string = null;
  public venueId: string = null;
  public deliveryLink = null;
  public params: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.params = this.activatedRoute.params.subscribe(async (params: Params) => {
      this.hasReservation = params.hasReservation == 'true';
      this.hasRedemption = params.hasRedemption == 'true';
      this.orderId = params.orderId;
      this.venueId = params.venueId;
    });

    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('foo') 
    }
  }

  public backToHome(): void {
    this.router.navigate(['gastronomia']);
  }

  public openDelivery() {
    this.deliveryLink = environment.config.reservationWidgetUrl
      .concat(this.venueId.toString())
      .concat(environment.config.reservationWidgetQueryString);

    this.deliveryLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.deliveryLink);
  }
}
