import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-modal-rating',
  templateUrl: './modal-rating.component.html',
  styleUrls: ['./modal-rating.component.scss']
})
export class ModalRatingComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalRatingComponent>,
  ){ }

  ngOnInit() {
  }

  public percentage: any[] = [
    { percent: '80%', text: 'gostaram do serviço do restaurante' },
    { percent: '95%', text: 'gostaram da comida do restaurante' }
  ]

  public closeModal(): void {
    this.dialogRef.close();
  }

}
