import { Validators } from '@angular/forms';

export class CustomerFormValidator {
  static validator() {
    return {
      id: [Number, Validators.required],
      name: ['', Validators.required],
      username: ['', Validators.required],
      lastName: ['', !Validators.required],
      rg: ['', !Validators.required],
      persontype: ['', !Validators.required],
      genderType: ['', !Validators.required],
      maritalStatus: ['', !Validators.required],
      status: ['', !Validators.required],
      email: ['', [Validators.required, Validators.email]],
      cpf: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/),
        ],
      ],
      accessToken: ['', Validators.required],
      idToken: ['', Validators.required],
      birthDate: [
        '',
        [Validators.required, Validators.pattern(/^\d{2}-\d{2}-\d{4}$/)],
      ],
      phones: [Array, Validators.required],
      emails: [Array, Validators.required],
      cellphone: [
        '',
        [Validators.required, Validators.pattern(/^\(\d{2}\)\s\d{5}\-\d{4}$/)],
      ],
      // onlyCash: [Boolean, Validators.required],
      points: ['', !Validators.required],
      documentNumber: ['', !Validators.required],
      catalogParameters: [Object, !Validators.required],
      address: [Object, !Validators.required]
    };
  }
}
