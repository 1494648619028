
export class VenueAdapter {
  static fromAPIResponse(venueFromAPIResponse) {
    return {
      id: venueFromAPIResponse._id,
      name: venueFromAPIResponse.name.pt,
      address: venueFromAPIResponse.address.adress1 + ', ' +
              venueFromAPIResponse.address.number + ' - ' +
              venueFromAPIResponse.address.city,
      apps: venueFromAPIResponse.apps,
      benefits: null,
    };
  }
}
