import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss']
})
export class OnboardingModalComponent {

  constructor(@Inject(DOCUMENT) private document: Document){}

  public goOnbordingLtm() {
    this.document.location.href = environment.iupp.onBoardingUrl;
  }
}
