import { Validators } from '@angular/forms';

export class PaymentFormValidator {
  static validator() {
    return {
      nameOfCardHolder: ['', Validators.required],
      cardNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{4}\s\d{4}\s\d{4}\s\d{4}$/),
        ],
      ],
      cardExpiringDate: [
        '',
        [Validators.required, Validators.pattern(/^\d{2}\/\d{4}$/)],
      ],
      securityCode: ['', [Validators.required, Validators.pattern(/^\d{3}$/)]],
      parcels: ['', Validators.required],
      PointsCash: ['', Validators.required]
    };
  }
}
