import { ShoppingCart } from '../interfaces/ShoppingCart';
import { ShoppingCartItem } from '../interfaces/ShoppingCartItem';
import { environment } from 'environments/environment';
import { Prize } from '../interfaces/Prize';

export class ShoppingCartAdapter {
  static getItemsArrayFromMenuItems(menuItems) {
    const items = [];

    menuItems.forEach(menuItem => {
      const descript = menuItem && menuItem.descript ? menuItem.descript.pt : ''
      const item = {
        image_url: menuItem.avatarUrl
          ? `${environment.config.imageUrl}${menuItem.avatarUrl}`
          : null,
        name: menuItem.name.pt,
        description: descript,
      };

      items.push(item);
    });

    return items;
  }

  static getCustomerFromAPIResponse(customerFromAPIResponse) {
    const customerNameInArray = customerFromAPIResponse.name.split(' ');
    const lengthOfCustomerNameInArray = customerNameInArray.length;
    const name = customerNameInArray[0];
    const lastName =
      lengthOfCustomerNameInArray > 1
        ? customerNameInArray[lengthOfCustomerNameInArray - 1]
        : '';
    const phone =
      customerFromAPIResponse.phones.length > 0
        ? customerFromAPIResponse.phones.find(phone => phone.type === 'MOBILE')
        : '';
    const email =
      customerFromAPIResponse.emails.length > 0
        ? customerFromAPIResponse.emails[0].email
        : '';
    const cellphone = phone ? phone.areaCode + phone.number : '';

    return {
      name,
      id: customerFromAPIResponse.id,
      username: name,
      lastName: lastName || '',
      email,
      rg: customerFromAPIResponse.rg,
      emails: customerFromAPIResponse.emails,
      cpf: customerFromAPIResponse.documentNumber,
      phones: customerFromAPIResponse.phones,
      cellphone,
      points: 0,
      accessToken: null,
      idToken: null,
      documentNumber: customerFromAPIResponse.documentNumber,
      address: customerFromAPIResponse.address,
      birthDate: customerFromAPIResponse.birthDate,
      maritalStatus: customerFromAPIResponse.maritalStatus,
      status: customerFromAPIResponse.status,
      persontype: customerFromAPIResponse.persontype,
      genderType: customerFromAPIResponse.genderType,
      catalogParameters: customerFromAPIResponse.catalogParameters,
    };
  }

  static fromAPIResponse(venueFromAPIResponse): ShoppingCart {
    const items: Array<ShoppingCartItem> = [];
    const desktopBackground: string =
      venueFromAPIResponse.app.sitePortraitCovers;
    const mobileBackground: string = venueFromAPIResponse.app.mobileCovers;
    const alcoholFeatureId: string = '56c773f42ceaf5891343069e';
    let prize: Prize;

    venueFromAPIResponse.prizes.forEach(_prize => {
      prize = {
        id: _prize._id,
        name: _prize.name,
        price: _prize.price
      }
      if (_prize.menus.length > 0) {
        _prize.menus.forEach(menu => {
          const item: ShoppingCartItem = {
            amount: 0,
            benefit: {
              id: _prize._id,
              name: menu.name.pt,
              conditions: _prize.conditions,
              imgUrl:
              '../../../assets/images/checkout-icon-menu-personnalite-quadrado.png',
              price: {
                points: {
                  current: 0,
                  previous: 0,
                },
                cash: {
                  current: _prize.price,
                  previous: 0,
                },
              },
              menu: {
                image_url: {
                  mobile: mobileBackground,
                  desktop: desktopBackground,
                },
                items: this.getItemsArrayFromMenuItems(menu.menuItems),
              },
              wine: null,
            },
          };

          items.push(item);
        });
      } else {
        const item: ShoppingCartItem = {
          amount: 0,
          benefit: {
            id: _prize._id,
            name: _prize.name,
            conditions: _prize.conditions,
            imgUrl:
            '../../../assets/images/icon-vinho-personnalite-quadrado.png',
            price: {
              points: {
                current: 0,
                previous: 0,
              },
              cash: {
                current: _prize.price,
                previous: 0,
              },
            },
            menu: null,
            wine: {
              image_url:
                _prize.menuItem && _prize.menuItem.product
                  ? `${environment.config.imageUrl}${_prize.menuItem.product.avatarUrl}`
                  : null,
              description:
                _prize.menuItem && 
                _prize.menuItem.product && 
                _prize.menuItem.product.name
                  ? _prize.menuItem.product.name.pt
                  : null,
              country: {
                image_url:
                  _prize.menuItem &&
                  _prize.menuItem.product &&
                  _prize.menuItem.product.country &&
                  _prize.menuItem.product.country.name &&
                  _prize.menuItem.product.country.name.en
                    ? `https://tagme.com.br/assets/images/icons/paises/${_prize.menuItem.product.country.name.en.toLowerCase().replace(' ', '-')}.png`
                    : null,
                name:
                  _prize.menuItem &&
                  _prize.menuItem.product &&
                  _prize.menuItem.product.country &&
                  _prize.menuItem.product.country.name
                    ? _prize.menuItem.product.country.name.pt
                    : null,
              },
              type: {
                name:
                  _prize.menuItem &&
                  _prize.menuItem.product &&
                  _prize.menuItem.product.wineDetails &&
                  _prize.menuItem.product.wineDetails.type
                    ? _prize.menuItem.product.wineDetails.type.slug
                    : null,
                text:
                  _prize.menuItem &&
                  _prize.menuItem.product &&
                  _prize.menuItem.product.wineDetails &&
                  _prize.menuItem.product.wineDetails.type &&
                  _prize.menuItem.product.wineDetails.type.name
                    ? _prize.menuItem.product.wineDetails.type.name.pt
                    : null,
              },
              alcohol:
                _prize.menuItem &&
                _prize.menuItem.product &&
                _prize.menuItem.product.features && 
                _prize.menuItem.product.features.length > 0
                  ? _prize.menuItem.product.features.find(
                      feature => feature.feature === alcoholFeatureId,
                    ).value
                  : null,
            },
          },
        };

        items.push(item);
      }
    });

    return {
      prize,
      items,
      customer: null,
      reservation: {
        date: null,
        ambience: null,
        people: null,
        time: null,
      },
      PaymentMoneyRequest: {
        cardNumber: '',
        nameOfCardHolder: '',
        cardHolderCPF: '',
        cardExpirationMonthDate: '',
        cardExpirationYearDate: '',
        securityCode: '',
        parcels: '',
        PointsCash: '',
      },
    };
  }
}
