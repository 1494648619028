import { VenueService } from "app/_services/venue.service";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpClientService } from "./../_services/httpclient.service";

@Injectable()
export class SharedDataService implements OnDestroy {
	public CPP: number | null = null;

	constructor(
		public venueService: VenueService,
		public http: HttpClient,
		public httpService: HttpClientService
	) {
		if (!this.CPP)
			this.getCPP().subscribe(
				(res) => (this.CPP = res.cpp),
				(error) => (this.CPP = 0.021)
			);
	}

	public getCPP(): Observable<{ cpp: number }> {
		return this.httpService.get("BankProfile/getCPP");
	}

	public cityFilter = new BehaviorSubject<string>("Cidade");
	_cityFilter = this.cityFilter.asObservable();
	private defaultCity: string = null;

	public changeCityFilter(_city: string) {
		this.cityFilter.next(_city);
	}

	public setDefaultCity(_city: string): void {
		if (this.defaultCity === null) this.defaultCity = _city;
	}

	public getDefaultCity(): string {
		return this.defaultCity;
	}

	// public getFilterCities() {
	//     return ["Todas as Cidades", "Brasília", "Belo Horizonte", "Campinas", "Campos do Jordão", "Cotia", "Curitiba", "Garibaldi", "Goiânia", "Gramado", "Itajaí", "Jundiaí", "Natal", "Porto Alegre", "Recife", "Ribeirão Preto", "Rio de Janeiro", "Salvador", "São Paulo", "Santos", "Florianópolis"]
	// }

	public getFilterCities(): Observable<any> {
		return this.venueService.getListAddress();
	}

	public getPrizesPersonnalite() {
		return ["Menu Personnalité", "Vinho Personnalité", "Grand Menu", "Menu Casual"];
	}

	public hasWine(prizeNames: Array<string>): boolean {
		if (!prizeNames || prizeNames.length < 1 || prizeNames.indexOf("Vinho Personnalité") < 0) return false;
		return true;
	}

	public getCuisine(index: number, _venue: any): string {
		if (_venue && _venue.cuisines && _venue.cuisines.length > index) return _venue.cuisines[index];
		return null;
	}

	public getVenueStyle(prizeNames: Array<string>): string {
		if (!prizeNames || prizeNames.length < 1) return null;
		if (prizeNames.indexOf("Grand Menu") > -1) return "Jantar fino";
		if (prizeNames.indexOf("Menu Personnalité") > -1) return "Sofisticado ou bistrô";
		return null;
	}

	public getVenuePrize(prizeNames: Array<string>): string {
		if (!prizeNames || prizeNames.length < 1) return null;
		if (prizeNames.indexOf("Grand Menu") > -1) return "Grand Menu";
		if (prizeNames.indexOf("Menu Personnalité") > -1) return "Menu Personnalité";
		return null;
	}

	public getAddressByGeoLocation(lat: string, lng: string): Observable<any> {
		const api = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
		return this.http.get<any>(api);
	}

	ngOnDestroy(): void {}
}
