import { Injectable } from '@angular/core';
import {SafeResourceUrl, DomSanitizer} from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClientService }  from './httpclient.service';
import { AppSettingsService } from './appsettings.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

class bingMaps {  
    url: SafeResourceUrl;
    constructor(sanitizer: DomSanitizer) {//sanitizer.bypassSecurityTrustUrl
        this.url = sanitizer.bypassSecurityTrustUrl('http://dev.virtualearth.net');
    }
}

const GEOLOCATION_ERRORS = {
	'errors.location.unsupportedBrowser': 'Browser does not support location services',
	'errors.location.permissionDenied': 'You have rejected access to your location',
	'errors.location.positionUnavailable': 'Unable to determine your location',
	'errors.location.timeout': 'Service timeout has been reached'
};

@Injectable()
export class GeolocationService {

  constructor(private _httpClient: HttpClientService, public _http: HttpClient, private _appSettingsService: AppSettingsService, private _sanitizer: DomSanitizer) {}

  private currentData = new BehaviorSubject<any>(null);
  public currentData$ = this.currentData.asObservable();

  private currentlocation = new BehaviorSubject<String>(null);
  public currentlocation$ = this.currentlocation.asObservable();

  public getCurrentData () : any {
    return this.currentData.getValue();
  }
  public setCurrentData (_data : any): void {
    this.currentData.next(_data);
  }

  public getLocation () : Observable<any> {
    return this._httpClient.get('GeoLocation/')
  }

  public getGeoLocation(): Observable<any> {
    let _geoLocationCity = "São Paulo";
    return Observable.create(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            if(position && position.coords && position.coords.latitude && position.coords.longitude) {
              let _lat = position.coords.latitude;
              let _long = position.coords.longitude;
              observer.next({latitude:_lat,longitude:_long});
            } else {
              observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
            } 
          }, (error) => {
            switch (error.code) {
              case 1:
                observer.error(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
                break;
              case 2:
                observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
                break;
              case 3:
                observer.error(GEOLOCATION_ERRORS['errors.location.timeout']);
                break;
            }
        });
      } else {
        observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
      }
    });
  }
}