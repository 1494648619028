import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AppSettingsService {
  public getSettings() {
    if (!environment.config) return null; 
    return {
      apiEndpoint: {
        url : environment.config.iBeneficiosApiUrl,
        token : environment.config.iBeneficiosApiToken
      },
      imageUrl: environment.config.imageUrl,
      fileUrl : environment.config.fileUrl
    }
  }
}
