import { DialogComponent } from "./../client/dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as _ from "lodash";
import * as jwt from "jsonwebtoken";

@Injectable()
export class AuthGuardService {
	constructor(private _router: Router, private _snackBar: MatSnackBar, private dialog: MatDialog) {
		this.setSafe();
	}

	private prefix = "";
	private secure = "";

	private isHttps() {
		return location.protocol === "https:";
	}

	private setSafe() {
		if (this.isHttps()) {
			this.prefix = "__Host-";
			this.secure = "Secure";
		}
	}

	public decode = (token) => {
		return jwt.decode(token, { algorithms: ["RS256"] }, (err, decoded) => {
			if (err) console.log("jwtService.verify() - erro: " + err);
			return decoded;
		});
	};

	public encode = (tokenName, context) => {
		return jwt.sign({ context }, tokenName, { expiresIn: 120 });
	};

	public goHome() {
		this._router.navigate(["/gastronomia"]);
		return false;
	}

	private user = new BehaviorSubject<{}>("");
	public user$ = this.user.asObservable();

	public getUser(): {} {
		return this.user.getValue();
	}

	setUser(_user): void {
		this.user.next(_user);
	}

	/**
	 * verify protocol, encode object with jwt and setItem into Local Storage
	 */
	public setItemLS(name, item) {
		const json = JSON.stringify(this.isHttps() ? this.encode(name, item) : item);
		localStorage.setItem(name, json);
	}

	/**
	 * get localStorage, verify protocol, decode object with jwt if https and return value
	 */
	public getItemLS(name) {
		const lsName = localStorage.getItem(name);
		if (lsName) {
			if (this.isHttps() || name === "teste") {
				const treatedName = lsName.replace(/"/g, "");
				const decodedName = this.decode(treatedName);
				if (!decodedName) {
					return null;
				}
				return decodedName.context;
			} else {
				return JSON.parse(lsName);
			}
		}
		return null;
	}

	public openSnackBar(message: string) {
		this._snackBar.open(message, "", {
			duration: 10000,
		});
	}

	public alert(message, textButton, funcToExec = null) {
		const dialogRef = this.dialog.open(DialogComponent, {
			hasBackdrop: true,
			disableClose: false,
			data: {
				message: message,
				buttonText: {
					ok: textButton,
				},
			},
		});

		dialogRef.afterClosed().subscribe(() => {
			if (funcToExec) funcToExec["doLogin"]();
		});
	}

	public deleteCookie(flag) {
		document.cookie = this.prefix + flag + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${this.secure};path=/;`;
	}

	public setCookie(name, value, days) {
		let expires = "";
		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + 60 * 60 * 1000);
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = this.prefix + name + "=" + (value || "") + expires + "; " + this.secure + ";path=/";
	}

	public getCookie(name) {
		let nameEQ = this.prefix + name + "=";
		let ca = document.cookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}
}
