import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
})
export class AmountComponent {
  @Input('data') amount: number = 0;

  @Output() amountUpdated = new EventEmitter<number>();

  /**
   * TODO:
   * - [] remove attribuition to this.amount and put it in another method
   * - [] remove event emission and put it in another method
   * - [] documment this method
   */
  updateAmount(operation: string): void {
    let amount = this.amount;

    if (operation === '+') {
      amount += 1;
    }

    if (operation === '-' && amount > 0) {
      amount -= 1;
    }

    this.amount = amount;

    this.amountUpdated.emit(this.amount);
  }
}
