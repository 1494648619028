import { Validators } from '@angular/forms';

export class AnteFraudFormValidator {
  static validator() {
    return {
      digitOne: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
      digitTwo: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
      digitThree: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
      digitFour: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
      digitFive: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
      digitSix: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
    };
  }
}
