import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class PrizeService {
  prizeIdxSub = new BehaviorSubject<{ prize: any; idx: number }>(undefined);
  prizeWithIdx$ = this.prizeIdxSub.asObservable();
  constructor() {}

  setPrize(prize: { prize: any; idx: number }) {
    this.prizeIdxSub.next(prize);
  }
}
