import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';


@Component({
  selector: 'app-modal-user-rating-mobile',
  templateUrl: './modal-user-rating-mobile.component.html',
  styleUrls: ['./modal-user-rating-mobile.component.scss']
})
export class ModalUserRatingMobileComponent implements OnInit {

  public starService: number = 0;
  public starFood: number = 0;
  public alertService: boolean = false;
  public alertFood: boolean = false;
  public modalSend: boolean = false;
  public btBlock: boolean = false;
  public clientName: string = '';

  constructor(private bottomSheetRef: MatBottomSheetRef<ModalUserRatingMobileComponent>) { }

  ngOnInit() {
    const client = JSON.parse(localStorage.getItem('client'))
    this.clientName = client.name;
  }

  public closeModal(){
    this.bottomSheetRef.dismiss();
  }

  public serviceStars: any[] = [
    { icon:'fa fa-star' ,number:'1' ,active: false},
    { icon:'fa fa-star' ,number:'2' ,active: false},
    { icon:'fa fa-star' ,number:'3' ,active: false},
    { icon:'fa fa-star' ,number:'4' ,active: false},
    { icon:'fa fa-star' ,number:'5' ,active: false}
  ]

  public foodStars: any[] = [
    { icon:'fa fa-star' ,number:'1' ,active: false},
    { icon:'fa fa-star' ,number:'2' ,active: false},
    { icon:'fa fa-star' ,number:'3' ,active: false},
    { icon:'fa fa-star' ,number:'4' ,active: false},
    { icon:'fa fa-star' ,number:'5' ,active: false}
  ]

  public numberServiceStars(stars){
    for (var i = 0; i < this.serviceStars.length; i++){
      if(this.serviceStars[i].number <= stars){
        this.serviceStars[i].active = true;
      }else{
        this.serviceStars[i].active = false;
      }
    }
    this.starService = stars;
    this.buttonBlock();
  }

  public numberFoodStar(stars){
    for (var i = 0; i < this.foodStars.length; i++){
      if(this.foodStars[i].number <= stars){
        this.foodStars[i].active = true;
      }else{
        this.foodStars[i].active = false;
      }
    }
    this.starFood = stars;
    this.buttonBlock();
  }

  public buttonBlock(){
    if(this.starFood > 0 && this.starService > 0){
      this.btBlock = true;
    }else{
      this.btBlock = false;
    }
  }

  public sendComentary(){
    let x  = (document.getElementById("cometary") as HTMLInputElement).value   
    
    if(this.starService == 0 || this.starFood == 0){
      
      if(this.starService == 0){     
        this.alertService = true
      }else{
        this.alertService = false
      }
      
      if(this.starFood == 0){
        this.alertFood = true
      }else{
        this.alertFood = false
      }
    }else{
      this.modalSend = true;
    }
    

    console.log('serviço => ', this.starService) 
    console.log('comida => ', this.starFood) 
    console.log(x)
  }

}
