import moment from 'moment-timezone';
import _ from 'lodash';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable }           from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  styleUrls:['./iupp.component.scss'],
  selector: 'tg-reservation-confirm-modal-form',
  template: `
    <div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
        <div class="modal-dialog modal-widget-reservation" role="document">
            <div class="col-md-6 col-sm-6 col-xs-6" style="bottom: 26px;right: 16px;">
                <!--<a (click)="goBack()" style="color:#ffffff;cursor:pointer" *ngIf="isBack"><i class="tagmeicon itau itau-seta itau-1x itau-flip-horizontal"></i>Voltar</a>-->
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 close-widget-reservation" style="bottom: 29px;left: 20px;">
                <a type="button" aria-label="Close" (click)="hideChildModal()" style="color:#ffffff;cursor:pointer"><span aria-hidden="true"><i class="tagmeicon itau itau-fechar itau-2x"></i></span></a>
            </div>
            <div class="modal-content">            
                <div class="modal-header">                    
                    <div class="row">
                         <div class="col-md-12 col-sm-12 col-xs-12">
                            <h4 style="text-align: center;">{{headerModal}}</h4>
                         </div>
                    </div>
                </div>
                <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12" style="left: 5px;width: 278px;">
                                <div class="form-group" style="text-align:center;margin-bottom: 31px;">
                                    <i class="tagmeicon itau itau-like itau-4x" style="color:#8a6632"></i>
                                    <div style="text-align: center;font-size: 15px;margin-top:38px">{{headerModalConfirmationReserve}}</div>
                                    <div style="text-align: center;font-size: 11px;margin-top:23px" >{{headerModalConfirmationReserveBottom}}</div>
                                </div>
                            </div>
                        </div>
                </div>
                    <!--<pre>{{formReservation.value | json}}</pre>-->
            </div>
        </div>
    </div>
  `
})
export class PersonnaliteReservationConfirmModalComponent implements OnInit {
  
  @ViewChild('modal') public modal: ModalDirective;

  constructor(private _activatedRoute: ActivatedRoute) {}

  public headerModal: string = '';
  public headerModalConfirmationReserve: string = 'Reserva confirmada com sucesso!';
  public headerModalConfirmationReserveBottom: string = '';

  ngOnInit() {
    
  }  
 
  public showChildModal():void {
    this.modal.show();    
  }
 
  public hideChildModal():void {
    this.modal.hide();
  }

}
