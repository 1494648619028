import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'points' })
export class PriceInPointsPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: string): string {
    if (value) {
      return this.currencyPipe.transform(value, undefined, '', '0.0-0').trim();
    }

    return null;
  }
}
