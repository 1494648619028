import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  message: string = '';
  cancelButtonText = "ok"
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>) {
    dialogRef.disableClose = true;
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.ok || this.cancelButtonText;
      }
    }
    this.dialogRef.updateSize('300vw','300vw');
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
