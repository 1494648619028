import { CheckoutService } from "./../_services/checkout.service";
import { AuthGuardService } from "app/_services/auth-guard.service";
import { LoaderService } from "./../_services/loader.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";
import { Component, OnInit, ViewEncapsulation, OnDestroy, ComponentFactoryResolver } from "@angular/core";
import { CarouselConfig } from "ngx-bootstrap/carousel";
import { VenueService } from "../_services/venue.service";
import { GeolocationService } from "../_services/geolocation.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LogService } from "../_services/log.service";
import { SharedDataService } from "../_services/sharedData.service";
import * as _ from "lodash";
import { Location } from "@angular/common";
import * as moment from "moment-timezone";
import { AuthenticationService } from "app/_services/authentication.service";

@Component({
  styleUrls: ["./iupp-home.component.scss"],
  templateUrl: "./iupp-home.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CarouselConfig, useValue: { interval: 9000, noPause: true } }],
})
export class IuppHomeComponent implements OnInit, OnDestroy {
  public selected = {};
  public action: string = "";
  public btnSeeRestaurantsClasses = ["btn-default", "btnSeeRestaurantsBlack"];
  public filterArrowClasses = ["itau", "itau-seta", "itau-rotate-90", "personnaliteColor", "arrowFilter", "itau-2x"];
  public filtersSelected: boolean = false;
  public isChecked: boolean = false;
  public filterAppliedCss = "noFilter";
  public filterQuantity = { quantity: 0, type: [] };
  public currentCityFilter = "Cidade";
  public listTypeCssSelected = "lista-restaurantes";
  public citySelect = "Rio de Janeiro";
  public filterCities = [{}];
  public days = new Array(31);
  public showGhosts: boolean = true;
  public exchanges: Array<any> = [];
  public queryParams: Subscription;
  public cityFilter: Subscription;
  public getAllExchangesService: Subscription;
  public geoLocation: Subscription;
  public geoCurrentData: Subscription;
  public getVenuesService: Subscription;
  public getClientService: Subscription;
  public registerFilterLog: Subscription;
  public env = environment;
  public openCloseModal: boolean = false;
  public daySelect = "";
  public monthSelect = "";
  public district: string = "";
  public buttonTop: any[] = [
    { name: "Experiência no restaurante", function: "", icon: "fa fa-no-restaurante", active: true },
    { name: "Meus pedidos", function: "order-details", icon: "fa fa-mala-icone", active: false },
    { name: "Meus favoritos", function: "favorites", icon: "fa fa-desejo-icone", active: false },
  ];
  private sophisticatedOrBistro = "Sofisticados ou bistrôs";
  private readonly CASUAL_ITAU = "Casual Itaú";
  private readonly MENU_CASUAL = "Menu Casual";
  private readonly MENU_FAST = "Menu Fast";
  private readonly VINHO_PERSONNALITE = "Vinho Personnalité";
  private readonly MENU_PERSONNALITE = "Menu Personnalité";
  private readonly GRAND_MENU = "Grand Menu";

  public objectLoop = Object.keys;
  public topFilter: any = {
    delivery: { label: "Delivery", slug: "delivery", isActive: false },
    restaurant_experience: { label: "Experiência no restaurante", slug: "restaurant_experience", isActive: true },
    my_orders: { label: "Meus pedidos", slug: "my_orders", isActive: false },
  };
  public modal = {
    address: {
      display: false,
      close_animation: false,
    },
  };
  public isSearchFilterFocused: boolean = false;

  public searchValue: string = "";

  constructor(
    private _venueService: VenueService,
    private _location: Location,
    private _geoService: GeolocationService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private _logService: LogService,
    private loaderService: LoaderService,
    private authGuardService: AuthGuardService,
    private authService: AuthenticationService,
    private checkoutService: CheckoutService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.checkLastFilter();
    document.title = "Resultado de busca - Menu Personnalité";

    moment.locale("pt-br");
    // this.showGhosts = true;
    this.loaderService.start();

    this.queryParams = this._activatedRoute.queryParams.subscribe(async (params: Params) => {
      // const generatedCodeParam = params["code"] === "null" ? null : params["code"];

      // if (
      //   !generatedCodeParam &&
      //   (!this.authGuardService.getCookie("token") || this.authGuardService.getCookie("token") == undefined)
      // ) {
      //   this.authService.doLogin();
      // } else {
      //   const treatedCodeParam = generatedCodeParam || this.authGuardService.getCookie("token");
      //   this.authGuardService.setCookie("token", treatedCodeParam, 1);
      //   if (!this.authGuardService.getItemLS("client")  && treatedCodeParam) {
      //     await this.authService.client(treatedCodeParam);
      //   } else if(this.authGuardService.getItemLS("client")){
      //     await this.authService.logout();
      //   }
        
      //   // if (venueId) this.goVenuePage(venueId);
      // }

      await this.sharedDataService.getFilterCities().subscribe((filterCities) => {
        this.filterCities = filterCities;
      });

      this.action = params["action"];

      // this.getAllExchangesService = this._exchangeService.getAllExchanges().subscribe(exchanges => {
      //   this.exchanges = exchanges;
      // });

      //if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.getVenuesFromAPI(
        this.bindAllData.reservationDay,
        this.bindAllData.city,
        this.bindAllData.neighborhood,
        this.bindAllData._location
      );
      this.showGhosts = false;

      this.fillCuisines();
      this.fillCalendar();
    });

    this.cityFilter = this.sharedDataService._cityFilter.subscribe((newCityFilter) => {
      this.currentCityFilter = newCityFilter;
      this.loaderService.stop();
    });

    this.geoCurrentData = this._geoService.currentData$.subscribe((_data) => {
      if (_data) this.bindAllData = _data;
    });

    // this.infiniteScroll();
  }
  /*
  public infiniteScroll() {
    // The Scroll Event.
    window.addEventListener("scroll", () => {
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
      if (scrollTop + clientHeight > scrollHeight - 2000 && this.hasNextVenues) {
        if (!this.showGhosts) this.fetchSeeMore();
      }
    });
  }
  */

  public handleSearchSubmit(event) {
    event.preventDefault();
  }

  public handleSearchFieldFocused(focus: boolean) {
    this.isSearchFilterFocused = focus;
  }

  public setTopFilter(filter: any) {
    Object.keys(this.topFilter).forEach((key) => {
      this.topFilter[key].isActive = key === filter.slug;
    });
  }

  public getActivatedTopFilter() {
    return Object.keys(this.topFilter).find((key) => this.topFilter[key].isActive);
  }

  public showFilteredResult(query) {
    if (query && query.length > 0) {
      this.searchRestaurant(query).subscribe((response) => {
        // console.log(response);
        this.bindAllData.filteredVenues = response;
        // console.log(this.bindAllData);
        // this.applyFilters();
      });
    }
  }

  public setModal(modal) {
    this.modal = { ...modal };
  }

  public setModalDisplay(key: string, display: boolean) {
    this.modal[key] = { ...this.modal[key], display };
  }

  public searchRestaurant(query) {
    return this._venueService.getFilteredSearchQuery(query);
  }

  private restaurantStylesModel = [
    {
      name: "Para desfrutar",
      allowedSkill: null,
      filteredVenues: [],
      benefits: [
        this.GRAND_MENU,
        this.MENU_PERSONNALITE,
        this.VINHO_PERSONNALITE,
        this.MENU_CASUAL,
        this.CASUAL_ITAU
      ]
    },
    {
      name: "Jantar fino",
      allowedSkill: "jantar-fino",
      filteredVenues: [],
      benefits: [this.GRAND_MENU],
    },
    {
      name: this.sophisticatedOrBistro,
      allowedSkill: "sofisticados-e-bistros",
      filteredVenues: [],
      benefits: [this.MENU_PERSONNALITE, this.VINHO_PERSONNALITE],
    },
    {
      name: this.MENU_CASUAL,
      allowedSkill: "menu-casual",
      filteredVenues: [],
      benefits: [this.CASUAL_ITAU, this.MENU_CASUAL],
    }
  ];

  bindAllData = {
    menuPersonnaliteCities: [],
    reservationTimes: [],
    reservationTime: null,
    partySizes: [
      { value: 1, name: "1 pessoa" },
      { value: 2, name: "2 pessoas" },
      { value: 3, name: "3 pessoas" },
      { value: 4, name: "4 pessoas" },
      { value: 5, name: "+4 pessoas" },
    ],
    partySize: { value: null, name: "-" },
    time: null,
    city: null,
    neighborhood: null,
    _location: null,
    day: null,
    month: null,
    reservationDay: null,
    reservationDays: [],
    nameRestaurant: [],
    idsNameRestaurant: [],
    cuisine: null,
    allItauVenues: null,
    filteredVenues: null,
    venuesCuisine: null,
    venuesCuisines: [],
    priceRange: null,
    restaurantStyle: null,
    venueCuisine: null,
    labelFilterSelected: "Para desfrutar",
    restaurantStyles: this.restaurantStylesModel,
    benefitType: null,
    benefitTypes: [
      "Todos",
      this.GRAND_MENU,
      this.MENU_PERSONNALITE,
      this.VINHO_PERSONNALITE,
      this.MENU_CASUAL
    ],
    filteredRestaurantStyleIndex: 0,
    filteredVenueCuisineIndex: 0,
    filteredBenefitTypeIndex: 0,
  };
  public allowedSkill: string = null;
  public page: number = 1;
  public limit: number = 10;
  public hasNextVenues: boolean = true;

  bindAllDataDefault = this.bindAllData;

  public labelFilterSelected = this.bindAllData.restaurantStyles[1].name;

  disabled = false;

  public goVenuePage(venueId): void {
    this.router.navigate(["gastronomia/venue-details", venueId]);
  }

  private checkLastFilter() {
    const lastFilter = localStorage.getItem("lastFilter");

    if (lastFilter) this.bindAllData = JSON.parse(lastFilter);

    this.labelFilterSelected = this.bindAllData.labelFilterSelected || "";
  }

  ngOnDestroy(): void {
    if (this.queryParams) this.queryParams.unsubscribe();
    if (this.cityFilter) this.cityFilter.unsubscribe();
    if (this.getAllExchangesService) this.getAllExchangesService.unsubscribe();
    if (this.geoLocation) this.geoLocation.unsubscribe();
    if (this.getVenuesService) this.getVenuesService.unsubscribe();
    this.geoCurrentData.unsubscribe();
  }

  public filtroGastronomico() {
    this.disabled = !this.disabled;
  }

  //=== botões ===

  // public btnRigth(id: any): void {
  //   this.animateScroll(
  //     document.getElementById(this.getElementIdForScroll(id)),
  //     'right',
  //     900, //690
  //   );
  // }

  public btnRigth(id: any): void {
    let query = window.matchMedia("(min-width:768px)");
    let scrollSize = document.getElementById(id).scrollWidth;
    let scrollPosition = document.getElementById(id).scrollLeft;
    let containerSize = document.getElementById(id).offsetWidth;

    if (scrollPosition + containerSize > scrollSize) {
      this.animateScroll(document.getElementById(this.getElementIdForScroll(id)), "right", scrollSize);
    } else if (query.matches) {
      this.animateScroll(document.getElementById(this.getElementIdForScroll(id)), "right", containerSize);
    }
  }

  public btnLeft(id: any): void {
    let query = window.matchMedia("(min-width:768px)");
    let scrollSize = document.getElementById(id).scrollWidth;
    let scrollPosition = document.getElementById(id).scrollLeft;
    let containerSize = document.getElementById(id).offsetWidth;

    if (scrollPosition + containerSize > scrollSize) {
      this.animateScroll(document.getElementById(this.getElementIdForScroll(id)), "left", scrollSize);
    } else if (query.matches) {
      this.animateScroll(document.getElementById(this.getElementIdForScroll(id)), "left", containerSize);
    }
  }

  private animateScroll(elementForScroll: HTMLElement, direction: string, distance: number): void {
    let containerSize = document.getElementById("0").offsetWidth;
    let fractionalDistance: number = containerSize / 12;

    if (containerSize < 700) {
      fractionalDistance = containerSize / 15;
    } else {
      distance = containerSize;
    }
    let distanceScrolled: number = 0;

    while (distanceScrolled < distance) {
      setTimeout(() => {
        if (direction === "right") {
          elementForScroll.scrollLeft += fractionalDistance;
        } else if (direction === "left") {
          elementForScroll.scrollLeft -= fractionalDistance;
        }
      }, 1);

      if (distanceScrolled + fractionalDistance > distance) {
        distanceScrolled += distance - distanceScrolled;
      } else {
        distanceScrolled += fractionalDistance;
      }
    }
  }

  private getElementIdForScroll(elementId: any): string {
    return elementId >= 1 || elementId <= 3
      ? String(elementId)
      : elementId === "restaurantesProximos"
      ? elementId
      : null;
  }

  goBack(): Boolean {
    this._location.back(); // <-- go back to previous location on cancel
    return false;
  }

  public getMonths() {
    return moment.months();
  }

  public getCurrentCityFilter() {
    if (this.currentCityFilter == "Todas as Cidades") return "Todas";
    if (this.currentCityFilter == "Campos do Jordão") return "Campos";
    if (this.currentCityFilter == "Santa Catarina") return "SC";
    if (this.currentCityFilter == "Belo Horizonte") return "BH";
    return this.currentCityFilter;
  }

  public callCitySelected(selectedCity) {
    this.page = 1;
    this.bindAllData.allItauVenues = [];
    this.bindAllData.filteredVenues = [];
    this.citySelect = selectedCity;
    this.citySelected(selectedCity);
    this.fillCalendar();
  }

  public callNeighborhoodSelected(selectedCity) {
    this.neighborhoodSelected(selectedCity);
    this.fillCalendar();
  }

  public fillCalendar() {
    this.bindAllData.reservationDays = [];
    let now = moment();
    this.bindAllData.reservationDays.push({
      weekDay: "Dia da reserva",
      monthDay: "Qualquer dia",
      value: null,
    });
    this.bindAllData.reservationDays.push({
      weekDay: now.format("dddd"),
      monthDay: now.format("DD [de] MMMM"),
      value: now.format("YYYY-MM-DD"),
    });

    for (let i = 1; i < 30; i++) {
      var auxDate = moment(now).add(i, "days");
      this.bindAllData.reservationDays.push({
        weekDay: auxDate.format("dddd"),
        monthDay: auxDate.format("DD [de] MMMM"),
        value: auxDate.format("YYYY-MM-DD"),
      });
    }
  }

  public onVenuesChange(_venues, _reservationDay) {
    this.bindAllData.allItauVenues = [...(this.bindAllData.allItauVenues || []), ..._venues.venues];
    this.bindAllData.filteredVenues = this.bindAllData.allItauVenues;
    _.find(this.bindAllData.restaurantStyles, { name: "Para desfrutar" }).filteredVenues =
      this.bindAllData.allItauVenues;

    this.fillPartySizesAndReservationTimes();

    this.bindAllData.city = _venues.city;
    this.sharedDataService.setDefaultCity(_venues.city);
    this.sharedDataService.changeCityFilter(this.bindAllData.city);
    // this.applyFilters();
    // this.showGhosts = false;
  }

  public fillPartySize() {
    this.bindAllData.partySizes = [
      { value: null, name: "Qualquer" },
      { value: 1, name: "1 pessoa" },
      { value: 2, name: "2 pessoas" },
      { value: 3, name: "3 pessoas" },
      { value: 4, name: "4 pessoas" },
      { value: 5, name: "+4 pessoas" },
    ];
  }

  public fillReservationTime() {
    this.bindAllData.reservationTimes = [];
    for (let i = 9; i <= 21; i++) {
      let hour = i < 10 ? `0${i}` : i;
      this.bindAllData.reservationTimes = [...this.bindAllData.reservationTimes, `${hour}:00`];
      this.bindAllData.reservationTimes = [...this.bindAllData.reservationTimes, `${hour}:30`];
    }
  }

  public fillPartySizesAndReservationTimes() {
    this.fillPartySize();
    this.fillReservationTime();
    return;
    var auxPartySizes = [];
    var auxReservationTimes = [];

    _.forEach(this.bindAllData.allItauVenues, (venueIterator) => {
      if (venueIterator.availability && venueIterator.availability.available && venueIterator.availability.sections) {
        _.forEach(venueIterator.availability.sections, (sectionIterator) => {
          if (!sectionIterator.available) return false;
          _.forEach(sectionIterator.schedules, (scheduleIterator) => {
            if (!scheduleIterator.available) return false;

            // Add party size if it has not been added yet
            var auxPartySize = parseInt(scheduleIterator.partySize);
            if (!_.find(auxPartySizes, { value: auxPartySize })) {
              auxPartySizes.push({
                value: auxPartySize,
                name: auxPartySize == 1 ? "1 pessoa" : auxPartySize.toString() + " pessoas",
              });
            }

            // Add reservation time if it has not been added yet
            _.forEach(scheduleIterator.reservationTimes, (reservationTimeIterator) => {
              if (reservationTimeIterator.available) {
                if (auxReservationTimes.indexOf(reservationTimeIterator.reservationTime.toString()) < 0) {
                  auxReservationTimes.push(reservationTimeIterator.reservationTime.toString());
                }
              }
            });
          });
        });
      }
    });

    this.bindAllData.reservationTimes = auxReservationTimes;
    this.bindAllData.reservationTimes = this.bindAllData.reservationTimes.sort();
    // if(auxReservationTimes.length > 0) this.bindAllData.reservationTimes.unshift("Todos os horários");
    if (this.bindAllData.reservationTime) {
      if (this.bindAllData.reservationTimes.indexOf(this.bindAllData.reservationTime) < 0)
        this.bindAllData.reservationTime = null;
    }

    this.bindAllData.partySizes = auxPartySizes;
    this.bindAllData.partySizes = this.bindAllData.partySizes.sort();
    if (auxPartySizes.length > 0) this.bindAllData.partySizes.unshift({ value: null, name: "Qualquer" });
    if (this.bindAllData.partySize) {
      var auxPartySizeValue = this.bindAllData.partySize.value;
      if (!_.find(this.bindAllData.partySizes, { value: auxPartySizeValue }))
        this.bindAllData.partySize = { value: null, name: "Qualquer" };
    }
  }

  public async fillCuisines() {
    await this.getCuisineFromAPI();

    this.bindAllData.venuesCuisines = this.bindAllData.venuesCuisines.map((cuisine) => cuisine._id);
    this.bindAllData.venuesCuisines.unshift("Todas as cozinhas");

    if (this.bindAllData.cuisine) {
      if (this.bindAllData.venuesCuisines.indexOf(this.bindAllData.cuisine) < 0) {
        this.bindAllData.cuisine = null;
      } else {
        this.cuisineSelected(this.bindAllData.cuisine, false);
      }
    }
  }

  public hasFilterSelected() {
    let filterSelected = false;
    if (this.bindAllData.reservationDay && this.bindAllData.reservationDay.value) filterSelected = true;
    else if (this.bindAllData.partySize && this.bindAllData.partySize.value) filterSelected = true;
    else if (this.bindAllData.reservationTime) filterSelected = true;
    else if (this.bindAllData.cuisine) filterSelected = true;
    else if (this.bindAllData.restaurantStyle) filterSelected = true;
    else if (this.bindAllData.benefitType) filterSelected = true;
    this.filtersSelected = filterSelected;
  }

  public daySelected(_day) {
    this.bindAllData.day = _day.value;
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));
    this.fillDayAndMonth(_day.value, null);
    this.daySelect = _day.value;
  }

  public monthSelected(_month) {
    this.bindAllData.month = _month.value;
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));
    this.fillDayAndMonth(null, _month.value);
    var mes = [
      "Nenhum",
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    this.monthSelect = mes[_month.value];
  }

  public fillDayAndMonth(_day, _month) {
    this.bindAllData.day = !_day ? this.bindAllData.day : _day;
    this.bindAllData.month = !_month ? this.bindAllData.month : _month;

    const concatDate = new Date().getFullYear() + "-" + this.bindAllData.month + "-" + this.bindAllData.day;

    this.bindAllData.reservationDay = moment(concatDate).format("YYYY-MM-DD");

    let reservationDate = this.bindAllData.reservationDay;

    if (moment(reservationDate).isValid()) {
      this.bindAllData.reservationDay = reservationDate;

      this.bindAllData.allItauVenues = [];
      this.bindAllData.filteredVenues = [];
      this.hasNextVenues = true;
      this.page = 1;

      this.getVenuesFromAPI(
        reservationDate ? reservationDate : null,
        this.bindAllData.city,
        this.bindAllData.neighborhood,
        this.bindAllData._location
      );
      if (reservationDate) {
        // Register log only if it is not a reset filter action
        this._logService
          .registerFilterLog({
            type: "ReservationDate",
            value: reservationDate,
          })
          .subscribe(() => {});
      }
    } else {
      reservationDate = null;
    }
  }

  public scrollToRestaurants() {
    window.scrollTo(0, 100);
  }

  public citySelected(_city) {
    this.bindAllData.city = _city;
    this.hasNextVenues = true;
    this.bindAllData.allItauVenues = null;
    this.getVenuesFromAPI(
      this.bindAllData.reservationDay ? this.bindAllData.reservationDay.value : null,
      _city,
      null,
      null
    );

    this.sharedDataService.changeCityFilter(_city);
  }

  public neighborhoodSelected(_neighborhood) {
    this.bindAllData.neighborhood = _neighborhood;
    this.district = _neighborhood;
    this.page = 1;
    this.bindAllData.allItauVenues = [];
    this.bindAllData.filteredVenues = [];

    this.getVenuesFromAPI(
      this.bindAllData.reservationDay ? this.bindAllData.reservationDay.value : null,
      null,
      _neighborhood,
      null
    );

    this.sharedDataService.changeCityFilter(_neighborhood);
    this.citySelect = "";
  }

  public partySizeSelected(_selected, _partySize) {
    this.bindAllData.partySize = _partySize;
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));

    if (!_partySize || !_partySize.value) {
      // this.bindAllData.partySize = {value: null, name: "-"};
      if (this.bindAllData.reservationTime) this.bindAllData.reservationTime = null;
    } else {
      this.bindAllData.partySize = _partySize;
    }

    if (this.bindAllData.reservationDay) {
      this.page = 1;
      this.bindAllData.allItauVenues = [];
      this.bindAllData.filteredVenues = [];
      this.hasNextVenues = true;
      this.page = 1;
      // this.bindAllData.partySize;
      this.getVenuesFromAPI(
        this.bindAllData.reservationDay,
        this.bindAllData.city,
        this.bindAllData.neighborhood,
        this.bindAllData._location
      );
      // this.applyFilters();
    }
    if (_partySize) {
      // Register log only if it is not a reset filter action
      this._logService.registerFilterLog({ type: "PartySize", value: _partySize.value }).subscribe(() => {});
    }
  }

  public reservationTimeSelected(_selected, _reservationTime) {
    this.page = 1;
    this.bindAllData.allItauVenues = [];
    this.bindAllData.filteredVenues = [];
    this.bindAllData.time = _reservationTime;
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));
    if (!_reservationTime) {
      this.bindAllData.reservationTime = null;
      this.bindAllData.filteredVenues = [];
      this.hasNextVenues = true;
      this.page = 1;
      // this.bindAllData.reservationTime;
      this.getVenuesFromAPI(
        this.bindAllData.reservationDay,
        this.bindAllData.city,
        this.bindAllData.neighborhood,
        this.bindAllData._location
      );
      // this.applyFilters();
    } else {
      this._logService.registerFilterLog({ type: "ReservationTime", value: _reservationTime }).subscribe(() => {});
      this.bindAllData.reservationTime = _reservationTime;

      this.bindAllData.filteredVenues = [];
      this.hasNextVenues = true;
      this.page = 1;
      // this.bindAllData.reservationTime;
      this.getVenuesFromAPI(
        this.bindAllData.reservationDay,
        this.bindAllData.city,
        this.bindAllData.neighborhood,
        this.bindAllData._location
      );
      // this.applyFilters();
    }
  }

  public cuisineSelected(_cuisine, isUserClick) {
    if (_cuisine == "Todas as cozinhas") {
      this.bindAllData.cuisine = null;
    } else {
      this.bindAllData.cuisine = _cuisine;
    }

    this.page = 1;
    this.bindAllData.allItauVenues = [];
    this.bindAllData.filteredVenues = [];
    this.hasNextVenues = true;
    this.page = 1;
    // this.bindAllData.cuisine;
    this.getVenuesFromAPI(
      this.bindAllData.reservationDay,
      this.bindAllData.city,
      this.bindAllData.neighborhood,
      this.bindAllData._location
    );

    // this.applyFilters();
    if (isUserClick) {
      this._logService.registerFilterLog({ type: "Cuisine", value: _cuisine }).subscribe(() => {});
    }
  }

  public findCuisine(_selected, _cuisineName, loopIndex: number) {
    this.bindAllData.filteredVenueCuisineIndex = loopIndex;
    this.bindAllData.venueCuisine = _cuisineName;
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));
    if (_selected.source.checked) {
      this.cuisineSelected(_cuisineName, true);
    } else {
      this.cuisineSelected(null, true);
    }
  }

  public async findRestaurantStyle(_selected, _styleName, loopIndex: number) {
    this.bindAllData.allItauVenues = [];
    this.bindAllData.filteredVenues = [];
    this.hasNextVenues = true;
    this.page = 1;
    this.allowedSkill = this.bindAllData.restaurantStyles[loopIndex].allowedSkill;
    this.bindAllData.filteredRestaurantStyleIndex = loopIndex;
    this.bindAllData.labelFilterSelected = _styleName;
    this.bindAllData.restaurantStyle = _styleName;
    // this.bindAllData.restaurantStyle;
    await this.fetchSeeMore();
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));

    this.labelFilterSelected = _styleName;
    if (_styleName === "Para desfrutar") _styleName = "Todos";
    if (_styleName === "Todos") {
      this.bindAllData.restaurantStyle = "";
      this.labelFilterSelected = this.bindAllData.restaurantStyles[0].name;
    }

    if (_selected.source.checked) {
      this.restaurantStyleSelected(_styleName, true);
    } else {
      this.restaurantStyleSelected(null, true);
    }
  }

  public findbenefitType(_selected, _benefitType, loopIndex: number) {
    this.bindAllData.filteredBenefitTypeIndex = loopIndex;
    this.bindAllData.benefitType = _benefitType;
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));
    if (_selected.source.checked) {
      this.benefitTypeSelected(_benefitType, true);
    } else {
      this.benefitTypeSelected(null, true);
    }
  }

  public restaurantStyleSelected(_style, isClick) {
    if (_style === "Todos") {
      this.bindAllData.restaurantStyle = null;
    } else {
      this.bindAllData.restaurantStyle = _style;
    }

    // this.applyFilters();
    if (isClick) {
      this._logService.registerFilterLog({ type: "RestaurantStyle", value: _style }).subscribe(() => {});
    }
  }

  public priceRangeSelected(_price) {
    if (_price.value === 0) {
      this.bindAllData.priceRange = null;
    } else {
      this.bindAllData.priceRange = _price.value;
      this._logService.registerFilterLog({ type: "PriceRange", value: _price.value }).subscribe(() => {});
    }
    // this.applyFilters();
  }

  public benefitTypeSelected(_benefitType, isUserClick) {
    if (_benefitType === "Todos") {
      this.bindAllData.benefitType = null;
    } else {
      this.bindAllData.benefitType = _benefitType;
    }

    this.page = 1;
    this.bindAllData.allItauVenues = [];
    this.bindAllData.filteredVenues = [];
    this.hasNextVenues = true;
    this.page = 1;
    // this.bindAllData.benefitType;
    this.getVenuesFromAPI(
      this.bindAllData.reservationDay,
      this.bindAllData.city,
      this.bindAllData.neighborhood,
      this.bindAllData._location
    );

    // this.applyFilters();
    if (isUserClick) {
      this._logService.registerFilterLog({ type: "BenefitType", value: _benefitType }).subscribe(() => {});
    }
  }

  public definePriceRange(_priceRange) {
    const convertPriceRange = {
      1: { min: 401, max: 10000 },
      2: { min: 301, max: 400 },
      3: { min: 201, max: 300 },
      4: { min: 101, max: 200 },
      5: { min: 1, max: 100 },
    };

    return convertPriceRange[_priceRange];
  }

  public formatLabel(value: number) {
    if (value >= 1) return "R$" + value + ",00";

    return value;
  }

  public applyFilters() {
    this.hasFilterSelected();
    let auxFilteredVenues = []; //_.cloneDeep(this.bindAllData.allItauVenues);
    let auxCuisine = this.bindAllData.cuisine;
    let auxPartySize = this.bindAllData.partySize;
    let auxReservationTime = this.bindAllData.reservationTime;
    let auxDay = this.bindAllData.reservationDay;
    let auxNameRestaurant = this.bindAllData.nameRestaurant;
    let auxIdsNameRestaurant = this.bindAllData.idsNameRestaurant;

    auxFilteredVenues = _.cloneDeep(this.bindAllData.allItauVenues);
    if (auxCuisine) {
      // || auxNameRestaurant) {
      this.getWithCuisineFilter(
        auxDay,
        auxCuisine,
        auxFilteredVenues,
        auxPartySize,
        auxReservationTime,
        auxNameRestaurant,
        auxIdsNameRestaurant
      );
    } else {
      if (!auxPartySize.value) {
        _.forEach(this.bindAllData.allItauVenues, (venueIterator) => {
          if (venueIterator.availability && venueIterator.availability.available)
            if (!_.find(auxFilteredVenues, { _id: venueIterator._id })) auxFilteredVenues.push(venueIterator);
        });
      } else {
        _.forEach(this.bindAllData.allItauVenues, (venueIterator) => {
          if (!auxDay || (venueIterator.availability && venueIterator.availability.available)) {
            let found = false;
            //if(venueIterator.availability && venueIterator.availability.available) {
            _.forEach(venueIterator.availability.sections, (sectionIterator) => {
              if (sectionIterator.available) {
                _.forEach(sectionIterator.schedules, (scheduleIterator) => {
                  if (scheduleIterator.available && parseInt(scheduleIterator.partySize) == auxPartySize.value) {
                    if (auxReservationTime) {
                      if (scheduleIterator.reservationTimes && scheduleIterator.reservationTimes.length > 0) {
                        _.forEach(scheduleIterator.reservationTimes, (reservationTimeIterator) => {
                          if (reservationTimeIterator.available) {
                            if (reservationTimeIterator.reservationTime.toString() == auxReservationTime) {
                              auxFilteredVenues.push(venueIterator);
                              found = true;
                              return false;
                            }
                          }
                        });
                        if (found) return false;
                      }
                    } else {
                      auxFilteredVenues.push(venueIterator);
                      found = true;
                      return false;
                    }
                  }
                });
              }
            });
            // }
          }
        });
      }
    }

    //get venues by price average
    auxFilteredVenues = this.getWithPriceRangeFilter(auxFilteredVenues);
    // Restaurant style and benefit type filterthis.bindAllData.month
    if (
      (this.bindAllData.restaurantStyle || this.bindAllData.benefitType) &&
      this.bindAllData.restaurantStyle !== "Para desfrutar" &&
      auxFilteredVenues &&
      auxFilteredVenues.length > 0
    ) {
      auxFilteredVenues = this.getWithRestaurantStyleOrBenefitTypeFilters(auxFilteredVenues);
    } else {
      //populate dynamic list for style restaurants
      this.bindAllData.restaurantStyles.forEach((restaurantStyle) => {
        restaurantStyle.filteredVenues = auxFilteredVenues.filter((venue) => {
          if (auxCuisine && !venue.cuisines.includes(auxCuisine)) return false;

          // if(_.find(restaurantStyle.filteredVenues, {'_id': venue._id})) return false;

          if (venue.prizeNames.length < 1 && restaurantStyle.benefits.length < 0) {
            return false;
          }
          const venueHasBenefitsOfFilter = _.intersection(venue.prizeNames, restaurantStyle.benefits).length > 0;
          return venueHasBenefitsOfFilter;
        });
        restaurantStyle.filteredVenues = _.uniqBy(restaurantStyle.filteredVenues, "name");
      });
    }
    //populate only cuisine selected for general list
    if (auxCuisine) {
      auxFilteredVenues.forEach((venue) => {
        auxFilteredVenues = [];
        const hasCuisineSelected = venue.cuisines.includes(auxCuisine);
        if (hasCuisineSelected) auxFilteredVenues.push(venue);
      });
    }

    if (this.bindAllData.day && this.bindAllData.month) {
      auxFilteredVenues = auxFilteredVenues.filter((venue) => venue.availability.available);

      for (let item of this.bindAllData.restaurantStyles)
        item.filteredVenues = item.filteredVenues.filter((venue) => venue.availability.available);
    }

    this.bindAllData.filteredVenues = _.uniqBy(auxFilteredVenues, "name");
    this._geoService.setCurrentData(this.bindAllData);
    // this.scrollToRestaurants();
  }

  private getWithCuisineFilter(
    auxDay: any,
    auxCuisine: any,
    auxFilteredVenues: any[],
    auxPartySize: { value: any; name: string },
    auxReservationTime: Date,
    auxNameRestaurant: any[],
    auxIdsNameRestaurant: any[]
  ) {
    //for parallel lists
    this.bindAllData.restaurantStyles.forEach((restaurantStyle) => {
      // restaurantStyle.filteredVenues.forEach( (filteredVenue, i) => {
      // if (!hasCuisineSelected) restaurantStyle.filteredVenues.splice(i,1);
      restaurantStyle.filteredVenues = [];
      restaurantStyle.filteredVenues = auxFilteredVenues.filter((venue) => {
        const hasCuisineSelected = venue.cuisines.includes(auxCuisine);
        const isSameTypeList = _.intersection(venue.prizeNames, restaurantStyle.benefits).length > 0;
        return hasCuisineSelected && isSameTypeList;
      });
      // });
    });
    //for general list
    _.forEach(this.bindAllData.allItauVenues, (venueIterator) => {
      let found = false;
      if (!auxDay || (venueIterator.availability && venueIterator.availability.available)) {
        _.forEach(venueIterator.cuisines, (cuisineIterator) => {
          if (cuisineIterator.toString() == auxCuisine) {
            if (auxDay) {
              // Filter only by cuisine
              auxFilteredVenues.push(venueIterator);
              return false;
            }
            if (auxPartySize.value) {
              //if(venueIterator.availability && venueIterator.availability.available) {
              _.forEach(venueIterator.availability.sections, (sectionIterator) => {
                if (sectionIterator.available) {
                  _.forEach(sectionIterator.schedules, (scheduleIterator) => {
                    if (scheduleIterator.available && parseInt(scheduleIterator.partySize) == auxPartySize.value) {
                      if (auxReservationTime) {
                        if (scheduleIterator.reservationTimes && scheduleIterator.reservationTimes.length > 0) {
                          _.forEach(scheduleIterator.reservationTimes, (reservationTimeIterator) => {
                            if (reservationTimeIterator.available) {
                              if (reservationTimeIterator.reservationTime.toString() == auxReservationTime) {
                                auxFilteredVenues.push(venueIterator);
                                found = true;
                                return false;
                              }
                            }
                          });
                          if (found) return false;
                        }
                      } else {
                        auxFilteredVenues.push(venueIterator);
                        found = true;
                        return false;
                      }
                    }
                  });
                }
              });
              // } else {
              //   return false;
              //}
            } else {
              auxFilteredVenues.push(venueIterator);
              return false;
            }
          }
        });
      }
    });
  }

  private getWithRestaurantStyleOrBenefitTypeFilters(auxFilteredVenues: any[]) {
    localStorage.setItem("lastFilter", JSON.stringify(this.bindAllData));

    let prizeNames: Array<string> = [];
    const benefitType: string = this.bindAllData.benefitType;
    const restaurantStyle: string = this.bindAllData.restaurantStyle;
    if (restaurantStyle === "Jantar fino") {
      prizeNames.push(this.GRAND_MENU);
      prizeNames.push(this.MENU_PERSONNALITE);
      prizeNames.push(this.VINHO_PERSONNALITE);
    } else if (restaurantStyle === this.sophisticatedOrBistro) {
      prizeNames.push(this.MENU_PERSONNALITE);
      prizeNames.push(this.VINHO_PERSONNALITE);
    } else if (restaurantStyle === this.MENU_CASUAL) {
      prizeNames.push(this.MENU_CASUAL);
      prizeNames.push(this.CASUAL_ITAU);
    }
    if (benefitType === this.GRAND_MENU && prizeNames.indexOf(this.GRAND_MENU) < 0) {
      prizeNames.push(this.GRAND_MENU);
    } else if (benefitType === this.MENU_PERSONNALITE && prizeNames.indexOf(this.MENU_PERSONNALITE) < 0) {
      prizeNames.push(this.MENU_PERSONNALITE);
    } else if (benefitType === this.MENU_CASUAL) {
      prizeNames.push(this.MENU_CASUAL);
      prizeNames.push(this.CASUAL_ITAU);
    } else if (benefitType === this.VINHO_PERSONNALITE) {
      prizeNames.push(this.VINHO_PERSONNALITE);
    }
    this.bindAllData.restaurantStyles.forEach(restaurantStyle => {
      restaurantStyle.filteredVenues = []
      const restaurantStyleHasBenefitsSelected = restaurantStyle.benefits.includes(benefitType);
      if (restaurantStyleHasBenefitsSelected) {
        restaurantStyle.filteredVenues = auxFilteredVenues.filter((venue) => {
          if (venue.prizeNames.length < 1 && prizeNames.length < 0) return false;
          const venueHasBenefitsOfFilter = _.intersection(venue.prizeNames, prizeNames).length > 0;
          return venueHasBenefitsOfFilter;
        });
      }
    });
    if (this.bindAllData.benefitType && this.bindAllData.benefitType !== "Todos") {
      auxFilteredVenues = [...(auxFilteredVenues || [])].filter((venue) =>
        venue.prizeNames.includes(this.bindAllData.benefitType)
      );
      return auxFilteredVenues;
    }
    if (restaurantStyle) {
      auxFilteredVenues = auxFilteredVenues.filter((venue) => {
        if (venue.prizeNames.length < 1 && prizeNames.length < 0) return false;
        const venueHasBenefitsOfFilter = _.intersection(venue.prizeNames, prizeNames).length > 0;
        return venueHasBenefitsOfFilter;
      });
      return auxFilteredVenues;
    }
  }

  private getWithPriceRangeFilter(auxFilteredVenues: any[]) {
    let auxListVenuesWithAveragePrice = [];
    if (!this.bindAllData.priceRange) return auxFilteredVenues;
    _.forEach(auxFilteredVenues, (venueIterator, i) => {
      if (venueIterator && venueIterator.priceRange) {
        const averagePrice = this.definePriceRange(venueIterator.priceRange);
        if (this.bindAllData.priceRange >= averagePrice.min && this.bindAllData.priceRange <= averagePrice.max) {
          auxListVenuesWithAveragePrice.push(venueIterator);
        }
      }
    });

    return auxListVenuesWithAveragePrice;
  }

  public fraudModal() {
    const phone = { customer: { cellphone: 21991453472 } };
    this.checkoutService.fraudModal(phone);
  }

  public onBoardingModal() {
    this.checkoutService.onBoardingModal();
  }

  public clearStoreFilter() {
    localStorage.setItem("lastFilter", "");
    location.reload();
  }

  public handleSearchValueChange(event: any) {
    this.searchValue = event.target.value;
  }

  public saveRestaurantName(event: any) {
    event.preventDefault();
    this.bindAllData.filteredVenues = [];
    this.bindAllData.allItauVenues = [];
    this.page = 1;

    this.searchValue = this.searchValue
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    this.getVenuesFromAPI(
      this.bindAllData.reservationDay,
      this.bindAllData.city,
      this.bindAllData.neighborhood,
      this.bindAllData._location
    );
  }

  fetchSeeMore() {
    this.getVenuesFromAPI(
      this.bindAllData.reservationDay,
      this.bindAllData.city,
      this.bindAllData.neighborhood,
      this.bindAllData._location
    );
  }

  private getVenuesFromAPI(_reservationDay, _city, _neighborhood, _location, _name?) {
    this.showGhosts = true;

    if (_city == "Todas as Cidades") _city = null;
    // Get Itau Venues

    // if (!this.authGuardService.getItemLS('client').context) await this.authService.client(this.authGuardService.getCookie('token'));
    const localStorageClient = this.authGuardService.getItemLS("client");
    if (!localStorageClient) {
      throw new Error(`Could not find client on LocalStorage.`);
    }
    const segment = localStorageClient.catalogId;

    const name = this.searchValue.trim().length > 0 ? this.searchValue : null;

    const reservationTime = this.bindAllData.reservationTime ? this.bindAllData.reservationTime : null;
    const benefitType = this.bindAllData.benefitType !== "Todos" ? this.bindAllData.benefitType : null;

    this.getVenuesService = this._venueService
      .getVenues(
        _city,
        _neighborhood,
        _location,
        segment,
        name,
        this.bindAllData.cuisine,
        benefitType,
        this.allowedSkill,
        this.page,
        this.limit
      )
      .subscribe(
        (_venues) => {
          _venues.venues = _venues.venues.map((venue) => ({
            ...venue,
            display: true,
            prizeNames: venue.prizes.map((prize) => prize.name),
            cuisines: venue.cousines.map((cuisine) => cuisine.name.pt),
          }));

          this.onVenuesChange(_venues, _reservationDay);

          // this._exchangeService.getAllExchanges().subscribe(exchanges => {
          //   this.exchanges = exchanges;
          //   this.showGhosts = false;
          // });
          if (_venues.next) {
            this.page = _venues.next.page;
            this.limit = _venues.next.limit;
          } else {
            this.hasNextVenues = false;
          }

          this.showGhosts = false;
        },
        (err) => {
          this.authGuardService.alert(
            "Erro ao tentar carregar restaurantes. Tente novamente em instantes, por favor.",
            "ok"
          );
          this.loaderService.stop();
          throw err;
        }
      );
  }

  public goPages(page: string): void {
    for (var i = 0; i < this.buttonTop.length; i++) {
      if (page == this.buttonTop[i].function) {
        this.buttonTop[i].active = true;
      } else {
        this.buttonTop[i].active = false;
      }
    }

    this.router.navigate(["gastronomia/", page]);
  }

  private async getCuisineFromAPI() {
    this.bindAllData.venuesCuisines = [];
    this.bindAllData.venuesCuisines = await this._venueService.getCuisineFromAPI().toPromise();
  }
}
