import { AuthGuardService } from "./../../_services/auth-guard.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { MatBottomSheet, MatDialog } from "@angular/material";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import { VenueService } from "app/_services/venue.service";
import { environment } from "environments/environment";
import { AuthenticationService } from "app/_services/authentication.service";
import { ModalRatingComponent } from "../modal-rating/modal-rating.component";
import { ModalDownRatingComponent } from "../modal-down-rating/modal-down-rating.component";
import { SharedDataService } from "app/_services/sharedData.service";
import { PrizeService } from "app/_services/prize.service";

@Component({
  styleUrls: ["./venue-details.component.scss"],
  templateUrl: "./venue-details.component.html",
})
export class VenueDetailsComponent implements OnInit {
  private venueId: string;
  public venue;
  public delivery = true;
  public selectedTab: string = null;
  public selectedNameRestaurant: string = "p";
  public showDeliveryInfo = false;
  public carouselImages: string[] = [];
  public benefits: object[] = [];
  public exchange;
  public showGhosts: boolean = true;
  public deliveryTab = "tab-selected";
  public reservationTab = "tab-hide";
  public nameMenuBanner: string;
  public srcIcon: string;
  public nameRestaurante = "";
  public isNotBobs = true;
  public exp = true;
  public inf = false;
  public firtMenu = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authGuardService: AuthGuardService,
    private authService: AuthenticationService,
    private venueService: VenueService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sharedDataService: SharedDataService,
    private prizeService: PrizeService
  ) {}

  ngOnInit() {
    moment.locale("pt-br");

    if (window.location.href.indexOf("reload") == -1) {
      window.location.replace(window.location.href + "?reload");
    }

    window.scrollTo(0, 0);

    try {
      this.route.params.subscribe(async (params: Params) => {
        this.venueId =
          params["venueIdParam"] === "null" ? null : params["venueIdParam"];

        if (this.venueId) {
          // this.exchangeService.getExchangesByVenue(this.venueId)
          //   .subscribe(exchanges => {
          //     this.exchange = exchanges;
          //     this.getVenue();
          //   });

          this.getVenue();
        } else {
          // this.authService.alertLogin();
          // this.authGuardService.logout();
        }
      });
    } catch (err) {
      // console.log(err);
    }
  }

  //gambi asked form iupp team
  public changeNumeric(name) {
    this.firtMenu = name.replace("3", "1").replace("4", "2");

    if (this.nameRestaurante.toLowerCase().includes("bob's")) {
      this.isNotBobs = false;
    }

    return this.firtMenu;
  }

  private replaceNumeric(string) {
    return string.replace(/[0-9]/g, "").trim();
  }

  public selectTab(activeTab, name) {
    this.selectedTab = activeTab;
    this.selectedNameRestaurant = name.replace("3", "1").replace("4", "2");
  }

  public firstMenu() {
    if (this.selectedNameRestaurant == "p") {
      return this.firtMenu
        .replace("2", "1")
        .replace("3", "1")
        .replace("4", "1");
    } else {
      return this.selectedNameRestaurant;
    }
  }

  private getVenue() {
    const catalogId = "40539";
    // if (!catalogId) {
    //   this.authService.alertLogin();
    // }
    this.venueService
      .getVenue(this.venueId + "?segment=" + catalogId)
      .subscribe(
        (venue) => {
          if (venue) {
            //venue.prizes = this.sortPrizesAlphabetically(venue.prizes);
            this.venue = venue;
            this.carouselImages = this.getImagesFromVenueToCarousel();
            this.authGuardService.setItemLS("venue", venue);
            this.checkIfExistsAndSetPoints(this.venue);

            this.buildBenefitsMenus();

            this.showGhosts = false;

            document.title = this.venue.name.pt + " - menu itaú";
            this.nameRestaurante = this.venue.name.pt;
          } else {
            this.authGuardService.alert(
              "Ops. Erro ao buscar dados do restaurante. Favor, entre em contato com o suporte.",
              "ok"
            );
            document.title = "Erro ao buscar dados do restaurante - menu itaú";
          }
        },
        (err) => {
          // console.log(err);
          this.authGuardService.alert(
            "Ops. Erro ao buscar dados do restaurante. Favor, entre em contato com o suporte.",
            "ok"
          );
          document.title = "Erro ao buscar dados do restaurante - menu itaú";
        }
      );
  }

  private buildBenefitsMenus() {
    this.venue.prizes.forEach((prize) => {
      if (prize.menus.length > 0) {
        prize.menus.forEach((menu) => {
          const benefit = { id: menu._id, name: menu.name.pt, srcIcon: null };
          this.benefits.push(benefit);
          this.nameMenuBanner = this.replaceNumeric(benefit.name);
          menu.srcIcon = this.getBanner(this.nameMenuBanner);
        });
      } else {
        const benefit = { id: prize._id, name: prize.name, srcIcon: null };
        this.benefits.push(benefit);
        this.nameMenuBanner = benefit.name;
        benefit.srcIcon = this.getBanner(this.nameMenuBanner);
      }
      this.selectedTab = this.benefits[0]["id"];
    });
  }

  // private getBanner(menuType){
  //   let srcIcon = null
  //   if( menuType.includes("Menu Casual") || menuType.includes("Casual Itaú")){
  //     srcIcon = "../../../assets/images/background/menu-casual-banner-iupp.png"
  //   }else if( menuType.includes("Grand Menu")){
  //     srcIcon = "../../../assets/images/background/grand-menu-banner-iupp.png"
  //   }else if( menuType.includes("Menu Fast")){
  //     srcIcon = "../../../assets/images/background/menu-fast-banner-iupp.png"
  //   }else{
  //     srcIcon = "../../../assets/images/background/menu-personnalite-banner-iupp.png"
  //   }
  //   return srcIcon
  // }

  private getBanner(menuType) {
    let srcIcon = null;
    if (menuType.includes("Menu Casual") || menuType.includes("Casual Itaú")) {
      srcIcon = "../../../assets/images/background/menu-casual-banner.png";
    } else if (menuType.includes("Grand Menu")) {
      srcIcon = "../../../assets/images/background/grand-menu-desktop.png";
    } else if (menuType.includes("Menu Fast")) {
      srcIcon = "../../../assets/images/background/menu-fast-banner.png";
    } else {
      srcIcon =
        "../../../assets/images/background/menu-personnalite.png";
    }
    return srcIcon;
  }

  private getImagesFromVenueToCarousel(): string[] {
    const images: string[] = [];

    if (this.venue.images && this.venue.images.ibeneficios_app_interna) {
      this.venue.images.ibeneficios_app_interna.forEach((image) =>
        images.push(`${environment.config.imageUrl}${image}`)
      );
    } else if (
      this.venue.images &&
      this.venue.images.original_mobile_background
    ) {
      this.venue.images.original_mobile_background.forEach((image) =>
        images.push(`${environment.config.imageUrl}${image}`)
      );
    } else {
      images.push(this.venue.app.sitePortraitCovers);
    }

    return images;
  }

  private checkIfExistsAndSetPoints(venue: any) {
    if (venue.prizes && venue.prizes.length > 0) {
      venue.prizes.forEach((prize) => {
        if (this.exchange) {
          this.exchange.forEach((exchange) => {
            if (exchange.prize.price == prize.price) {
              prize.priceInPoints = Math.trunc(
                parseInt(prize.price) / exchange.rate
              );
            } else {
              prize.priceInPoints = Math.trunc(
                parseInt(prize.price) / this.sharedDataService.CPP
              );
            }
          });
        } else {
          prize.priceInPoints = Math.trunc(
            parseInt(prize.price) / this.sharedDataService.CPP
          );
        }
      });
    }
  }

  private sortPrizesAlphabetically(prizes: any) {
    return prizes.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });
  }

  public backToHome(): void {
    this.router.navigate(["gastronomia"]);
  }

  public getToken() {
    return this.authGuardService.getCookie("token");
  }

  public goCheckout(reservationRequired: boolean): void {
    this.router.navigate(["gastronomia/checkout", this.venueId], {
      state: { data: reservationRequired },
    });

    localStorage.setItem("state.data", reservationRequired.toString());
  }

  public wineCountrySlug(prize): string {
    try {
      const countryName: string = prize.menuItem.product.country.name.en
        .toLowerCase()
        .replace(" ", "-");

      return `https://tagme.com.br/assets/images/icons/paises/${countryName}.png`;
    } catch (error) {
      return null;
    }
  }

  public wineCountryName(prize): string {
    try {
      return prize.menuItem.product.country.name.pt;
    } catch (error) {
      return null;
    }
  }

  public wineName(prize): string {
    try {
      return prize.menuItem.product.name.pt;
    } catch (error) {
      return null;
    }
  }

  public wineTypeName(prize): string {
    try {
      return prize.menuItem.product.wineDetails.type.slug;
    } catch (error) {
      return null;
    }
  }

  public wineTypeText(prize): string {
    try {
      return prize.menuItem.product.wineDetails.type.name.pt;
    } catch (error) {
      return null;
    }
  }

  public wineAlcohol(prize): string {
    try {
      return prize.menuItem.product.features.find(
        (feature) => feature.feature === "56c773f42ceaf5891343069e"
      ).value;
    } catch (error) {
      return null;
    }
  }

  public wineImage(prize): string {
    const defaultAvatarUrl = "../../../assets/images/bottle.png";

    try {
      const { avatarUrl } = prize.menuItem.product;

      if (avatarUrl) {
        return `${environment.config.imageUrl}${avatarUrl}`;
      }
    } catch (error) {
      return defaultAvatarUrl;
    }

    return defaultAvatarUrl;
  }

  public conditionsInArray(venue): Array<string> {
    try {
      return venue.prizes[0].conditions.split("; ");
    } catch (error) {
      return [];
    }
  }

  public showDetails(prize, idx): void {
    const router: string[] =
      prize.menus.length > 0
        ? ["/gastronomia/menu-details"]
        : prize.menus.length === 0
        ? ["/gastronomia/wine-details"]
        : ["../"];

    prize.venue = this.venue;

    this.prizeService.setPrize({ prize, idx });
    this.router.navigate(router);
  }

  public menuItemImageURL(avatarURL: string): string {
    let avatarFullUrl = `${environment.config.imageUrl}${avatarURL}`;
    if (avatarFullUrl.endsWith("/undefined")) {
      ///Caso retorne a URL com 'undefined' no final (img quebrada)
      avatarFullUrl = avatarFullUrl.slice(0, -10); ///Tiramos o 'undefined' e apontamos para a URL com a img default correta sem quebrar
      return avatarFullUrl + `/MenuItem/no_image.jpg`;
    } else {
      return `${environment.config.imageUrl}${avatarURL}`;
    }
  }

  public selectExp() {
    this.exp = true;
    if (this.inf == true) {
      this.inf = false;
    }
  }

  public selectInf() {
    this.inf = true;
    if (this.exp == true) {
      this.exp = false;
    }
  }

  public changeMode(delivery) {
    this.delivery = delivery;
    if (delivery) {
      this.deliveryTab = "tab-selected";
      this.reservationTab = "tab-hide";
    } else {
      this.deliveryTab = "tab-hide";
      this.reservationTab = "tab-selected";
    }
  }

  public hasDelivery() {
    return (
      this.venue &&
      this.venue.delivery &&
      this.venue.delivery.enabled &&
      this.venue.delivery.available &&
      false
    );
  }

  public openDelivery() {
    window.open(
      environment.config.reservationWidgetUrl
        .concat(this.venueId.toString())
        .concat(environment.config.reservationWidgetQueryString),
      "_blank"
    );
  }

  openDialog() {
    this.dialog.open(ModalRatingComponent);
  }

  openBottomSheet() {
    this.bottomSheet.open(ModalDownRatingComponent);
  }
}
