import { MenusService } from "app/_services/menus.service";
import { Subscription } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataLayerService } from "./_services/gtm/data-layer.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "iupp-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private menusService: MenusService,
    private dataLayerService: DataLayerService,
    private router: Router
  ) {}

  private menusSubscription: Subscription;

  ngOnInit() {
    const defaultCatalogId = "40539";
    // this.menusSubscription = this.menusService.getHeaderAndFooter(defaultCatalogId).subscribe( menus => {
    //   this.menusService.setMenus(menus);
    // }, err => {
    //   console.log(err);
    // });
    this.router.events.subscribe((event) => {
      // subscribe to router events
      if (event instanceof NavigationEnd) {
        // if our event is of our interest
        this.dataLayerService.logPageView(event.url); //call our dataLayer service's page view method to ping home with the url value.
      }
    });
  }

  ngOnDestroy(): void {
    if (this.menusSubscription) this.menusSubscription.unsubscribe();
  }
}
