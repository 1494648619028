export const environment = {
  production: false,
  config: {
    imageUrl: "https://tagmepub.azureedge.net/pubimg/",
    fileUrl: "https://tagmepub.azureedge.net/pubfiles/",
    iBeneficiosApiUrl: "https://menupersonback-dev.azurewebsites.net",
    iBeneficiosApiToken: "IFNkQeCARIDEyFOMirAndaRuLOUROkNfAYUR1P0NT3S",
    reservationWidgetUrl: "https://reservation-widget-staging.azurewebsites.net/delivery/",
    reservationWidgetQueryString: "?origin=iupp",
    redirect_logged: "",
    redirect_logout: "",
  },
  iupp: {
    url: {
      auth: "https://oldold.com.br",
      main: "https://www.oldold.com.br",
    },
    onBoardingUrl: "https://hml-onboarding.azurewebsites.net/Login/RedirectCognito?id=40455",
    itauStaticToken: "iuppIFNkQeCARIDEyFOMirAndaRuLOUROkNfG",
    clientID: "7mn9l5jadbk9t0juh7q9lf57iu",
  },
  message: {
    pt: {
      checkout: {
        venue: {
          description:
            "Para sua comodidade, selecione um ou mais benefícios para desfrutar na sua reserva:",
        },
      },
      home: {
        filter: {
          cusine: "Filtrar opções gastronômicas",
          style: "Estilo do restaurante",
          culinary: "Tipo de culinária",
          tableAvailable: "Disponibilidade de Mesa",
          tableTime: "Disponibilidade de horário",
          reservationDate: "Data de Reserva",
          priceRange: "Média da conta por pessoa",
          benefit: "Tipo de benefício",
        },
        mosaic: {
          previous: "Restaurantes anteriores",
          next: "Próximos restaurantes",
        },
        title: {
          nearly: "Restaurantes próximos de você",
        },
      },
    },
  },
};
