import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";

@Component({
  selector: "filter-address-modal",
  templateUrl: "./filter-address-modal.component.html",
  styleUrls: ["./filter-address-modal.component.scss"],
})
export class FilterAddressModalComponent implements OnInit {
  @Output() setModalEmitter: EventEmitter<any> = new EventEmitter();
  @Input() modal;
  public slug = "address";
  public objectLoop = Object.keys;
  public tab: any = {
    list: {
      slug: "list",
      title: "Endereço de entrega",
      isActive: true,
    },
    create_form: {
      slug: "create_form",
      title: "Adicionar novo endereço",
      isActive: false,
    },
    edit_form: {
      slug: "edit_form",
      title: "Editar endereço",
      isActive: false,
    },
  };

  public dataAddressForm: FormGroup = null;

  public dataAddress: any[] = [
    { name: "Destino de entrega (opcional)", form: "destino" },
    { name: "Endereço", form: "endereco" },
    { name: "Número", form: "numero" },
    { name: "Complemento", form: "complemento" },
    { name: "Bairro", form: "bairro" },
    { name: "Cidade", form: "cidade" },
    { name: "Estado", form: "estado" },
    { name: "CEP", form: "cep" },
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm(): void {
    this.dataAddressForm = this.fb.group({
      destino: ["", []],
      endereco: [
        "",
        [Validators.required, Validators.pattern(/^[a-zA-Z]+/), Validators.minLength(3), Validators.maxLength(60)],
      ],
      numero: [
        "",
        [Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.minLength(1), Validators.maxLength(8)],
      ],
      complemento: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      bairro: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
      cidade: [
        "",
        [Validators.required, Validators.pattern(/^[a-zA-Z]+/), Validators.minLength(1), Validators.maxLength(2)],
      ],
      estado: [
        "",
        [Validators.required, Validators.pattern(/^[a-zA-Z]+/), Validators.minLength(2), Validators.maxLength(20)],
      ],
      cep: ["", [Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.maxLength(8)]],
    });
  }

  setActivatedTab(key: string) {
    Object.keys(this.tab).forEach((item) => {
      this.tab[item].isActive = key === item;
    });
  }

  getActivatedTab() {
    const activatedTab = Object.keys(this.tab).find((item) => this.tab[item].isActive);
    return this.tab[activatedTab];
  }

  setModalVisibleState(display: boolean) {
    this.modal[this.slug] = { ...this.modal[this.slug], close_animation: !display };

    setTimeout(() => {
      this.modal[this.slug] = { ...this.modal[this.slug], display, close_animation: display };
      this.setModalEmitter.emit(this.modal);
    }, 500);
  }

  closeOnClickOutside(el) {
    const isModalNavigate = el.target.classList.contains("modal__navigate");
    const hasModalContentParent = el.target.closest(".modal__content");

    if (!isModalNavigate && !hasModalContentParent) this.setModalVisibleState(false);
  }

  seeValid() {
    for (var i = 0; i < this.dataAddress.length; i++) {
      if (this.dataAddressForm.get(this.dataAddress[i].form).status == "INVALID") {
        if (this.dataAddress[i].name.includes("(inválido)")) {
          this.dataAddress[i].name;
        } else {
          this.dataAddress[i].name = this.dataAddress[i].name + " (inválido)";
        }
      } else if (this.dataAddress[i].name.includes("(inválido)")) {
        this.dataAddress[i].name = this.dataAddress[i].name.replace("(inválido)", "");
      }
    }
  }
}
