import { Component, Inject } from '@angular/core';
import { Modal } from '../../interfaces/Modal';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Benefit } from '../../interfaces/Benefit';

@Component({
  selector: 'app-wine-modal',
  templateUrl: './wine-modal.component.html',
  styleUrls: ['../modal.component.scss', './wine-modal.component.scss'],
})
export class WineModalComponent implements Modal {
  constructor(
    public dialogRef: MatDialogRef<WineModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Benefit,
  ) {}

  public getWineTypeColor(slug: string): string {
    let color: string = null;

    switch (slug) {
      case 'destilado_de_uva':
        color = '#ffffff';
        break;

      case 'champagne':
      case 'espumante':
        color = '#d6d65d';
        break;

      case 'tinto':
        color = '#810024';
        break;

      case 'rose':
        color = '#f95154';
        break;

      case 'madeira':
      case 'jerez':
        color = '#ea8030';
        break;

      case 'branco_fortificado':
      case 'branco_doce':
      case 'porto_branco':
        color = '#a38945';
        break;

      case 'tinto_fortificado':
      case 'porto':
        color = '#990012';
        break;

      case 'tinto_doce':
        color = '#740001';
        break;

      case 'branco':
        color = '#f8f6c3';
        break;

      default:
    }
    return color;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
