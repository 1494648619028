import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-card-not-allowed-modal',
  templateUrl: './card-not-allowed-modal.component.html',
  styleUrls: [
    '../modal.component.scss',
    './card-not-allowed-modal.component.scss',
  ],
})
export class CardNotAllowedModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<CardNotAllowedModalComponent>) {}

  ngOnInit() {}
}
