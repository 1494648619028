import { Component, OnInit } from "@angular/core";

@Component({
  selector: "favorite-loading",
  templateUrl: "./favorite-loading.component.html",
  styleUrls: ["./favorite-loading.component.scss"],
})
export class FavoriteLoadingComponent implements OnInit {
  public loadingVenues: Array<null> = Array(10).fill(null);

  constructor() {}

  ngOnInit() {}
}
