import { VerificationCodeModalComponent } from "./checkout/modal/verification-code-modal/verification-code-modal.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { IuppComponent } from "./iupp.component";
import { MaintenanceComponent } from "./../maintenance/maintenance.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { CheckoutFinishedComponent } from "./checkout-finished/checkout-finished.component";
import { VenueDetailsComponent } from "./venue-details/venue-details.component";
import { MenuDetailsComponent } from "./menu-details/menu-details.component";
import { WineDetailsComponent } from "./wine-details/wine-details.component";
import { ReservationComponent } from "./reservation/reservation.component";
import { HomeComponent } from "./page/home/home.component";
import { FavoriteComponent } from "./page/favorite/favorite.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "gastronomia",
        component: IuppComponent,
        // canActivateChild: [AuthGuardService],
        children: [
          {
            path: "",
            component: HomeComponent,
            // component: MaintenanceComponent,
          },
          // {
          //   path: "meus-favoritos",
          //   component: FavoriteComponent,
          //   // component: MaintenanceComponent,
          // },
          // {
          //   path: "checkout/:venueIdParam",
          //   component: CheckoutComponent,
          //   pathMatch: "full",
          // },
          // {
          //   path: "finished/:hasRedemption/:hasReservation",
          //   component: CheckoutFinishedComponent,
          // },
          // {
          //   path: "finished/:hasRedemption/:hasReservation/:orderId",
          //   component: CheckoutFinishedComponent,
          // },
          // {
          //   path: "finished/:hasRedemption/:hasReservation/:orderId/:venueId",
          //   component: CheckoutFinishedComponent,
          // },
          {
            path: "venue-details/:venueIdParam",
            component: VenueDetailsComponent,
            pathMatch: "full",
          },
          {
            path: "venue-details",
            component: VenueDetailsComponent,
            pathMatch: "full",
          },
          {
            path: "menu-details",
            component: MenuDetailsComponent,
          },
          {
            path: "wine-details",
            component: WineDetailsComponent,
          },
          // {
          //   path: "verification-code",
          //   component: VerificationCodeModalComponent,
          // },
          // {
          //   path: "reservation/cancel/:reservationId",
          //   component: ReservationComponent,
          // },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class IuppRoutingModule {}
