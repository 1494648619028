import { ShoppingCart } from "../interfaces/ShoppingCart";
import { Mock } from "../interfaces/Mock.interface";

export class ShoppingCartMock implements Mock {
  static default(): ShoppingCart {
    return {
      prize: {
        id: 1,
        name: "",
        price: 0,
      },
      items: [
        {
          benefit: {
            id: "1",
            name: "",
            conditions: "",
            imgUrl:
              "../../../assets/images/checkout-icon-menu-personnalite-quadrado.png",
            price: {
              points: { current: 0, previous: 0 },
              cash: { current: 0, previous: 0 },
            },
            wine: null,
            menu: {
              image_url: {
                // mobile: '../../../assets/images/background/checkout-menu-personnalite-banners-iupp-detalhes.png',
                // desktop: '../../../assets/images/background/menu-personnalite-banner-iupp.png'
                mobile:
                  "../../../assets/images/background/checkout-menu-personnalite-banners.png",
                desktop:
                  "../../../assets/images/background/menu-personnalite-banner.png",
              },
              items: [
                {
                  image_url:
                    // '../../../assets/images/background/little-menu-personnalite-banner-mobile-iupp.png',
                    "../../../assets/images/background/little-menu-personnalite-banner-mobile.png",
                  name: "",
                  description: "",
                },
                {
                  image_url:
                    // "../../../assets/images/background/little-menu-personnalite-banner-mobile-iupp.png",
                    "../../../assets/images/background/little-menu-personnalite-banner-mobile.png",
                  name: "",
                  description: "",
                },
                {
                  image_url:
                    // "../../../assets/images/background/little-menu-personnalite-banner-mobile-iupp.png",
                    "../../../assets/images/background/little-menu-personnalite-banner-mobile.png",
                  name: "",
                  description: "",
                },
              ],
            },
          },
          amount: 2,
        },
        {
          benefit: {
            id: "2",
            name: "Vinho Personalité",
            conditions: "",
            imgUrl:
              "../../../assets/images/background/icon-vinho-personnalite-quadrado.png",
            price: {
              points: { current: 0, previous: 0 },
              cash: { current: 0, previous: 0 },
            },
            wine: {
              image_url:
                // "../../../../../assets/images/background/vinho-personnalite-banner-iupp.png",
                "../../../../../assets/images/background/vinho-personnalite-banner.png",
              description: "Perini Graça Cabernet Sauvignon",
              country: {
                image_url:
                  "../../../../../assets/images/icons/paises/portugal.png",
                name: "Portugal",
              },
              type: {
                name: "red",
                text: "Vinho tinto",
              },
              alcohol: 13,
            },
            menu: null,
          },
          amount: 1,
        },
        {
          benefit: {
            id: "3",
            name: "GrandMenu",
            conditions: "",
            imgUrl: "../../../assets/images/icon-grand-menu-quadrado.png",
            price: {
              points: { current: 0, previous: 0 },
              cash: { current: 0, previous: 0 },
            },
            wine: null,
            menu: {
              image_url: {
                mobile:
                  // "../../../assets/images/background/checkout-grand-menu-banners-iupp-detalhes.png",
                  "../../../assets/images/background/checkout-grand-menu-banners-detalhes.png",
                desktop:
                  // "../../../assets/images/background/grand-menu-banner-iupp.png",
                  "../../../assets/images/background/grand-menu-banner.png",
              },
              items: [
                {
                  image_url:
                    // "../../../assets/images/background/little-grand-menu-banner-mobile-iupp.png",
                    "../../../assets/images/background/little-grand-menu-banner-mobile.png",
                  name: "",
                  description: "",
                },
                {
                  image_url:
                    // "../../../assets/images/background/little-grand-menu-banner-mobile-iupp.png",
                    "../../../assets/images/background/little-grand-menu-banner-mobile.png",
                  name: "",
                  description: "",
                },
                {
                  image_url:
                    // "../../../assets/images/background/little-grand-menu-banner-mobile-iupp.png",
                    "../../../assets/images/background/little-grand-menu-banner-mobile.png",
                  name: "",
                  description: "",
                },
              ],
            },
          },
          amount: 1,
        },
      ],
      customer: {
        id: null,
        name: null,
        username: null,
        lastName: null,
        email: null,
        emails: null,
        cpf: null,
        rg: null,
        persontype: null,
        genderType: null,
        accessToken: null,
        idToken: null,
        maritalStatus: null,
        status: null,
        birthDate: null,
        phones: null,
        address: null,
        cellphone: null,
        points: null,
        documentNumber: null,
        catalogParameters: { onlyCash: false, percentMinPurchasePoints: "0" },
      },
      reservation: {
        date: null,
        ambience: null,
        people: null,
        time: null,
      },
      PaymentMoneyRequest: {
        cardNumber: "",
        nameOfCardHolder: "",
        cardHolderCPF: "",
        cardExpirationMonthDate: "",
        cardExpirationYearDate: "",
        securityCode: "",
        parcels: "",
        PointsCash: "",
      },
    };
  }
}
