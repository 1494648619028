import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';

@Component({
  selector: 'app-modal-down-rating',
  templateUrl: './modal-down-rating.component.html',
  styleUrls: ['./modal-down-rating.component.scss']
})
export class ModalDownRatingComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ModalDownRatingComponent>
  ) { }

  public percentage: any[] = [
    { percent: '80%', text: 'gostaram do serviço do restaurante' },
    { percent: '95%', text: 'gostaram da comida do restaurante' }
  ]

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit() {
  }

  public closeModal(){
    this.bottomSheetRef.dismiss();
  }

}
