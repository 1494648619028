import { ICustomerFormData } from '../interfaces/CustomerFormData';
import { Mock } from '../interfaces/Mock.interface';
import { Mask } from '../masks/Mask.maks';

export class CustomerFormData implements Mock {
  static default(): Array<ICustomerFormData> {
    return [
      {
        id: 'customer_name',
        label: 'Nome *',
        type: 'text',
        placeholder: 'Priscila',
        name: 'name',
        mask: [],
        errorMessage: null,
      },
      {
        id: 'customer_lastName',
        label: 'Sobrenome',
        type: 'text',
        placeholder: 'Vidal',
        name: 'lastName',
        mask: [],
        errorMessage: null,
      },
      {
        id: 'customer_email',
        label: 'E-mail *',
        type: 'email',
        placeholder: 'priscila@itau.com.br',
        name: 'email',
        mask: [],
        errorMessage: 'Formato de e-mail inválido',
      },
      {
        id: 'customer_cpf',
        label: 'CPF *',
        type: 'text',
        placeholder: '123.456.789-12',
        name: 'cpf',
        mask: Mask.cpf,
        errorMessage: 'Formato de CPF inválido',
      },
      {
        id: 'customer_birthday',
        label: 'Data de nascimento *',
        type: 'text',
        placeholder: '05.09.1983',
        name: 'birthDate',
        mask: Mask.birthDate,
        errorMessage: 'Formato de data inválido',
      },
      {
        id: 'customer_cellphone',
        label: 'Celular *',
        type: 'type',
        placeholder: '(51) 99505-8289',
        name: 'cellphone',
        mask: Mask.cellphone,
        errorMessage: 'Formato de celular inválido',
      }
    ];
  }
}
