import { NgModule }         from '@angular/core';
import { RouterModule }     from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forRoot([
      // {
      //   path: 'login',
      //   redirectTo: '/login',
      //   pathMatch: 'full'
      // },
      // /* {
      //   path: '',
      //   //redirectTo: '/landing',
      //   redirectTo: '/gastronomia',
      //   pathMatch: 'full'
      // }, */
      {
        path: 'gastronomia',
        redirectTo: '/gastronomia',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: '/gastronomia',
        pathMatch: 'full'
      }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
