import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PrizeService } from "app/_services/prize.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-wine-details",
  templateUrl: "./wine-details.component.html",
  styleUrls: ["./wine-details.component.scss"],
})
export class WineDetailsComponent implements OnInit {
  public prize = null;
  private subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prizeService: PrizeService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.subs.add(
      this.prizeService.prizeWithIdx$.subscribe((prizeIdx) => {
        this.prize = prizeIdx.prize;
        if (!this.prize) {
          this.router.navigate(["../"], { relativeTo: this.route });
        }
      })
    );
  }

  public getWineTypeColor(slug: string): string {
    let color: string = null;

    switch (slug) {
      case "destilado_de_uva":
        color = "#ffffff";
        break;

      case "champagne":
      case "espumante":
        color = "#d6d65d";
        break;

      case "tinto":
        color = "#810024";
        break;

      case "rose":
        color = "#f95154";
        break;

      case "madeira":
      case "jerez":
        color = "#ea8030";
        break;

      case "branco_fortificado":
      case "branco_doce":
      case "porto_branco":
        color = "#a38945";
        break;

      case "tinto_fortificado":
      case "porto":
        color = "#990012";
        break;

      case "tinto_doce":
        color = "#740001";
        break;

      case "branco":
        color = "#f8f6c3";
        break;

      default:
    }
    return color;
  }

  public get alcohol(): number {
    try {
      return this.prize.menuItem.product.features.find(
        (feature) => feature.feature === "56c773f42ceaf5891343069e"
      ).value;
    } catch (error) {
      return null;
    }
  }

  public get typeName(): string {
    try {
      return this.prize.menuItem.product.wineDetails.type.name.pt;
    } catch (error) {
      return null;
    }
  }

  public get typeColor(): string {
    try {
      return this.getWineTypeColor(
        this.prize.menuItem.product.wineDetails.type.slug
      );
    } catch (error) {
      return null;
    }
  }

  public get countryName(): string {
    try {
      return this.prize.menuItem.product.country.name.pt;
    } catch (error) {
      return null;
    }
  }

  public get countryFlag(): string {
    try {
      return `https://tagme.com.br/assets/images/icons/paises/${this.prize.menuItem.product.country.slug}.png`;
    } catch (error) {
      return null;
    }
  }

  public get name(): string {
    try {
      return this.prize.menuItem.product.name.pt;
    } catch (error) {
      return null;
    }
  }

  public get wineImage(): string {
    const defaultAvatarUrl = "../../../assets/images/bottle.png";

    try {
      const { avatarUrl } = this.prize.menuItem.product;

      if (avatarUrl) {
        return `${environment.config.imageUrl}${avatarUrl}`;
      }
    } catch (error) {
      return defaultAvatarUrl;
    }

    return defaultAvatarUrl;
  }

  public goCheckout(): void {
    try {
      this.router.navigate(["gastronomia/checkout", this.prize.venue._id], {
        state: { data: true },
      });
      localStorage.setItem("state.data", true.toString());
    } catch (error) {
      this.router.navigate(["../"], { relativeTo: this.route });
    }
  }

  public backToVenueDetails(): void {
    try {
      this.router.navigate(["gastronomia/venue-details", this.prize.venue._id]);
    } catch (error) {
      this.router.navigate(["../"], { relativeTo: this.route });
    }
  }
}
