import { OnboardingModalComponent } from './modal/onboarding-modal/onboarding-modal.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from '@angular/common';
import localePT from '@angular/common/locales/pt';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
  MatDialogModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { CheckoutComponent } from './checkout.component';
import { BenefitComponent } from './benefit/benefit.component';
import { AmountComponent } from './benefit/amount/amount.component';
import { DividerComponent } from './divider/divider.component';
import { SubtotalComponent } from './subtotal/subtotal.component';
import { ButtonComponent } from './button/button.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { PaymentComponent } from './payment/payment.component';
import { MenuModalComponent } from './modal/menu-modal/menu-modal.component';
import { WineModalComponent } from './modal/wine-modal/wine-modal.component';
import { CardNotAllowedModalComponent } from './modal/card-not-allowed-modal/card-not-allowed-modal.component';
import { VerificationCodeModalComponent } from './modal/verification-code-modal/verification-code-modal.component';
import { VenueCardGhostComponent } from './ghost/venue-card-ghost/venue-card-ghost.component';
import { FraudFinishedComponent } from './fraud-finished/fraud-finished.component';

registerLocaleData(localePT);

@NgModule({
  declarations: [
    CheckoutComponent,
    BenefitComponent,
    AmountComponent,
    DividerComponent,
    SubtotalComponent,
    ButtonComponent,
    OrderSummaryComponent,
    FormInputComponent,
    FormSelectComponent,
    PaymentComponent,
    MenuModalComponent,
    WineModalComponent,
    VenueCardGhostComponent,
    CardNotAllowedModalComponent,
    VerificationCodeModalComponent,
    OnboardingModalComponent,
    FraudFinishedComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
  ],
  providers: [CurrencyPipe, { provide: LOCALE_ID, useValue: 'pt' }],
  exports: [CheckoutComponent, ButtonComponent],
  entryComponents: [
    WineModalComponent,
    MenuModalComponent,
    CardNotAllowedModalComponent,
    VerificationCodeModalComponent,
    OnboardingModalComponent,
    FraudFinishedComponent
  ],
})
export class CheckoutModule {}
