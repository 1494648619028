import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { HttpClientService } from "./httpclient.service";
import * as _ from "lodash";
import { Venue } from "app/client/component/venue/venue.interface";
import { AuthGuardService } from "./auth-guard.service";

@Injectable()
export class VenueService {
	constructor(private _httpClient: HttpClientService, private authGuardService: AuthGuardService) {}

	public hasItau(): Observable<any> {
		return this._httpClient.get("Venue/hasItau");
	}

	public getVenuesItau(): Observable<any> {
		return this._httpClient.get("Venue/allVenuesItau");
	}

	public getFilteredSearchQuery(_searchQuery): Observable<any> {
		// return this._httpClient.get('Venue/' + _searchQuery + '/bySearchQuery').map((response: Response) => {
		//     let venues = response.json();
		//     //console.log(venues);
		//     if(venues && _.size(venues) > 0) {
		//         return venues;
		//     } else {
		//         return null;
		//     }
		//   }).catch(this.handleError);

		return this._httpClient.get("Venue/" + _searchQuery + "/bySearchQuery");
	}

	public getChildrens(_venueId: string): Observable<any> {
		return this._httpClient.get("Venue/Childrens/" + _venueId);
	}

	public getVenues(
		slug: string,
		_city,
		_neighborhood,
		_location,
		segment?,
		name = null,
		cousine = null,
		benefit = null,
		allowedSkill = null,
		reservation = null,
		page: number = 0,
		limit: number = 12,
		venuesAlreadyInView: Array<string> = []
	  ): Observable<any> {
		let params = { slug, segment, limit, page, venuesAlreadyInView };
	
		if (name) params["name"] = name;
		if (benefit) params["benefit"] = benefit;
		if (reservation !== null) params["hasReservation"] = reservation;
		if (cousine) params["cousine"] = cousine;
		if (allowedSkill) params["allowedSkill"] = allowedSkill;
		if (_city && _city !== "Todas as cidades") params["city"] = _city;
		if (_neighborhood) params["neighborhood"] = _neighborhood;
	
		if (_location && _location.latitude && _location.longitude && _city !== "Todas as cidades") {
		  params["latitude"] = _location.latitude;
		  params["longitude"] = _location.longitude;
		}
	
		const client = this.authGuardService.getItemLS("client");
	
		params["partnerID"] = '619bf41b465719a6b85afb65'; //client.partnerID;
//		params["customerID"] = '615766f068d8f5fe80db47a3'; //client.customerID;
	
		switch(slug){
			case "home": return this._httpClient.postWithStaticAuth("Venue/getItauAll", params);
			case "favorite": return this._httpClient.postWithStaticAuth(`Favorites/getByCustomer`, params);
			default: return this._httpClient.postWithStaticAuth("Venue/getItauAll", params);
		}
	  }

	public getFilterSearchDatalist(): Observable<any> {
		const client = this.authGuardService.getItemLS("client");

		let params = { segment: '40539' };
		return this._httpClient.getWithStaticAuth(`Venue/getFilterSearchDatalist`, params);
	}

	public getVenue(_id: String): Observable<any> {
		return this._httpClient.getWithStaticAuth("Venue/" + _id);
	}

	public getVenuesBySearchQuery(_searchQuery): Observable<any> {
		return this._httpClient.get("Venue/" + _searchQuery + "/bySearchQuery");
	}

	public getListAddress(): Observable<any> {
		return this._httpClient.get("Prize/Citys/List/All");
	}

	public getCuisineFromAPI(): Observable<any> {
		return this._httpClient.getWithStaticAuth("Venue/Cousines/all");
	}

	public toggleFavorite(venue: Venue): Observable<any> {
		const client = JSON.parse(localStorage.getItem("client"));

		if (Object.keys(client).length <= 0) return;

		const data = {
			email: client.emails[0].email,
			venueID: venue._id,
		};

		return this._httpClient.put(`Favorites/IUPP/${data.email}/${data.venueID}`, {});
	}

	public getFavorites(): Observable<any> {
		const client = JSON.parse(localStorage.getItem("client"));

		if (Object.keys(client).length <= 0) return;

		return this._httpClient.get(`Favorites/IUPP/${client.emails[0].email}`);
	}
}
