import { ExchangeService } from './../../../_services/exchange.service';
import { Component, OnInit, Input, Type } from '@angular/core';
import { ShoppingCartItem } from '../interfaces/ShoppingCartItem';
import { MatDialog } from '@angular/material/dialog';
import { WineModalComponent } from '../modal/wine-modal/wine-modal.component';
import { Benefit } from '../interfaces/Benefit';
import { MenuModalComponent } from '../modal/menu-modal/menu-modal.component';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss'],
})
export class BenefitComponent implements OnInit {
  @Input('data') item: ShoppingCartItem;
  @Input('venue') venue: any;

  public showPreviousPriceInCash: boolean;
  public showPreviousPriceInPoints: boolean;
  public srcIcon: String;

  constructor(public modal: MatDialog) {}

  ngOnInit() {
    this.showPreviousPriceInCash =
      this.item.benefit.price.cash.current <
      this.item.benefit.price.cash.previous;
    this.showPreviousPriceInPoints =
      this.item.benefit.price.points.current <
      this.item.benefit.price.points.previous;

    if( this.item.benefit.name.includes("Menu Casual") || this.item.benefit.name.includes("Casual Itaú")){
      this.srcIcon = "../../../assets/images/icon-menu-casual-quadrado.png"
    }else if( this.item.benefit.name.includes("Menu Personnalité")){
      this.srcIcon = "../../../assets/images/checkout-icon-menu-personnalite-quadrado.png"
    }else if( this.item.benefit.name.includes("Grand Menu")){
      this.srcIcon = "../../../assets/images/icon-grand-menu-quadrado.png"
    }else if( this.item.benefit.name.includes("Menu Fast")){
      this.srcIcon = "../../../assets/images/icon-menu-fast-quadrado.png"
    }else{
      this.srcIcon = "../../../assets/images/icon-vinho-personnalite-quadrado.png"
    }

    this.changeNumeric();
  }

  //gambi asked form iupp team
  private changeNumeric() {
    if(!this.venue.name.toLowerCase().includes("bob's")){
      this.item.benefit.name = this.item.benefit.name.replace('3', '1').replace('4', '2');
    }
  }

  /**
   * Updates the item's amount with a number
   *
   * @param $event Amount to be updated
   *
   * @return void
   */
  receiverAmountUpdated($event: number): void {
    this.item.amount = $event;
  }

  /**
   * Opens the modal according to the benefit received
   *
   * @param data Benefit to be displayed in the modal
   *
   * @return void
   */
  public openModal(data: Benefit): void {
    const modal: Type<
      WineModalComponent | MenuModalComponent
    > = this.chooseModal(data);

    this.modal.open(modal, { data });
  }

  /**
   * Determines which modal will open
   *
   * @param benefit Benefit to determining which modal will open
   *
   * @return Type<WineModalComponent | MenuModalComponent>
   */
  private chooseModal(
    benefit: Benefit,
  ): Type<WineModalComponent | MenuModalComponent> {
    return benefit.wine !== null ? WineModalComponent : MenuModalComponent;
  }
}
