import { Component, Inject } from "@angular/core";
import { Modal } from "../../interfaces/Modal";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Benefit } from "../../interfaces/Benefit";

@Component({
  selector: "app-menu-modal",
  templateUrl: "./menu-modal.component.html",
  styleUrls: ["../modal.component.scss", "./menu-modal.component.scss"],
})
export class MenuModalComponent implements Modal {
  public srcIcon: String;

  constructor(
    public dialogRef: MatDialogRef<MenuModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Benefit
  ) {}

  ngOnInit() {
    if (
      this.data.name.includes("Menu Casual") ||
      this.data.name.includes("Casual Itaú")
    ) {
      this.srcIcon =
        // "../../../assets/images/background/menu-casual-banner-iupp.png";
        "../../../assets/images/background/menu-casual-banner.png";
    } else if (this.data.name.includes("Menu Personnalité")) {
      this.srcIcon =
        // "../../../assets/images/background/menu-personnalite-banner-iupp.png"
        "../../../assets/images/background/menu-personnalite-banner.png";
    } else if (this.data.name.includes("Grand Menu")) {
      this.srcIcon =
        // "../../../assets/images/background/grand-menu-banner-iupp.png"
        "../../../assets/images/background/grand-menu-banner.png";
    } else {
      this.srcIcon =
        // "../../../assets/images/background/menu-fast-banner-iupp.png"
        "../../../assets/images/background/menu-fast-banner.png";
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
