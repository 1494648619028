import { Availability } from '../interfaces/Availability.interface';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export class ReservationFormDataAdapter {
  static getDaysToSelect(availabilities: Array<Availability>) {
    const days = [
      {
        value: '',
        text: 'Selecione um dia',
      },
    ];

    if (availabilities) {
      availabilities.forEach(availability => {
        if (availability.available) {
          days.push({
            value: availability.reservationDay,
            text: format(
              parseISO(availability.reservationDay),
              "dd 'de' MMMM 'de' yyyy",
              { locale: ptBR },
            ),
          });
        }
      });
    }

    return days;
  }

  static fromAPIResponse(availabilities: Array<Availability>) {
    return [
      {
        id: 'reservation_date',
        label: 'Dia da reserva',
        name: 'date',
        options: this.getDaysToSelect(availabilities),
      },
      {
        id: 'reservation_ambience',
        label: 'Ambiente',
        name: 'ambience',
        options: [],
      },
      {
        id: 'reservation_people',
        label: 'Nº de pessoas',
        name: 'people',
        options: [],
      },
      {
        id: 'reservation_time',
        label: 'Horário',
        name: 'time',
        options: [],
      },
    ];
  }
}
