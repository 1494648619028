import { Component, OnInit } from "@angular/core";

@Component({
  selector: "home-loading",
  templateUrl: "./home-loading.component.html",
  styleUrls: ["./home-loading.component.scss"],
})
export class HomeLoadingComponent implements OnInit {
  public loadingVenues: Array<null> = Array(10).fill(null);

  constructor() {}

  ngOnInit() {}
}
