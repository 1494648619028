import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cusine-type-ghost',
  templateUrl: './cusine-type-ghost.component.html',
  styleUrls: ['./cusine-type-ghost.component.scss'],
})
export class CusineTypeGhostComponent {
  @Input() width: number = 40;
}
