import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { AuthGuardService } from "app/_services/auth-guard.service";
import { AuthenticationService } from "app/_services/authentication.service";
import { LoaderService } from "app/_services/loader.service";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	public isLogged: boolean = false;

	constructor(
		private loaderService: LoaderService,
		private activatedRoute: ActivatedRoute,
		private authGuardService: AuthGuardService,
		private authService: AuthenticationService
	) {}

	async ngOnInit() {
		this.isLogged = true;
		// this.loaderService.start();

		// await this.activatedRoute.queryParams.subscribe(async (params: Params) => {
		// 	const generatedCodeParam = params["code"] === "null" ? null : params["code"];

		// 	const isLoggedOut =
		// 		!generatedCodeParam &&
		// 		(!this.authGuardService.getCookie("token") || this.authGuardService.getCookie("token") == undefined);

		// 	if (isLoggedOut) {
		// 		this.isLogged = true;
		// 		await this.authService.doLogin();
		// 	} else {
		// 		let treatedCodeParam = generatedCodeParam || this.authGuardService.getCookie("token");
		// 		this.authGuardService.setCookie("token", treatedCodeParam, 1);
		// 		if (!this.authGuardService.getItemLS("client")) {
		// 			await this.authService.client(treatedCodeParam);
		// 		}
		// 		this.isLogged = true;
		// 	}
		// });

		// this.loaderService.stop();
	}
}
