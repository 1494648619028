import { AuthenticationService } from "./_services/authentication.service";
import { AuthGuardService } from "./_services/auth-guard.service";
//ANGULAR MODULES
import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpModule } from "@angular/http";
//APP GLBAL ROUTING AND COMPONENTS
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
//MODULES
import { IuppModule } from "./client/iupp.module";
import { SharedModule } from "./_shared/shared.module";
//SERVICES
import { AppSettingsService } from "./_services/appsettings.service";
import { LoaderService } from "./_services/loader.service";
import { GeolocationService } from "./_services/geolocation.service";
import { HttpClientService } from "./_services/httpclient.service";
import { ReservationService } from "./_services/reservation.service";
import { LogService } from "./_services/log.service";
import { SharedDataService } from "./_services/sharedData.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

//MATERIAL-ANGULAR
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material";
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MaintenanceComponent } from "./maintenance/maintenance.component";

@NgModule({
  imports: [
    BrowserModule,
    HttpModule,
    IuppModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatBottomSheetModule,
  ],
  declarations: [AppComponent, MaintenanceComponent],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    AppSettingsService,
    LoaderService,
    AuthGuardService,
    AuthenticationService,
    GeolocationService,
    HttpClientService,
    LogService,
    SharedDataService,
    ReservationService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
