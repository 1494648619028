import { Component, Input, OnInit } from "@angular/core";
import { AppSettingsService } from "../../../_services/appsettings.service";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { Venue } from "./venue.interface";
import { VenueService } from "app/_services/venue.service";
import { SharedDataService } from "app/_services/sharedData.service";

@Component({
  selector: "venue",
  templateUrl: "./venue.component.html",
  styleUrls: ["./venue.component.scss"],
})
export class VenueComponent implements OnInit {
  public imgPrefixPath: string = this._appSettingsService.getSettings().imageUrl;

  @Input() type: string = "hor";
  @Input() venue;
  @Input() exchanges;
  @Input() unsetLeftMargin: false;
  @Input() topFilterActive;

  constructor(
    private _appSettingsService: AppSettingsService,
    private router: Router,
    private venueService: VenueService,
    public sharedDataService: SharedDataService
  ) {}

  public ngOnInit() {
  }

  public handleFavorite($event: any) {
    $event.stopPropagation();

    this.venueService.toggleFavorite(this.venue).subscribe((res) => {
      // console.log(res);
      const client = JSON.parse(localStorage.getItem("client"));
      localStorage.setItem("client", JSON.stringify({ ...client, favorites: res.favorites || [] }));
      this.venue.favorited = !this.venue.favorited!!;
    });
  }

  public getUniquePrizes(prizes: []) {
    return _.uniq(prizes);
  }

  public goVenuePage(venueId): void {
    this.router.navigate(["gastronomia/venue-details", venueId]);
  }

  public getVenueAddress(venue): string {
    if (!venue || !venue.address) return "";

    let address: string = "";

    if (venue.address.neighborhood) address = venue.address.neighborhood.trim();
    if (address === "") address = venue.address.city;
    else address += " - " + venue.address.city;

    return address;
  }

  public getVenuePhoto(venue) {
    let venuePhoto: string = this.imgPrefixPath;

    try {
      venuePhoto += venue.apps.itau[0].coverUrl;
    } catch (error) {
      venuePhoto += "no-image-ibeneficios.jpg";
    }

    return venuePhoto;
  }

  public filteredPrizes(venue) {
    venue.prizes = _.sortBy(venue.prizes, ["price"]);
    const isMenuCasual = venue.prizes.find((prize) => prize.name.includes("Casual"));

    venue.prizes = isMenuCasual ? _.uniqBy(venue.prizes, ["name"]) : _.uniqBy(venue.prizes, "name");
    return venue.prizes;
  }

  public definePoints(_venue, _price) {
    let points = 0;

    if (this.exchanges && this.exchanges.length > 0) {
      this.exchanges.forEach((exchange) => {
        if (exchange.prize.price === _price) {
          _venue.prizePrices.forEach((prizePrice) => {
            if (exchange.prize.price === prizePrice) {
              points = Math.trunc(parseInt(prizePrice, 0) / exchange.rate);
            }
          });
        } else {
          points = Math.trunc(parseInt(_price, 0) / this.sharedDataService.CPP);
        }
      });
    } else {
      points = Math.trunc(parseInt(_price, 0) / this.sharedDataService.CPP);
    }

    return points;
  }
}
