import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Navigator } from "./navigator.interface";

@Component({
  selector: "navigator",
  templateUrl: "./navigator.component.html",
  styleUrls: ["./navigator.component.scss"],
})
export class NavigatorComponent implements OnInit {
  public navigators: Array<Navigator> | null = [
    { icon: "no-restaurante", title: "Experiência do restaurante", link: "", isActive: true },
    { icon: "mala-icone", title: "Meus pedidos", link: "order-details", isActive: false },
    { icon: "desejo-icone", title: "Meus favoritos", link: "favorites", isActive: false },
  ];

  constructor(private router: Router) {}

  ngOnInit() {}

  public navigate(page: string): void {
    for (var i = 0; i < this.navigators.length; i++) {
      if (page == this.navigators[i].link) {
        this.navigators[i].isActive = true;
      } else {
        this.navigators[i].isActive = false;
      }
    }

    this.router.navigate(["gastronomia/", page]);
  }
}
