import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() title: string;
  @Input() name: string;
  @Input() options: Array<{ value: string; text: string }>;

  @Output() changed = new EventEmitter<{ target: { value: string } }>();

  public selectedValue: string;

  /**
   * Emmits the selected value
   *
   * @return void
   */
  public changeValue(): void {
    this.changed.emit({ target: { value: this.selectedValue } });
  }
}
