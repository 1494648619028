import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ReservationService } from 'app/_services/reservation.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

  constructor(private _route: ActivatedRoute, 
              private _reservationService: ReservationService) { }

  ngOnInit() {
    this._route.params.subscribe((params: Params) => {
      const reservationId = params['reservationId'];
      if (reservationId) {
        this._reservationService.getReservation(reservationId).subscribe(
          (dataSet: Response) => {
            console.log(dataSet);
              this._reservationService.cancelReservation(dataSet).subscribe(
                (dataCancel: Response) => {
                  return true;
                },
                (error: Error) => {
                  return Observable.throw(error);
                }
              );
            return true;
          },
          (error: Error) => {
            return Observable.throw(error);
          }
        );
      }
    });
  }

}
